import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function OmniDmsPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>DMS (Driver Monitor System)</h1>
          <h2>Introduction</h2>
          <p>
            The Driver Monitoring System (DMS) is an advanced safety technology
            developed to enhance vehicle security and driver performance. It
            employs a combination of sensors, cameras, and artificial
            intelligence to monitor the driver&apos;s alertness, focus, and
            overall behavior behind the wheel.{' '}
          </p>
          <h2>Key Features and Functions</h2>
          <h3>Driver Absence:</h3>
          <p>
            The Driver Absence Detection feature of our Driver Monitoring System
            (DMS) plays a vital role in maintaining driver engagement and
            ensuring safety. This feature is designed to detect and alert when a
            driver is not correctly positioned in front of the camera or is
            improperly seated. Key aspects of this feature include:
          </p>
          <ul>
            <li>
              <strong>Correct Positioning Alert:</strong> The DMS continuously
              monitors the driver&apos;s position. If the driver is not
              correctly aligned with the camera or is not seated properly, an
              alert is issued. This ensures that the driver is always in the
              optimal position for vehicle control and system interaction.
            </li>
            <li>
              <strong>Preventive Safety Measures:</strong> Timely alerts for
              driver absence or incorrect positioning act as preventive
              measures. They not only ensure that the driver is always ready to
              take control but also help in reducing the likelihood of accidents
              due to inattention or improper posture.
            </li>
          </ul>
          <p>
            The integration of the Driver Absence Detection feature into the DMS
            is key to promoting safer driving habits, enhancing driver
            engagement, and improving overall driving quality.
          </p>
          <h3>Sleep Detection</h3>
          <p>
            The Sleep Detection feature of our DMS is critical for preventing
            accidents caused by driver drowsiness. It monitors the driver’s eyes
            and issues an alert if it detects that the eyes are closed,
            signaling potential sleep. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Eye Closure Monitoring:</strong> This feature continuously
              scans the driver&apos;s eyes. If the eyes are detected as closed
              for a prolonged period, indicative of sleep, an immediate alert is
              issued to the driver.
            </li>
          </ul>
          <h3>Fatigue Detection</h3>
          <p>
            Fatigue Detection within the DMS is designed to identify signs of
            driver tiredness or microsleep, a brief lapse in attention. This
            feature is instrumental in maintaining driver alertness and road
            safety. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Microsleep Alert:</strong> The system monitors for
              indicators of microsleep, such as slowed blinking. Upon detection,
              an alert is issued to refocus the driver&apos;s attention.
            </li>
            <li>
              <strong>Enhancing Driver Vigilance:</strong> By detecting early
              signs of fatigue, this feature prompts drivers to stay awake,
              thereby reducing the risk of accidents due to tiredness.
            </li>
          </ul>
          <h3>Distraction Detection</h3>
          <p>
            Distraction Detection is a key safety feature in our DMS, designed
            to alert when a driver is not focusing on the road. It detects
            instances of the driver looking to the side or downwards. Key
            aspects include:
          </p>
          <ul>
            <li>
              <strong>Focus Monitoring:</strong> The system continuously
              assesses the driver&apos;s focus. If the driver is detected
              looking away from the road for too long, an alert is triggered.
            </li>
            <li>
              <strong>Reducing Distracted Driving:</strong> This feature plays a
              crucial role in minimizing distracted driving, a leading cause of
              road accidents, by encouraging drivers to maintain their focus on
              the road.
            </li>
          </ul>
          <h3>Phone Talking Detection</h3>
          <p>
            The Phone Talking Detection feature in the DMS identifies and alerts
            when a driver is talking on the phone, promoting adherence to safe
            driving practices. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Monitoring Phone Use:</strong> The system detects when the
              driver is holding a phone to their ear or talking while driving,
              immediately issuing an alert.
            </li>
          </ul>
          <h3>Smoking Detection</h3>
          <p>
            Smoking Detection in our DMS ensures a smoke-free driving
            environment by alerting when a driver is smoking inside the vehicle.
            Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Identifying Smoking Behavior:</strong> The system detects
              smoking-related actions and issues an alert, promoting a healthy
              and safe driving environment.
            </li>
            <li>
              <strong>Encouraging Smoke-Free Driving:</strong> By alerting
              against smoking, this feature helps in maintaining air quality
              within the vehicle and discourages a practice that can be
              distracting and hazardous.
            </li>
          </ul>
          <h3>Sensor Fusion for Distraction Detection in DMS</h3>
          <p>
            The application of Sensor Fusion in our DMS (Driver Monitoring
            System) for distraction detection is a sophisticated advancement
            ensuring contextually appropriate alerts. This feature intelligently
            incorporates vehicle movement data, such as from g-sensors and
            gyroscopes, to understand the context of the driver&apos;s focus,
            particularly during vehicle turning. Key aspects of this feature
            include:
          </p>
          <ul>
            <li>
              <strong>Integration with Vehicle Movement Data:</strong> The DMS
              synchronizes with g-sensors and gyroscopes to detect when the
              vehicle is turning. This integration allows the system to
              understand the driving context more accurately.
            </li>
            <li>
              <strong>Contextual Distraction Analysis:</strong> During turns,
              the system recognizes that the driver watching in the direction of
              the turn is normal behavior and not a sign of distraction.
              Therefore, in such scenarios, the driver is not flagged for
              distraction if they are looking in the same direction as the turn.
            </li>
            <li>
              <strong>Enhanced Accuracy in Alerts:</strong> By considering the
              vehicle&apos;s turning movement, the DMS provides more accurate
              and contextually relevant alerts, reducing false positives and
              ensuring that driver attention is correctly monitored.
            </li>
          </ul>
          <p>
            This advanced implementation of Sensor Fusion in distraction
            detection marks a significant step in enhancing the reliability and
            effectiveness of our DMS, ensuring that driver monitoring is both
            precise and attuned to real-world driving conditions.
          </p>
          <h2>Settings</h2>
          <p>
            You can configure DMS features by operating OMNI → Main Menu → DMS
            Settings.
          </p>
          <h3>Enabling:</h3>
          <p>
            You possess the flexibility to activate or deactivate alerts for
            each specific type of alarm. Notably, even when an alarm is muted,
            the corresponding event will continue to be uploaded to the cloud.
          </p>
          <h3>Threshold:</h3>
          <p>
            Adjust the triggering time threshold to modify the sensitivity of
            the alert system.
          </p>
          <h3>Seat:</h3>
          <p>
            The distraction alert thresholds for the left and right sides are
            asymmetrical. To ensure optimal accuracy in distraction detection,
            it is essential to inform the OMNI system whether the driver is
            positioned on the left or right side.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OmniDmsPage;
