import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

import DownloadTable from './DownloadTable';
/*eslint-disable */

const documents = [
  {
    name: 'OMNI User Manual v1.0',
    description: 'The user manual of the OMNI device.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_User_Manual_v1.0.pdf',
  },
  {
    name: 'Quick Installation Guide v1.0',
    description: 'The quick installation guide of OMNI device.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Portal_Quick_installation_guide.pdf',
  },
  {
    name: 'OMNI Installation/Calibration Manual v1.2',
    description: 'The manual for OMNI installation and calibration.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Installation_Calibration_Manual_v1.2.pdf',
  },
  // ... more documents
];

function OmniManualPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>User Manual</h1>
          <p>
            You can download all of the user manual related to OMNI device here.
          </p>
        </div>
      </div>

      {/* The table for download. */}
      <h2>Resources</h2>
      <DownloadTable documents={documents} />
    </div>
  );
}

export default OmniManualPage;
