import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIShapePage() {
  useEffect(() => () => {}, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Shape APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Shape - Set</h3>
              <li><a href="#noahsetsize">NoahSetSize</a></li>
              <li><a href="#noahsetwidth">NoahSetWidth</a></li>
              <li><a href="#noahsetheight">NoahSetHeight</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Shape - Get</h3>
              <li><a href="#noahgetsize">NoahGetSize</a></li>
              <li><a href="#noahgetwidth">NoahGetWidth</a></li>
              <li><a href="#noahgetheight">NoahGetHeight</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Anchor - Set</h3>
              <li><a href="#noahsetanchor">NoahSetAnchor</a></li>
              <li><a href="#noahsetanchorx">NoahSetAnchorX</a></li>
              <li><a href="#noahsetanchory">NoahSetAnchorY</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Anchor - Get</h3>
              <li><a href="#noahgetanchor">NoahGetAnchor</a></li>
              <li><a href="#noahgetanchorx">NoahGetAnchorX</a></li>
              <li><a href="#noahgetanchory">NoahGetAnchorY</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahsetsize">NoahSetSize</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetSize(string target, number w, number h)</code></p>
          <h4 id="description">Description</h4>
          <p>Sets the dimensions of the specified <em>target</em> to the given width (<em>w</em>) and height (<em>h</em>), both measured in pixels. This function is tailored for flat rectangle elements, including <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, and <strong>Video2D</strong> elements. Applying this function to other types of elements will result in an error message from Noah, with no changes made to the <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          <li><strong><code>w</code> (number):</strong> The width to set in pixels.</li>
          <li><strong><code>h</code> (number):</strong> The height to set in pixels.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set the size of &quot;exampleElement&quot; to 100 pixels wide by 150 pixels high:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetSize(<span class="hljs-string">"exampleElement"</span>, <span class="hljs-number">100</span>, <span class="hljs-number">150</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetwidth">NoahSetWidth</a> - Sets the width of the specified <em>target</em> to the given <em>w</em> value in pixels.</li>
          <li><a href="#noahsetheight">NoahSetHeight</a> - Sets the height of the specified <em>target</em> to the given <em>h</em> value in pixels</li>
          </ul>
          <hr />
          <h3 id="noahsetwidth">NoahSetWidth</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetWidth(string target, number w)</code></p>
          <h4 id="description">Description</h4>
          <p>Sets the width of the specified <em>target</em> to the given <em>w</em> value in pixels, while the height of the element remains unchanged. This function is designed for flat rectangle elements such as <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, and <strong>Video2D</strong>. Using this function on other element types will trigger an error message from Noah without affecting the <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          <li><strong><code>w</code> (number):</strong> The width to set in pixels.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To change the width of &quot;exampleElement&quot; to 200 pixels:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetWidth(<span class="hljs-string">"exampleElement"</span>, <span class="hljs-number">200</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetsize">NoahSetSize</a> - Sets the dimensions of the specified <em>target</em> to the given width (<em>w</em>) and height (<em>h</em>) in pixels.</li>
          <li><a href="#noahsetheight">NoahSetHeight</a> - Sets the height of the specified <em>target</em> to the given <em>h</em> value in pixels</li>
          </ul>
          <hr />
          <h3 id="noahsetheight">NoahSetHeight</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetHeight(string target, number h)</code></p>
          <h4 id="description">Description</h4>
          <p>Modifies the height of the specified <em>target</em> to the assigned <em>h</em> value in pixels, with the width of the element staying the same. This function operates on flat rectangle elements, including <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, and <strong>Video2D</strong> elements. Targeting other types of elements with this function will cause Noah to log an error message and leave the <em>target</em> unchanged.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          <li><strong><code>h</code> (number):</strong> The height to set in pixels.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To adjust the height of &quot;exampleElement&quot; to 250 pixels:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetHeight(<span class="hljs-string">"exampleElement"</span>, <span class="hljs-number">250</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetsize">NoahSetSize</a> - Sets the dimensions of the specified <em>target</em> to the given width (<em>w</em>) and height (<em>h</em>) in pixels.</li>
          <li><a href="#noahsetwidth">NoahSetWidth</a> - Sets the width of the specified <em>target</em> to the given <em>w</em> value in pixels.</li>
          </ul>
          <hr />
          <h3 id="noahgetsize">NoahGetSize</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetSize(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Retrieves the size of the specified <em>target</em>, which is a flat rectangle element, returning a <strong>vec2</strong> object for width (x) and height (y) access. This function is designed for <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, and <strong>Video2D</strong> elements. Using this function on other types of elements will result in an error message from Noah and a return value of <strong>vec2(0, 0)</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a <strong>vec2</strong> object indicating the width and height of the target.</p>
          <h4 id="example">Example</h4>
          <p>To get the size of &quot;my_quad&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> size = NoahGetSize(<span class="hljs-string">"my_quad"</span>)<br/>
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Width: "</span> .. size.x .. <span class="hljs-string">", Height: "</span> .. size.y)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Width: 200, Height: 250
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetwidth">NoahGetWidth</a> - Fetches the width in pixels of the specified <em>target</em>.</li>
          <li><a href="#noahgetheight">NoahGetHeight</a> - Fetches the height in pixels of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetwidth">NoahGetWidth</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetWidth(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Fetches the width in pixels of the specified <em>target</em>, a flat rectangle element. This function returns a float number, meaningful for <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, and <strong>Video2D</strong> elements. Targeting other types of elements will trigger an error message from Noah and a return value of <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the width of the target as a float number. For incompatible element types, returns <strong>0</strong>.</p>
          <h4 id="example">Example</h4>
          <p>To get the width of &quot;my_quad&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> width = NoahGetWidth(<span class="hljs-string">"my_quad"</span>)<br/>
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Width: "</span> .. width)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Width: 200
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetsize">NoahGetSize</a> - Retrieves the size of the specified <em>target</em>.</li>
          <li><a href="#noahgetheight">NoahGetHeight</a> - Fetches the height in pixels of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetheight">NoahGetHeight</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetHeight(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Obtains the height in pixels of the specified <em>target</em>, which is a flat rectangle element. Similar to <code>NoahGetWidth</code>, this function outputs a float number applicable to <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, and <strong>Video2D</strong> elements. Targeting an incompatible element type results in an error from Noah and a return value of <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the height of the target as a float number. For other element types, returns <strong>0</strong>.</p>
          <h4 id="example">Example</h4>
          <p>To determine the height of &quot;my_quad&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> height = NoahGetHeight(<span class="hljs-string">"my_quad"</span>)<br/>
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Height: "</span> .. height)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Height: 250
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetsize">NoahGetSize</a> - Retrieves the size of the specified <em>target</em>.</li>
          <li><a href="#noahgetwidth">NoahGetWidth</a> - Fetches the width in pixels of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahsetanchor">NoahSetAnchor</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetAnchor(string target, number x, number y)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the anchor of <em>target</em>, which is a flat rectangle element, to the assigned <em>x, y</em> position.
          This function can handle <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> and <strong>Video</strong> element. If you target a element of other types to this function, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong>.</li>
          <li><strong><code>x</code> (number):</strong> Anchor x-coordinate position to set.</li>
          <li><strong><code>y</code> (number):</strong> Anchor y-coordinate position to set.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;image&quot; anchor (0.5, 0.5):</p>
          <pre class="hljs hljs-content"><code><div>NoahSetAnchor(<span class="hljs-string">"image"</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.5</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetanchorx">NoahSetAnchorX</a> - Set anchor x-position of node</li>
          <li><a href="#noahsetanchory">NoahSetAnchorY</a> - Set anchor y-position of node</li>
          <li><a href="#noahgetanchor">NoahGetAnchor</a> - Get anchor position of node</li>
          <li><a href="#noahgetanchorx">NoahGetAnchorX</a> - Get anchor x-position of node</li>
          <li><a href="#noahgetanchory">NoahGetAnchorY</a> - Get anchor y-position of node</li>
          </ul>
          <hr />
          <h3 id="noahsetanchorx">NoahSetAnchorX</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetAnchorX(string target, number x)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the anchor of <em>target</em>, which is a flat rectangle element, to the assigned <em>x</em> position, while the y position of anchor remains unchanged.
          This function can handle <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> and <strong>Video</strong> element. If you target a element of other types to this function, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong>.</li>
          <li><strong><code>x</code> (number):</strong> Anchor x-coordinate position to set.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;image&quot; anchor x to 0.5:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetAnchorX(<span class="hljs-string">"image"</span>, <span class="hljs-number">0.5</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetanchor">NoahSetAnchor</a> - Set anchor position of node</li>
          <li><a href="#noahsetanchory">NoahSetAnchorY</a> - Set anchor y-position of node</li>
          <li><a href="#noahgetanchor">NoahGetAnchor</a> - Get anchor position of node</li>
          <li><a href="#noahgetanchorx">NoahGetAnchorX</a> - Get anchor x-position of node</li>
          <li><a href="#noahgetanchory">NoahGetAnchorY</a> - Get anchor y-position of node</li>
          </ul>
          <hr />
          <h3 id="noahsetanchory">NoahSetAnchorY</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetAnchorY(string target, number y)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the anchor of <em>target</em>, which is a flat rectangle element, to the assigned <em>y</em> position, while the x position of anchor remains unchanged.
          This function can handle <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> and <strong>Video</strong> element. If you target a element of other types to this function, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong>.</li>
          <li><strong><code>y</code> (number):</strong> Anchor y-coordinate position to set.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;image&quot; anchor y to 0.5:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetAnchorY(<span class="hljs-string">"image"</span>, <span class="hljs-number">0.5</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetanchor">NoahSetAnchor</a> - Set anchor position of node</li>
          <li><a href="#noahsetanchorx">NoahSetAnchorX</a> - Set anchor x-position of node</li>
          <li><a href="#noahgetanchor">NoahGetAnchor</a> - Get anchor position of node</li>
          <li><a href="#noahgetanchorx">NoahGetAnchorX</a> - Get anchor x-position of node</li>
          <li><a href="#noahgetanchory">NoahGetAnchorY</a> - Get anchor y-position of node</li>
          </ul>
          <hr />
          <h3 id="noahgetanchor">NoahGetAnchor</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetAnchor(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the anchor of <em>target</em>, which is a flat rectangle element.
          Only <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> and <strong>Video</strong> elements take effect. If you target a element of other types to this function, <em>Noah</em> logs an error message and returns <strong>vec2(0, 0)</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function returns anchor position as a <strong>vec2</strong> object, with which you can access the x, y values by the dot operator.</p>
          <h4 id="example">Example</h4>
          <p>To get node &quot;image&quot; anchor position:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> pos = NoahGetAnchor(<span class="hljs-string">"image"</span>)<br/>
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Anchor position : "</span> .. pos.x .. <span class="hljs-string">", "</span> .. pos.y)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Anchor position : 0.5, 0.5
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetanchor">NoahSetAnchor</a> - Set anchor position of node</li>
          <li><a href="#noahsetanchorx">NoahSetAnchorX</a> - Set anchor x-position of node</li>
          <li><a href="#noahsetanchory">NoahSetAnchorY</a> - Set anchor y-position of node</li>
          <li><a href="#noahgetanchorx">NoahGetAnchorX</a> - Get anchor x-position of node</li>
          <li><a href="#noahgetanchory">NoahGetAnchorY</a> - Get anchor y-position of node</li>
          </ul>
          <hr />
          <h4 id="noahgetanchorx">NoahGetAnchorX</h4>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetAnchorX(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the x anchor of <em>target</em>, which is a flat rectangle element.
          <em>target</em> type must be one of <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong> element. If you target a element of other types to this function, <em>Noah</em> logs an error message and returns <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function returns a float number, which is x-coordinate anchor value.</p>
          <h4 id="example">Example</h4>
          <p>To get node &quot;image&quot; anchor x-coordinate:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> x = NoahGetAnchorY(<span class="hljs-string">"image"</span>)<br/>
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Anchor x : "</span> .. x)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Anchor x : 0.5
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetanchor">NoahSetAnchor</a> - Set anchor position of node</li>
          <li><a href="#noahsetanchorx">NoahSetAnchorX</a> - Set anchor x-position of node</li>
          <li><a href="#noahsetanchory">NoahSetAnchorY</a> - Set anchor y-position of node</li>
          <li><a href="#noahgetanchor">NoahGetAnchor</a> - Get anchor position of node</li>
          <li><a href="#noahgetanchory">NoahGetAnchorY</a> - Get anchor y-position of node</li>
          </ul>
          <hr />
          <h3 id="noahgetanchory">NoahGetAnchorY</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetAnchorY(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the y anchor of <em>target</em>, which is a flat rectangle element.
          <em>target</em> type must be one of <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong> element. If you target a element of other types to this function, <em>Noah</em> logs an error message and returns <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2d</strong>, <strong>Text3D</strong> or <strong>Video</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function returns a float number, which is y-coordinate anchor value.</p>
          <h4 id="example">Example</h4>
          <p>To get node &quot;image&quot; anchor y-coordinate:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> y = NoahGetAnchorY(<span class="hljs-string">"image"</span>)<br/>
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Anchor y : "</span> .. y)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Anchor y : 0.5
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetanchor">NoahSetAnchor</a> - Set anchor position of node</li>
          <li><a href="#noahsetanchorx">NoahSetAnchorX</a> - Set anchor x-position of node</li>
          <li><a href="#noahsetanchory">NoahSetAnchorY</a> - Set anchor y-position of node</li>
          <li><a href="#noahgetanchor">NoahGetAnchor</a> - Get anchor position of node</li>
          <li><a href="#noahgetanchorx">NoahGetAnchorX</a> - Get anchor x-position of node</li>
          </ul>
          <hr />

        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIShapePage;
