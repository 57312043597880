import React, { useEffect, useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import './css/ContentPage.css'; // Ensure this is correctly imported

import NoahLuaAPIReferencePage from './NoahLuaAPIReferncePage';
import NoahLuaAPICommunicationPage from './noah_api/NoahLuaAPICommunicationPage';
import NoahLuaAPIBasicCameraPage from './noah_api/NoahLuaAPIBasicCameraPage';
import NoahLuaAPIBasicScreenPage from './noah_api/NoahLuaAPIBasicScreenPage';
import NoahLuaAPIBasicTransformPage from './noah_api/NoahLuaAPIBasicTransformPage';
import NoahLuaAPIShapePage from './noah_api/NoahLuaAPIBasicShapePage';
import NoahLuaAPIColorPage from './noah_api/NoahLuaAPIBasicColorPage';
import NoahLuaAPIBasicFontPage from './noah_api/NoahLuaAPIBasicFontPage';
import NoahLuaAPIBasicImagePage from './noah_api/NoahLuaAPIBasicImagePage';
import NoahLuaAPI3DScenePage from './noah_api/NoahLuaAPIBasic3DScenePage';
import NoahLuaAPIVideoPage from './noah_api/NoahLuaAPIBasicVideoPage';
import NoahLuaAPIAnimationPage from './noah_api/NoahLuaAPIBasicAnimationPage';
import NoahLuaAPISliderPage from './noah_api/NoahLuaAPIBasicSliderPage';
import NoahLuaAPIButtonPage from './noah_api/NoahLuaAPIBasicButtonPage';
import NoahLuaAPIMusicPage from './noah_api/NoahLuaAPIBasicMusicPage';
import NoahLuaAPIFilesystemPage from './noah_api/NoahLuaAPIBasicFilesystemPage';
import NoahXMLReferencePage from './NoahXMLReferencePage';
import NoahInstallationPage from './NoahInstallationPage';
import NoahFirstBuildPage from './NoahFirstBuildPage';
import NoahOverviewPage from './NoahOverviewPage';
import NoahTutorialLab1Page from './noah_tutorial/NoahTutorialLab1Page';
import NoahTutorialLab1Page1 from './noah_tutorial/NoahTutorialLab1Page1';
import NoahTutorialLab1Page2 from './noah_tutorial/NoahTutorialLab1Page2';
import NoahTutorialLab1Page3 from './noah_tutorial/NoahTutorialLab1Page3';
import NoahTutorialLab1Page4 from './noah_tutorial/NoahTutorialLab1Page4';
import NoahTutorialLab1Page5 from './noah_tutorial/NoahTutorialLab1Page5';
import NoahTutorialLab2Page1 from './noah_tutorial/NoahTutorialLab2Page1';
import NoahTutorialLab2Page2 from './noah_tutorial/NoahTutorialLab2Page2';
import NoahTutorialLab2Page3 from './noah_tutorial/NoahTutorialLab2Page3';
import NoahTutorialLab2Page4 from './noah_tutorial/NoahTutorialLab2Page4';
import NoahTutorialLab2Page5 from './noah_tutorial/NoahTutorialLab2Page5';
import NoahTutorialLab2Page6 from './noah_tutorial/NoahTutorialLab2Page6';
import NoahTutorialLab3Page1 from './noah_tutorial/NoahTutorialLab3Page1';
import NoahTutorialLab3Page2 from './noah_tutorial/NoahTutorialLab3Page2';
import NoahTutorialLab3Page3 from './noah_tutorial/NoahTutorialLab3Page3';
import NoahTutorialLab3Page4 from './noah_tutorial/NoahTutorialLab3Page4';
import NoahTutorialLab3Page5 from './noah_tutorial/NoahTutorialLab3Page5';
import NoahTutorialLab4Page1 from './noah_tutorial/NoahTutorialLab4Page1';
import NoahTutorialLab4Page2 from './noah_tutorial/NoahTutorialLab4Page2';
import NoahTutorialLab4Page3 from './noah_tutorial/NoahTutorialLab4Page3';
import NoahTutorialLab4Page4 from './noah_tutorial/NoahTutorialLab4Page4';
import NoahTutorialLab4Page5 from './noah_tutorial/NoahTutorialLab4Page5';
import NoahTutorialLab4Page6 from './noah_tutorial/NoahTutorialLab4Page6';
import NoahDownloadPage from './NoahDownloadPage';
/*eslint-disable */
function NoahPage({ user }) {
  const [isLab1Expanded, setIsLab1Expanded] = useState(false);
  const [isLab2Expanded, setIsLab2Expanded] = useState(false);
  const [isLab3Expanded, setIsLab3Expanded] = useState(false);
  const [isLab4Expanded, setIsLab4Expanded] = useState(false);

  const toggleLab1 = () => {
    setIsLab1Expanded((prev) => !prev);
    setIsLab2Expanded(false);
    setIsLab3Expanded(false);
    setIsLab4Expanded(false);
  };

  const toggleLab2 = () => {
    setIsLab1Expanded(false);
    setIsLab2Expanded((prev) => !prev);
    setIsLab3Expanded(false);
    setIsLab4Expanded(false);
  };

  const toggleLab3 = () => {
    setIsLab1Expanded(false);
    setIsLab2Expanded(false);
    setIsLab3Expanded((prev) => !prev);
    setIsLab4Expanded(false);
  };

  const toggleLab4 = () => {
    setIsLab1Expanded(false);
    setIsLab2Expanded(false);
    setIsLab3Expanded(false);
    setIsLab4Expanded((prev) => !prev);
  };

  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <div className="page-body full-width flush docs-page">
      <div className="docs-nav">
        <div className="side-nav">
          <div className="side-nav-section">
            <div className="side-nav-header subheading">QuickStart</div>
            <Link to="/noah/installation" className="scroll-link side-nav-item">
              Installation
            </Link>
            <Link to="/noah/hello" className="scroll-link side-nav-item">
              Hello Noah
            </Link>
          </div>
          <div className="side-nav-section">
            <div className="side-nav-header subheading">Tutorial</div>
            <div className="side-nav-header chapter_title" onClick={toggleLab1}>Lab1</div>
            {isLab1Expanded && (
              <div className="side-nav-content">
                <Link
                  to="/noah/tutorial/lab1_step1"
                  className="scroll-link side-nav-item"
                >
                  Before you begin
                </Link>
                <Link
                  to="/noah/tutorial/lab1_step2"
                  className="scroll-link side-nav-item"
                >
                  Create New Project
                </Link>
                <Link
                  to="/noah/tutorial/lab1_step3"
                  className="scroll-link side-nav-item"
                >
                  Noah Basic Elements
                </Link>
                <Link
                  to="/noah/tutorial/lab1_step4"
                  className="scroll-link side-nav-item"
                >
                  Construct Noah Layout
                </Link>
                <Link
                  to="/noah/tutorial/lab1_step5"
                  className="scroll-link side-nav-item"
                >
                  Conclusion
                </Link>
              </div>
            )}

            <div className="side-nav-header chapter_title" onClick={toggleLab2}>Lab2</div>
            {isLab2Expanded && (
              <div className="side-nav-content">
                <Link
                  to="/noah/tutorial/lab2_step1"
                  className="scroll-link side-nav-item"
                >
                  Before you begin
                </Link>
                <Link
                  to="/noah/tutorial/lab2_step2"
                  className="scroll-link side-nav-item"
                >
                  Introduce Lua
                </Link>
                <Link
                  to="/noah/tutorial/lab2_step3"
                  className="scroll-link side-nav-item"
                >
                  Default Lua Functions
                </Link>
                <Link
                  to="/noah/tutorial/lab2_step4"
                  className="scroll-link side-nav-item"
                >
                  Control Noah Elements
                </Link>
                <Link
                  to="/noah/tutorial/lab2_step5"
                  className="scroll-link side-nav-item"
                >
                  Example: 2D Meter
                </Link>
                <Link
                  to="/noah/tutorial/lab2_step6"
                  className="scroll-link side-nav-item"
                >
                  Conclusion
                </Link>
              </div>
            )}

            <div className="side-nav-header chapter_title" onClick={toggleLab3}>Lab3</div>
            {isLab3Expanded && (
              <div className="side-nav-content">
                <Link
                  to="/noah/tutorial/lab3_step1"
                  className="scroll-link side-nav-item"
                >
                  Before you begin
                </Link>
                <Link
                  to="/noah/tutorial/lab3_step2"
                  className="scroll-link side-nav-item"
                >
                  UI Concept
                </Link>
                <Link
                  to="/noah/tutorial/lab3_step3"
                  className="scroll-link side-nav-item"
                >
                  Button
                </Link>
                <Link
                  to="/noah/tutorial/lab3_step4"
                  className="scroll-link side-nav-item"
                >
                  Slider
                </Link>
                <Link
                  to="/noah/tutorial/lab3_step5"
                  className="scroll-link side-nav-item"
                >
                  Conclusion
                </Link>
              </div>
            )}
            
            <div className="side-nav-header chapter_title" onClick={toggleLab4}>Lab4</div>
            {isLab4Expanded && (
              <div className="side-nav-content">
                <Link
                  to="/noah/tutorial/lab4_step1"
                  className="scroll-link side-nav-item"
                >
                  Before you begin
                </Link>
                <Link
                  to="/noah/tutorial/lab4_step2"
                  className="scroll-link side-nav-item"
                >
                  Establish Communication
                </Link>
                <Link
                  to="/noah/tutorial/lab4_step3"
                  className="scroll-link side-nav-item"
                >
                  Subscribe Data
                </Link>
                <Link
                  to="/noah/tutorial/lab4_step4"
                  className="scroll-link side-nav-item"
                >
                  Publish Data
                </Link>
                <Link
                  to="/noah/tutorial/lab4_step5"
                  className="scroll-link side-nav-item"
                >
                  Send/Read Messages
                </Link>
                <Link
                  to="/noah/tutorial/lab4_step6"
                  className="scroll-link side-nav-item"
                >
                  Conclusion
                </Link>
              </div>
            )}
          </div>
          <div className="side-nav-section">
            <div className="side-nav-header subheading">Reference</div>
            <Link to="/noah/api" className="scroll-link side-nav-item">
              Lua API Reference
            </Link>
            <Link to="/noah/xml" className="scroll-link side-nav-item">
              XML Reference
            </Link>
          </div>
          <div className="side-nav-section">
            <div className="side-nav-header subheading">Download</div>
            <Link to="/noah/download" className="scroll-link side-nav-item">
              Resources
            </Link>
          </div>
        </div>
      </div>
      <div className="documentPageContainer">
        <Routes>
          <Route path="/overview" element={<NoahOverviewPage />} />
          <Route
            path="/api"
            element={<NoahLuaAPIReferencePage user={user} />}
          />
          <Route
            path="/api/communication"
            element={<NoahLuaAPICommunicationPage />}
          />
          <Route path="/api/camera" element={<NoahLuaAPIBasicCameraPage />} />
          <Route path="/api/screen" element={<NoahLuaAPIBasicScreenPage />} />
          <Route
            path="/api/transform"
            element={<NoahLuaAPIBasicTransformPage />}
          />
          <Route path="/api/shape" element={<NoahLuaAPIShapePage />} />
          <Route path="/api/color" element={<NoahLuaAPIColorPage />} />
          <Route path="/api/font" element={<NoahLuaAPIBasicFontPage />} />
          <Route path="/api/imgae" element={<NoahLuaAPIBasicImagePage />} />
          <Route path="/api/3DScene" element={<NoahLuaAPI3DScenePage />} />
          <Route path="/api/video" element={<NoahLuaAPIVideoPage />} />
          <Route path="/api/animation" element={<NoahLuaAPIAnimationPage />} />
          <Route path="/api/slider" element={<NoahLuaAPISliderPage />} />
          <Route path="/api/button" element={<NoahLuaAPIButtonPage />} />
          <Route path="/api/music" element={<NoahLuaAPIMusicPage />} />
          <Route path="/api/filesystem" element={<NoahLuaAPIFilesystemPage />} />
          <Route path="/xml" element={<NoahXMLReferencePage />} />
          <Route path="/installation" element={<NoahInstallationPage />} />
          <Route path="/hello" element={<NoahFirstBuildPage />} />
          <Route path="/tutorial/lab1" element={<NoahTutorialLab1Page />} />
          <Route path="/tutorial/lab1_step1" element={<NoahTutorialLab1Page1 />} />
          <Route path="/tutorial/lab1_step2" element={<NoahTutorialLab1Page2 />} />
          <Route path="/tutorial/lab1_step3" element={<NoahTutorialLab1Page3 />} />
          <Route path="/tutorial/lab1_step4" element={<NoahTutorialLab1Page4 />} />
          <Route path="/tutorial/lab1_step5" element={<NoahTutorialLab1Page5 />} />
          <Route path="/tutorial/lab2_step1" element={<NoahTutorialLab2Page1 />} />
          <Route path="/tutorial/lab2_step2" element={<NoahTutorialLab2Page2 />} />
          <Route path="/tutorial/lab2_step3" element={<NoahTutorialLab2Page3 />} />
          <Route path="/tutorial/lab2_step4" element={<NoahTutorialLab2Page4 />} />
          <Route path="/tutorial/lab2_step5" element={<NoahTutorialLab2Page5 />} />
          <Route path="/tutorial/lab2_step6" element={<NoahTutorialLab2Page6 />} />
          <Route path="/tutorial/lab3_step1" element={<NoahTutorialLab3Page1 />} />
          <Route path="/tutorial/lab3_step2" element={<NoahTutorialLab3Page2 />} />
          <Route path="/tutorial/lab3_step3" element={<NoahTutorialLab3Page3 />} />
          <Route path="/tutorial/lab3_step4" element={<NoahTutorialLab3Page4 />} />
          <Route path="/tutorial/lab3_step5" element={<NoahTutorialLab3Page5 />} />
          <Route path="/tutorial/lab4_step1" element={<NoahTutorialLab4Page1 />} />
          <Route path="/tutorial/lab4_step2" element={<NoahTutorialLab4Page2 />} />
          <Route path="/tutorial/lab4_step3" element={<NoahTutorialLab4Page3 />} />
          <Route path="/tutorial/lab4_step4" element={<NoahTutorialLab4Page4 />} />
          <Route path="/tutorial/lab4_step5" element={<NoahTutorialLab4Page5 />} />
          <Route path="/tutorial/lab4_step6" element={<NoahTutorialLab4Page6 />} />
          <Route path="/download" element={<NoahDownloadPage />} />
        </Routes>
      </div>
    </div>
  );
}

// Define prop types
NoahPage.propTypes = {
  user: PropTypes.string.isRequired,
};

export default NoahPage;
