import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahTutorialLab2Page1() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab2_title">Lab2 Lua Script</h1>
          <h3 id="before-you-begin">Before you begin</h3>
          <p>Upon completing this tutorial, you'll learn the basics of Lua scripting and how to use Lua to interact with the Noah theatre.</p>
          <h2 id="prerequisites">Prerequisites</h2>
          <ul>
            <li>Complete Lab 1.</li>
          </ul>
          <h2 id="what-youll-learn">What you’ll learn</h2>
          <ul>
            <li>What is Lua script</li>
            <li>What is the framework of Lua script</li>
            <li>How to control Noah basic elements with Lua</li>
            <li>Write a Noah Example: 2D Meter</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab2Page1;
