import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIFontPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Font APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Font - Set</h3>
              <li><a href="#noahsetfont">NoahSetFont</a></li>
              <li><a href="#noahsetfontsize">NoahSetFontSize</a></li>
              <li><a href="#noahsettext">NoahSetText</a></li>
              <li><a href="#noahsettextalign">NoahSetTextAlign</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Font - Get</h3>
              <li><a href="#noahgetfont">NoahGetFont</a></li>
              <li><a href="#noahgetfontsize">NoahGetFontSize</a></li>
              <li><a href="#noahgettext">NoahGetText</a></li>
              <li><a href="#noahgettextalign">NoahGetTextAlign</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahsetfont">NoahSetFont</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetFont(string target, string font)</code></p>
          <h4 id="description">Description</h4>
          <p>Set font family of the target <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</li>
          <li><strong><code>font</code> (string):</strong> The name of a font family defined in font config, <strong>font.yml</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>In <strong>font.yml</strong>, a font family named MONO_REGULAR was defined as follows:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-string">%YAML</span> <span class="hljs-number">1.2</span><br/>

            <span class="hljs-attr">list:</span><br/>

            <span class="hljs-bullet">-</span> <span class="hljs-attr">font:</span> <span class="hljs-string">MONO_REGULAR</span><br/>
            {'  '}<span class="hljs-attr">path:</span> <span class="hljs-string">default/font/Azeret_Mono/AzeretMono-Regular.ttf</span><br/>

          </div></code></pre>
          <p>To apply MONO_REGULAR to the font family of the Text2D element with id warning_message:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetFont(<span class="hljs-string">"warning_message"</span>, <span class="hljs-string">"MONO_REGULAR"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfontsize">NoahSetFontSize</a> - Set font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettext">NoahSetText</a> - Set text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettextalign">NoahSetTextAlign</a> - Set the text align position of multi-line text in the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfont">NoahGetFont</a> - Get font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfontsize">NoahGetFontSize</a> - Get font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettext">NoahGetText</a> - Get text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettextalign">NoahGetTextAlign</a> - Get the text align position of multi-line text in the target Text2D or Text3D element.</li>
          </ul>
          <hr />
          <h3 id="noahsetfontsize">NoahSetFontSize</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetFontSize(string target, number fontsize)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>fontsize</em> of the <em>target</em> <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</li>
          <li><strong><code>fontsize</code> (number):</strong> The size of the font.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set the font size of the Text2D element with id warning_message to 30:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetFont(<span class="hljs-string">"warning_message"</span>, <span class="hljs-number">30</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfont">NoahSetFont</a> - Set font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettext">NoahSetText</a> - Set text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettextalign">NoahSetTextAlign</a> - Set the text align position of multi-line text in the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfont">NoahGetFont</a> - Get font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfontsize">NoahGetFontSize</a> - Get font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettext">NoahGetText</a> - Get text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettextalign">NoahGetTextAlign</a> - Get the text align position of multi-line text in the target Text2D or Text3D element.</li>
          </ul>
          <hr />
          <h3 id="noahsettext">NoahSetText</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetText(string target, string text)</code></p>
          <h4 id="description">Description</h4>
          <p>Set text content of the target <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</li>
          <li><strong><code>text</code> (string):</strong> The text content to be displayed.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set the text content of the Text2D element with id warning_message to &quot;Lane departure&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetText(<span class="hljs-string">"warning_message"</span>, <span class="hljs-string">"Lane departure"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfont">NoahSetFont</a> - Set font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahsetfontsize">NoahSetFontSize</a> - Set font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettextalign">NoahSetTextAlign</a> - Set the text align position of multi-line text in the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfont">NoahGetFont</a> - Get font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfontsize">NoahGetFontSize</a> - Get font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettext">NoahGetText</a> - Get text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettextalign">NoahGetTextAlign</a> - Get the text align position of multi-line text in the target Text2D or Text3D element.</li>
          </ul>
          <hr />
          <h3 id="noahsettextalign">NoahSetTextAlign</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetTextAlign(string target, number align)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the text align position of multi-line text in the <em>target</em> <strong>Text2D</strong> or <strong>Text3D</strong> element, to the <em>align</em> value.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li>
          <p><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          </li>
          <li>
          <p><strong><code>align</code> (number):</strong> The text align position of a multi-line text.
          The align should be a real number between 0.0 and 1.0. Text will be aligned on the left side when align is 0.0, and aligned on the right side when align is 1.0. For align value between 0.0 and 1.0, the align position will be linearly interpolated for progressive animation. The following table lists the most commonly used align values:</p>
          <table>
          <thead>
          <tr>
          <th>Align</th>
          <th>Meaning</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>0</td>
          <td>Align text on the left side</td>
          </tr>
          <tr>
          <td>0.5</td>
          <td>Align text at the center</td>
          </tr>
          <tr>
          <td>1.0</td>
          <td>Align text on the right side</td>
          </tr>
          </tbody>
          </table>
          </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To align text on the left side:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetTextAlign(<span class="hljs-string">"warning_message"</span>, <span class="hljs-number">0.0</span>)
          </div></code></pre>
          <p>To align text at the center:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetTextAlign(<span class="hljs-string">"warning_message"</span>, <span class="hljs-number">0.5</span>)
          </div></code></pre>
          <p>To align text on the right side:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetTextAlign(<span class="hljs-string">"warning_message"</span>, <span class="hljs-number">1.0</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfont">NoahSetFont</a> - Set font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahsetfontsize">NoahSetFontSize</a> - Set font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettext">NoahSetText</a> - Set text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfont">NoahGetFont</a> - Get font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfontsize">NoahGetFontSize</a> - Get font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettext">NoahGetText</a> - Get text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettextalign">NoahGetTextAlign</a> - Get the text align position of multi-line text in the target Text2D or Text3D element.</li>
          </ul>
          <hr />
          <h3 id="noahgetfont">NoahGetFont</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetFont(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the name of the font family applied to the <em>target</em> <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns an empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The name of the font family applied to the <em>target</em> element when <em>target</em> is a <strong>Text2D</strong> or <strong>Text3D</strong> element. An empty string otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the font family applied:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> font_family = NoahGetFont(<span class="hljs-string">"warning_message"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"font_family is: "</span> .. font_family)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfont">NoahSetFont</a> - Set font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahsetfontsize">NoahSetFontSize</a> - Set font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettext">NoahSetText</a> - Set text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettextalign">NoahSetTextAlign</a> - Set the text align position of multi-line text in the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfontsize">NoahGetFontSize</a> - Get font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettext">NoahGetText</a> - Get text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettextalign">NoahGetTextAlign</a> - Get the text align position of multi-line text in the target Text2D or Text3D element.</li>
          </ul>
          <hr />
          <h3 id="noahgetfontsize">NoahGetFontSize</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetFontSize(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the font size of the <em>target</em> <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns 0.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The font size applied to the <em>target</em> element when <em>target</em> is a <strong>Text2D</strong> or <strong>Text3D</strong> element. 0 would be returned otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the font size applied:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> font_size = NoahGetFontSize(<span class="hljs-string">"warning_message"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"font_size is: "</span> .. font_size)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfont">NoahSetFont</a> - Set font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahsetfontsize">NoahSetFontSize</a> - Set font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettext">NoahSetText</a> - Set text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettextalign">NoahSetTextAlign</a> - Set the text align position of multi-line text in the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfont">NoahGetFont</a> - Get font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettext">NoahGetText</a> - Get text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettextalign">NoahGetTextAlign</a> - Get the text align position of multi-line text in the target Text2D or Text3D element.</li>
          </ul>
          <hr />
          <h3 id="noahgettext">NoahGetText</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetText(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the text stored in <em>target</em> <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns an empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The text content stored in <em>target</em> element when <em>target</em> is a <strong>Text2D</strong> or <strong>Text3D</strong> element. An empty string otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the text of a <strong>Text2D</strong> or <strong>Text3D</strong> element:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> text = NoahGetText(<span class="hljs-string">"warning_message"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"text is: "</span> .. text)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfont">NoahSetFont</a> - Set font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahsetfontsize">NoahSetFontSize</a> - Set font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettext">NoahSetText</a> - Set text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettextalign">NoahSetTextAlign</a> - Set the text align position of multi-line text in the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfont">NoahGetFont</a> - Get font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfontsize">NoahGetFontSize</a> - Get font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettextalign">NoahGetTextAlign</a> - Get the text align position of multi-line text in the target Text2D or Text3D element.</li>
          </ul>
          <hr />
          <h3 id="noahgettextalign">NoahGetTextAlign</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetTextAlign(string id)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the text align position of <em>target</em> <strong>Text2D</strong> or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Text2D</strong> and <strong>Text3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns 0.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Text2D</strong> or <strong>Text3D</strong> element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The text align applied to the <em>target</em> element when <em>target</em> is a <strong>Text2D</strong> or <strong>Text3D</strong> element. 0 would be returned otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the text align:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> text_align = NoahGetTextAlign(<span class="hljs-string">"warning_message"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"text_align is: "</span> .. text_align)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetfont">NoahSetFont</a> - Set font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahsetfontsize">NoahSetFontSize</a> - Set font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettext">NoahSetText</a> - Set text content of the target Text2D or Text3D element.</li>
          <li><a href="#noahsettextalign">NoahSetTextAlign</a> - Set the text align position of multi-line text in the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfont">NoahGetFont</a> - Get font family of the target Text2D or Text3D element.</li>
          <li><a href="#noahgetfontsize">NoahGetFontSize</a> - Get font size of the target Text2D or Text3D element.</li>
          <li><a href="#noahgettext">NoahGetText</a> - Get text content of the target Text2D or Text3D element.</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIFontPage;
