import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahTutorialLab3Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab3_title"> Lab3 Button and Slider</h1>
          <h3 id="before-you-begin">Before you begin</h3>
          <p>In this lab, we focus on adding UI components to the IVI project. We’ll create buttons to switch between pages in the IVI project, and a slider to change the target temperature in the air conditioner page.</p>
          <h2 id="prerequisites">Prerequisites</h2>
          <ul>
            <li>Complete Lab 2.</li>
          </ul>
          <h2 id="what-youll-learn">What you’ll learn</h2>
          <ul>
              <li>Concept of UI components
              <ul>
                <li>What UI component we can use</li>
                <li>How to operate UI system in Lua.</li>
                <li>How to setup appearance of UI component.</li>
                <li>How to implement UI event in Lua.</li>
              </ul>
            </li>
            <li>Adding a Button</li>
            <li>Adding a Slider</li>
            <li>Run IVI Project in Practice</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab3Page;
