import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
import noahRuntimeWorkflow from './image/Noah_runtime_workflow.png';
import designbutton from './image/4_figma_1_design_button.png';
import framebutton from './image/4_figma_2_frame_button.png';
import dimension from './image/4_figma_3_dimension.png';
import addbackground from './image/4_figma_4_add_background.png';
import addimage from './image/4_figma_5_add_image.png';
import addtext from './image/4_figma_6_add_text.png';
import addquad from './image/4_figma_7_add_quad.png';
import flowOfPlaceElement from './image/4_flow_of_place_element.png';
import layoutExample from './image/4_xml_1_layout_example.png';
import xmlSample from './image/4_xml_2_xml_sample.png';
import noahUi from './image/4_ui_1_noah_ui.png';

/*eslint-disable */

function NoahTutorialLab1Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="before-you-begin">Lab1 UI Layout Construction</h1>
          <p>Upon completing this tutorial, you'll learn how to load 2D assets and utilize them as the 2D user interface using Noah.</p>
          <h2 id="prerequisites">Prerequisites</h2>
          <ul>
          <li>None</li>
          </ul>
          <h2 id="what-youll-learn">What you’ll learn</h2>
          <ul>
            <li>What is Noah Basic Element</li>
            <li>What attributes are in element</li>
            <li>How to place UI in Figma</li>
            <li>How to place UI in XML</li>  
          </ul>
          <h1 id="introduction">Introduction</h1>
          <p>The Noah workflow is illustrated in the diagram below. The runtime interprets both the XML and Lua files, which dictate the UI layout and software behavior. Additionally, it can seamlessly integrate with external user libraries.</p>
          <p><img src={noahRuntimeWorkflow} alt="alt text" width={960}/></p>
          <p><strong>Lua</strong> is a lightweight, high-level, multi-paradigm programming language designed primarily for embedded use in applications. Lua is cross-platform, since the interpreter of compiled bytecode is written in ANSI C, and Lua has a relatively simple C API to embed it into applications. For more information, please go to https://www.lua.org/</p>
          <h2 id="noah-project">Noah Project</h2>
          <pre class="hljs"><code><div>root
          |__ noah                     # Binary for executing the Noah runtime environment.<br />
          |__ config<br />
          |____ font.yml               # Configuration file for the font manager to manage font file paths.<br />
          |____ graphic.yml            # Configuration file for Noah OpenGL (usually not modified).<br />
          |____ logger.yml             # Configuration file for managing Noah logger settings.<br />
          |__ project<br />
          |____ demo_meter             # User-specific project directory.<br />
          |________ assets             # Directory for storing assets such as images, 3D models, and other resources.<br />
          |________ script<br />
          |____________ script.lua       # Main entry point script for the Noah runtime.<br />
          |____________ keyboard.lua     # Script for handling keyboard input events.<br />
          |____________ mouse.lua        # Script for handling mouse input events.<br />
          |____________ meter.lua        # Script created by user contain user defined function.<br />
          |________ noah.yml             # Configuration file for setting up the Noah window environment.<br />
          |________ main.xml             # XML file for defining the UI layout.<br />
          </div></code></pre>
          <ul>
          <li><strong>noah.yml (Configuration)</strong>:
          <ul>
          <li>A configuration file used to define the <em>window's size</em> and <em>position</em>. for the Noah UI. It serves as the foundational setup for your application.</li>
          <li>Because the window <code>id</code> is <code>main</code>, we'll have a <code>main.xml</code> file to describe what Noah elements are included in this project and how they are organized.</li>
          </ul>
          </li>
          </ul>
          <pre class="hljs"><code><div>%YAML 1.2 <br />

          editing: 0  # Indicates the editing state. Default is 0. When set to 1, the Noah window will automatically adjust to fit the display resolution while maintaining the original aspect ratio.<br />
          window: <br />
            {'  '}- id: main   # Unique identifier for the window; required when running multiple Noah projects simultaneously.<br />
              {'  '}{'  '}w: 1920    # Width of the window in pixels.<br />
              {'  '}{'  '}h: 720     # Height of the window in pixels.<br />
              {'  '}{'  '}x: 0       # Horizontal position of the window in pixels (not supported on embedded Wayland due to window management policy limitations).<br />
              {'  '}{'  '}y: 0       # Vertical position of the window in pixels.<br />
          </div></code></pre>
          <ul>
          <li><strong>main.xml (XML)</strong>:
          <ul>
          <li>Defines how the UI layout is rendered, including positioning, rotation angles, and dimensions.</li>
          <li>The XML structure is composed of various elements, similar to tags in HTML, and forms the visual framework of your application.</li>
          </ul>
          </li>
          </ul>
          <pre class="hljs"><code><div><span class="hljs-meta">&lt;?xml version="1.0" encoding="UTF-8"?&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">Scene</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"main"</span> <span class="hljs-attr">sizepx</span>=<span class="hljs-string">"1920, 720"</span> <span class="hljs-attr">sizemm</span>=<span class="hljs-string">"346, 130"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">eyepos</span>=<span class="hljs-string">"0, 90, 650"</span>&gt;</span><br />
              {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Group2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"group_1"</span> <span class="hljs-attr">enabled</span>=<span class="hljs-string">"1"</span>&gt;</span><br />
                  {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Text2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"hello"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"960, 240"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Hello, Noah"</span> /&gt;</span><br />
                  {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Quad2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"quad_1"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"960, 480"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"640"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"160"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0"</span> <span class="hljs-attr">color</span>=<span class="hljs-string">"#ffffffff"</span> /&gt;</span><br />
                  {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Image2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"image_1"</span> <span class="hljs-attr">src</span>=<span class="hljs-string">"asset/image/example.jpg"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"1600, 360"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0"</span>&gt;</span><br />
              {'  '}<span class="hljs-tag">&lt;/<span class="hljs-name">Group2D</span>&gt;</span><br />
          <span class="hljs-tag">&lt;/<span class="hljs-name">Scene</span>&gt;</span><br />
          </div></code></pre>
          <ul>
          <li>
          <p><strong>Assets</strong>:</p>
          <ul>
          <li>Contains the resources used for rendering the UI layout, such as <code>images</code>, <code>3D models</code>, and other assets. For example, the project introduced in this section includes a sample image stored in the <code>asset/image</code> folder.</li>
          </ul>
          </li>
          <li>
          <p><strong>Lua scripts</strong>:</p>
          <ul>
          <li>These scripts define the <em>program's logic</em> and <em>state machine</em>, acting as the backbone of the project. Lua scripts control user interactions and ensure smooth functionality throughout the application.</li>
          </ul>
          </li>
          </ul>
            <pre class="hljs"><code><div><span class="hljs-comment">-- Import the mouse module for handling mouse input events</span>
            <span class="hljs-built_in">require</span>(<span class="hljs-string">"mouse"</span>)
            <span class="hljs-comment">-- Import the keyboard module for handling keyboard input events</span>
            <span class="hljs-built_in">require</span>(<span class="hljs-string">"keyboard"</span>)


            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Initialize</span><span class="hljs-params">()</span></span>
                <span class="hljs-comment">-- Add any setup code here, such as initializing variables or loading resources</span>
            <span class="hljs-keyword">end</span>


            <span class="hljs-comment">-- @param interval: Time interval (in milliseconds or seconds) since the last update</span>
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span>
                <span class="hljs-comment">-- Add logic to handle updates, such as animations, input events, or game logic</span>
            <span class="hljs-keyword">end</span>


            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Terminate</span><span class="hljs-params">()</span></span>
                <span class="hljs-comment">-- Add any cleanup code here, such as releasing resources or saving data</span>
            <span class="hljs-keyword">end</span>

          </div></code></pre>
          <h1 id="noah-basic-elements">Noah Basic Elements</h1>
          <p>Noah UI is defined using XML and is composed of various <strong>Basic Noah Elements</strong>, which resemble tags in HTML. You can customize the appearance, translation, rotation, and other properties of these elements through their attributes.</p>
          <p>In the <code>demo_hello_noah</code> project, you will encounter five of the most commonly used <strong>Basic Noah Elements</strong>:</p>
          <ul>
          <li><strong>Scene</strong></li>
          <li><strong>Group2D</strong></li>
          <li><strong>Quad2D</strong></li>
          <li><strong>Text2D</strong></li>
          <li><strong>Image2D</strong></li>
          </ul>
          <p>Every Noah element requires a unique <code>id</code> attribute, which serves as the unique identifier for that element. No two elements within the same scene can share the same <code>id</code>. Each of these elements will be introduced below.</p>
          <hr />
          <h3 id="1-scene">1. <strong>Scene</strong></h3>
          <p>The root node of the entire scene. It has key functions:</p>
          <ul>
          <li>Define the screen specifications by attributes <code>sizepx</code> and <code>sizemm</code>, which the units are pixel and millimeters respectively.</li>
          <li>Specify the viewer's position by attribute <code>eyepos</code>, which is useful in the 3D UI construction.</li>
          </ul>
          <hr />
          <h3 id="2-group2d">2. <strong>Group2D</strong></h3>
          <p>A container node that does not render anything on its own but can hold multiple elements in 2D space. Its key features are:</p>
          <ul>
          <li>Useful for grouping elements to manipulate them collectively.</li>
          <li>The <code>enabled</code> attribute can toggle whether elements within the group are active, without having to modify each child element individually.</li>
          </ul>
          <hr />
          <h3 id="3-quad2d">3. <strong>Quad2D</strong></h3>
          <p>A <strong>Quad2D</strong> is a flat rectangle element used to render basic shapes. It is highly customizable and supports the following attributes:</p>
          <ul>
          <li><code>w</code>, <code>h</code>: Control the width and height of the rectangle.</li>
          <li><code>r</code>: Control rotation.</li>
          <li><code>t</code>: Control the position.</li>
          <li><code>anchor</code>: Specifies the reference point of the element. Requires two values, representing the x-axis and y-axis positions, and works together with the <code>t</code> attribute.
          <ul>
          <li>Value Range:
          <ul>
          <li><code>0</code> represents the <em>Left/Top</em> side of the Quad.</li>
          <li><code>1</code> represents the <em>Right/Bottom</em> side of the Quad.</li>
          </ul>
          </li>
          <li>The anchor point does not have to be within the Quad2D itself. For example, an external anchor point such as <code>anchor=&quot;-5.0, 8.4&quot;</code> is valid.</li>
          </ul>
          </li>
          <li><code>color</code>:<br />
          Defines the color used to render the Quad2D element. The color is specified using the <code>#AARRGGBB</code> hexadecimal format, with the following rendering options:
          <ol>
          <li>One Color:
          <ul>
          <li>Applies a single uniform color to the entire rectangle.</li>
          </ul>
          </li>
          <li>Two Colors:
          <ul>
          <li>Applies a gradient using two colors, with the first color on the left side and the second color on the right side.</li>
          </ul>
          </li>
          <li>Four Colors:
          <ul>
          <li>Applies a gradient using four colors, with colors representing the top-left, top-right, bottom-left, and bottom-right corners, blended smoothly across the rectangle.</li>
          </ul>
          </li>
          </ol>
          </li>
          </ul>
          <hr />
          <h3 id="4-text2d">4. <strong>Text2D</strong></h3>
          <p>A flat element for displaying text. Its key attributes are:</p>
          <ul>
          <li><code>text</code>: Specifies the text content to display.</li>
          <li><code>size</code>: Controls the font size.</li>
          <li><code>r</code>: Controls rotation.</li>
          <li><code>t</code>: Controls the position.</li>
          <li><code>font</code>: Specifies the font type. All referenced fonts must be defined in the font configuration file (the path is config/font.yml) to work properly.</li>
          </ul>
          <hr />
          <h3 id="5-image2d">5. <strong>Image2D</strong></h3>
          <p>A flat element for displaying images. Its key attributes are:</p>
          <ul>
          <li><code>src</code>: Specifies the file path of the image to be displayed. This attribute is mandatory.</li>
          <li><code>w</code>, <code>h</code>: Control the width and height of the image.</li>
          <li><code>r</code>: Controls rotation.</li>
          <li><code>t</code>: Controls the position.</li>
          <li><code>anchor</code>: Specifies the reference point of the element. Requires two values, representing the x-axis and y-axis positions, and works together with the <code>t</code> attribute.
          <ul>
          <li>Value Range:
          <ul>
          <li><code>0</code> represents the <em>Left/Top</em> side of the Quad.</li>
          <li><code>1</code> represents the <em>Right/Bottom</em> side of the Quad.</li>
          </ul>
          </li>
          <li>The anchor point does not have to be within the Quad2D itself. For example, an external anchor point such as <code>anchor=&quot;-5.0, 8.4&quot;</code> is valid.</li>
          </ul>
          </li>
          </ul>
          <hr />
          <p>For more detailed information about these elements, refer to the <a href="/UI_layout_construction"><code>UI_layout_construction</code></a> document.</p>
          <h1 id="construct-noah-layout">Construct Noah Layout</h1>
          <p>To construct a Noah Layout, we have divided the assignment into several tasks.</p>
          <ol>
          <li>Prepare UI assets.</li>
          <li>Place UI in Figma.</li>
          <li>Place UI in XML.</li>
          </ol>
          <p><img src={flowOfPlaceElement} alt="image"/></p>
          <p>Let us describe them step by step.</p>
          <h2 id="prepare-ui-assets">Prepare UI assets</h2>
          <ol>
          <li>Navigate to the <code>project/demo_hello_noah/asset/image</code> directory. For the NOAH project, it's our convention to place 2D image files within the image folder, enabling the NOAH main program to read them.</li>
          <li>Additionally, we can organize the images further by placing them into subfolders for categorization.</li>
          </ol>
          <h2 id="place-ui-in-figma">Place UI in Figma</h2>
          <p>For the UI layout, we can use the 3rd tool to help us define the coordinate and preview. We use Figma for example here.</p>
          <ol>
          <li>
          <p>Register for a Figma account at https://www.figma.com/.</p>
          </li>
          <li>
          <p>Log in using your newly created Figma account.</p>
          </li>
          <li>
          <p>Click on the “Design File” button to create a new design file.</p>
          <ul>
          <li><img src={designbutton} alt="image"/></li>
          </ul>
          </li>
          <li>
          <p>To initiate a new canvas, click on the button shown in Images below. Create a rectangle by dragging and dropping with the mouse. Once the rectangle is formed, select it and enter the desired values into the &quot;Frame&quot; section. For our project, we've set the dimensions to 1920 x 720 with coordinates at (0, 0), matching our project's dimensions.</p>
          <ul>
          <li>
          <p><img src={framebutton} alt="image"/></p>
          </li>
          <li>
          <p><img src={dimension} alt="image"/></p>
          </li>
          </ul>
          </li>
          <li>
          <p>You can fill the canvas color by setting “Fill” section. For this example, we use 193333, the default background color of Noah.</p>
          <ul>
                <li><img src={addbackground} alt="image"/></li>
          </ul>
          </li>
          <li>
          <p>Simply drag and drop the image from your folder into the design area. Once placed, you can adjust the image's parameters or reposition it by dragging and dropping.</p>
          <ul>
          <li><img src={addimage} alt="image"/></li>
          </ul>
          </li>
          <li>
          <p>Add a text element by clicking on the button shown in image below. Create a text element by clicking on the canvas and typing in &quot;Hello, Noah!&quot;. Once placed, you can adjust the text element's parameters, or reposition it by dragging and dropping.</p>
          <ul>
          <li><img src={addtext} alt="image"/></li>
          </ul>
          </li>
          <li>
          <p>Add rectangle elements by clicking on the button shown in image below. Create a rectangle by dragging and dropping with the mouse. Once placed, you can adjust the rectangle element's  parameters, or reposition it by dragging and dropping.</p>
          <ul>
          <li><img src={addquad} alt="image"/></li>
          </ul>
          </li>
          <li>
          <p>Finally, we build a complete UI layout from the Figma.</p>
          </li>
          </ol>
          <h2 id="place-ui-in-xml">Place UI in XML</h2>
          <p>After completing the UI in Figma, you can select an element in Figma to identify its coordinates and dimensions, and then directly input these values into the XML file. Please note that if the UI element is within a group, the coordinates will be relative to that group, rather than being absolute coordinates.</p>
          <ul>
          <li>
          <p><img src={layoutExample} alt="image"/></p>
          </li>
          <li>
          <p><img src={xmlSample} alt="image"/></p>
          </li>
          </ul>
          <h2 id="run-the-project">Run the Project</h2>
          <ol>
          <li>Run Noath Theatre with project lab_demo_cluster</li>
          </ol>
          <pre class="hljs"><code><div>$ cd noah.lab_105a/
          $ noah_theatre-x64-linux project/lab_demo_cluster/
          </div></code></pre>
          <ol start="2">
          <li>A window shows on your screen like this.</li>
          </ol>
          <ul>
          <li><img src={noahUi} alt="image"/></li>
          </ul>
          <ol start="3">
          <li>Use hot key: CTRL + Esc to exit the Noah Theatre</li>
          </ol>
          <h1 id="conclusion">Conclusion</h1>
          <h2 id="summary">Summary</h2>
          <ul>
          <li>Understand the tags and attributes of Noah Basic Element.</li>
          <li>Prepare UI Assets.</li>
          <li>Layout UI in Figma.</li>
          <li>Place UI in the XML file.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab1Page;
