import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPISliderPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Slider APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#noahsetslidervalue">NoahSetSliderValue</a></li>
              <li><a href="#noahsetsliderrange">NoahSetSliderRange</a></li>
              <li><a href="#noahsetsliderintegerrange">NoahSetSliderIntegerRange</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 104a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahsetslidervalue">NoahSetSliderValue</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetSliderValue(string target, number v)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em> value to <em>v</em>, where <em>target</em> is a <strong>Slider</strong> component.
          Users can configure the (min, max) range and value type of <em>v</em> by using <strong>NoahSetSliderRange</strong> or <strong>NoahSetSliderIntegerRange</strong>.
          If <em>target</em> is not a <strong>Slider</strong>, Noah will simply log an error message.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be a <strong>Slider</strong>.</li>
          <li><strong><code>v</code> (number):</strong> The value expected to set.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set slider &quot;sample_slider&quot; value to 0.5:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetSliderValue(<span class="hljs-string">"sample_slider"</span>, <span class="hljs-number">0.5</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetsliderrange">NoahSetSliderRange</a> - Set slider range in float</li>
          <li><a href="#noahsetsliderintegerrange">NoahSetSliderIntegerRange</a> - Set slider range in integer</li>
          </ul>
          <hr />
          <h3 id="noahsetsliderrange">NoahSetSliderRange</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetSliderRange(string target, number min, number max)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the range of float value for <em>target</em> to <em>min</em> and <em>max</em>, where <em>target</em> is a <strong>Slider</strong> component.
          After user using this function, slider value will be treated as a float.
          If <em>target</em> is not a <strong>Slider</strong>, Noah will simply log an error message.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be a <strong>Slider</strong>.</li>
          <li><strong><code>min</code> (number):</strong> The min value expected to set.</li>
          <li><strong><code>max</code> (number):</strong> The max value expected to set.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set slider &quot;sample_slider&quot; range to -0.5 ~ 0.5:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetSliderRange(<span class="hljs-string">"sample_slider"</span>, <span class="hljs-number">-0.5</span>, <span class="hljs-number">0.5</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetslidervalue">NoahSetSliderValue</a> - Set slider value</li>
          <li><a href="#noahsetsliderintegerrange">NoahSetSliderIntegerRange</a> - Set slider range in integer</li>
          </ul>
          <hr />
          <h3 id="noahsetsliderintegerrange">NoahSetSliderIntegerRange</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetSliderIntegerRange(string target, number min, number max)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the range of integer value for <em>target</em> to <em>min</em> and <em>max</em>, where <em>target</em> is a <strong>Slider</strong> component.
          After user using this function, slider value will be treated as a integer.
          If <em>target</em> is not a <strong>Slider</strong>, Noah will simply log an error message.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be a <strong>Slider</strong>.</li>
          <li><strong><code>min</code> (number):</strong> The min value expected to set.</li>
          <li><strong><code>max</code> (number):</strong> The max value expected to set.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set slider &quot;sample_slider&quot; range to 1 ~ 5:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetSliderIntegerRange(<span class="hljs-string">"sample_slider"</span>, <span class="hljs-number">1</span>, <span class="hljs-number">5</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetslidervalue">NoahSetSliderValue</a> - Set slider value</li>
          <li><a href="#noahsetsliderrange">NoahSetSliderRange</a> - Set slider range in float</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPISliderPage;
