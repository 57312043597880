import React, { useEffect, useState } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahXMLReferencePage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Noah Elements</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#scene">Scene</a></li>
              <li><a href="#group2d">Group2D</a></li>
              <li><a href="#group3d">Group3D</a></li>
              <li><a href="#quad2d">Quad2D</a></li>
              <li><a href="#quad3d">Quad3D</a></li>
              <li><a href="#image2d">Image2D</a></li>
              <li><a href="#image3d">Image3D</a></li>
              <li><a href="#text2d">Text2D</a></li>
              <li><a href="#text3d">Text3D</a></li>
              <li><a href="#video">Video</a></li>
              <li><a href="#model3d">Model3D</a></li>
              <li><a href="#light3d">Light3D</a></li>
              <li><a href="#button">Button</a></li>
              <li><a href="#slider">Slider</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="xml-reference">XML Reference</h2>
          <h4 id="version-105a">version 105a</h4>
          <hr />
          <h2 id="noah-ui-layout-configuration">Noah UI Layout Configuration</h2>
          <p>The Noah UI layout is defined by an XML file, which consists of various <strong>Basic Noah Elements</strong>. These elements function similarly to HTML tags, and their appearance, translation, rotation, and other properties can be modified by adjusting the attributes within each tag.</p>
          <p>Each <strong>Basic Noah Element</strong> serves as a building block for the user interface, allowing for a flexible and customizable layout. By modifying the attributes of these elements, you can control their visual properties and behavior within the UI.</p>
          <h2 id="basic-noah-elements">Basic Noah Elements</h2>
          <p>Noah has the following types of basic elements:</p>
          <ul>
          <li><strong>Scene</strong>: The root node of the entire scene, where you can define the specifications of your screen and the position of viewer.</li>
          <li><strong>Group2D</strong>: A node that doesn't render anything itself, but can hold multiple elements in <em>2D space</em> as a group for manipulation.</li>
          <li><strong>Group3D</strong>:A node that doesn't render anything itself, but can hold multiple elements in <em>3D space</em> as a group for manipulation.</li>
          <li><strong>Quad2D</strong>: A flat rectangle that allows customized colors and opacity.</li>
          <li><strong>Quad3D</strong>: Offers a visual effect similar to <strong>Quad2D</strong>, but with the ability to freely rotate and move in <em>3D space</em>.</li>
          <li><strong>Image2D</strong>: A flat element to display images.</li>
          <li><strong>Image3D</strong>: Offers a visual effect similar to <strong>Image2D</strong>, but with the ability to freely rotate and move in <em>3D space</em>.</li>
          <li><strong>Text2D</strong>: A flat element which can display a piece of text.</li>
          <li><strong>Text3D</strong>: Offers a visual effect similar to <strong>Text2D</strong>, but with the ability to freely rotate and move in <em>3D space</em>.</li>
          <li><strong>Video</strong>: A flat element to display videos.</li>
          <li><strong>Model3D</strong>: A element to render 3D models, supporting the glTF standard format.</li>
          <li><strong>Light3D</strong>: A light source capable of illuminating a 3D scene. Note that the light source only affect elements defined after it.</li>
          <li><strong>Button</strong>: A flat button that allows users to interact with Noah.</li>
          <li><strong>Slider</strong>: A flat slider that allows users to interact with Noah.</li>
          </ul>
          <p>Most elements have both 2D and 3D versions, each with distinct motion control interfaces and coordinate systems. 2D elements are within a 2D plane based on screen pixels, with the top-left corner of the screen as the origin. On the other hand, 3D elements are within a <em>3D space</em> based on real-world physical dimensions, using the spatial origin defined by your <strong>Scene</strong> element.
          Although 3D elements offer more freedom in usage, 2D elements possess a natural advantage of ease to use when strict alignment and pixel-level accuracy are required.
          A brief overview of the various elements and fundamental functionalities follows, with their typical applications.</p>
          <hr />
          <h3 id="scene"><strong>Scene</strong></h3>
          <p>The root element representing the entire scene. You can use the sizepx attribute to set its width and height in <em>2D space</em> (in pixels), the sizemm attribute to set the size of <strong>Scene</strong> in <em>3D space</em> (in millimeters).</p>
          <p>To display the 3D elements correctly, you should use the <strong>r</strong> attribute and <strong>t</strong> attribute to set the rotation angles (in degrees) and the position (in millimeters) of the screen, and use the <strong>eyepos</strong> attribute to define the eye position (in millimeters).</p>
          <p>All other elements should be arranged as the sub-elements of a <strong>Scene</strong>.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>sizepx</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>sizemm</td>
          <td>number[2]</td>
          <td>millimeters</td>
          </tr>
          <tr>
          <td>eyepos</td>
          <td>number[3]</td>
          <td>millimeters</td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number[3]</td>
          <td>degrees</td>
          </tr>
          </tbody>
          </table>
          <p>The following XML code shows how to define a <strong>Scene</strong> element:</p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-tag">&lt;<span className="hljs-name">Scene</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"main"</span> <span className="hljs-attr">sizepx</span>=<span className="hljs-string">"-1280, -720"</span> <span className="hljs-attr">sizemm</span>=<span className="hljs-string">"338, 190"</span> <span className="hljs-attr">eyepos</span>=<span className="hljs-string">"0, 90, 650"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"0,0,0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0,0,0"</span>&gt;</span><br/>
            {'  '}<span className="hljs-comment">&lt;!-- Sub-elements go here --&gt;</span><br/>
            <span className="hljs-tag">&lt;/<span className="hljs-name">Scene</span>&gt;</span><br/>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:</p>
          <pre className="hljs hljs-content"><code><div>
            sizepx=<span className="hljs-string">"640, 480"</span><br/>
            sizemm=<span className="hljs-string">"240, 180"</span><br/>
            eyepos=<span className="hljs-string">"0, 0, 600.0"</span><br/>
            t=<span className="hljs-string">"0, 0, 0"</span><br/>
            r=<span className="hljs-string">"0, 0, 0"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="group2d"><strong>Group2D</strong></h3>
          <p><strong>Group2D</strong> is a node that doesn't render anything itself, but can hold multiple elements in <em>2D space</em> as a group for manipulation.</p>
          <p>You can use the <strong>r</strong> attribute of a <strong>Group2D</strong> element to define its rotation angle on the screen plane, the <strong>t</strong> attribute to position it in the x and y directions, and the s attribute to scale it along the x and y directions so that all sub-elements can be handled in a grouped manner.</p>
          <p>The enabled attribute is also very useful in real application because it can enable/disable all sub-elements of the group. (You can set enabled to 1 to enable it, and 0 to disable it.)</p>
          <p>You can use <strong>alpha</strong> attribute to adjust the transparency of the whole group.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>s</td>
          <td>number[2]</td>
          <td>scale</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number</td>
          <td>degrees</td>
          </tr>
          </tbody>
          </table>
          <p>The following XML code define a <strong>Group2D</strong> element:</p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-tag">&lt;<span className="hljs-name">Group2D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"group_1"</span> <span className="hljs-attr">enabled</span>=<span className="hljs-string">"1"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"100, 100"</span> <span className="hljs-attr">s</span>=<span className="hljs-string">"1, 1"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0"</span>&gt;</span><br />
            {'  '}<span className="hljs-comment">&lt;!-- Sub-elements go here --&gt;</span><br/>
            <span className="hljs-tag">&lt;/<span className="hljs-name">Group2D</span>&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0"</span><br />
            s=<span className="hljs-string">"1, 1"</span><br/>
            r=<span className="hljs-string">"0"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="group3d"><strong>Group3D</strong></h3>
          <p><strong>Group3D</strong> is a node that doesn't render anything itself, but can hold multiple elements in <em>3D space</em> as a group for manipulation.</p>
          <p>You can use the <strong>r</strong> attribute of a <strong>Group3D</strong> element to define its rotation angles, the <strong>t</strong> attribute to position it in the x, y, and z directions (in millimeters), and the s attribute to scale it along the x, y, and z directions so that all sub-elements can be handled in a grouped manner.</p>
          <p>The enabled attribute is also very useful in real application because it can enable/disable all sub-elements of the group. (You can set enabled to 1 to enable it, and 0 to disable it.)</p>
          <p>You can use <strong>alpha</strong> attribute to adjust the transparency of the whole group.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>s</td>
          <td>number[3]</td>
          <td>scale</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number[3]</td>
          <td>degrees</td>
          </tr>
          </tbody>
          </table>
          <p>The following XML code define a <strong>Group3D</strong> element:</p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-tag">&lt;<span className="hljs-name">Group3D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"group_2"</span> <span className="hljs-attr">enabled</span>=<span className="hljs-string">"1"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"10, 20, 30"</span> <span className="hljs-attr">s</span>=<span className="hljs-string">"1, 1, 1"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0, 0, 0"</span>&gt;</span><br />
            {'  '}<span className="hljs-comment">&lt;!-- Sub-elements go here --&gt;</span><br/>
            <span className="hljs-tag">&lt;/<span className="hljs-name">Group3D</span>&gt;</span><br/>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0, 0"</span><br />
            s=<span className="hljs-string">"1, 1, 1"</span><br/>
            r=<span className="hljs-string">"0, 0, 0"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span>
          </div></code></pre>
          <hr />
          <h3 id="quad2d"><strong>Quad2D</strong></h3>
          <p><strong>Quad2D</strong> is designed for displaying a colored rectangle in <em>2D space</em>.</p>
          <p>You can use its <strong>w</strong> and <strong>h</strong> attributes to set its width and height in pixels, the <strong>r</strong> attribute to set its rotation angle (clockwise) in degrees, and use the <strong>t</strong> attribute to define its position in the x and y directions, measured in pixels.</p>
          <p>The actual reference point of its position is defined by the <strong>anchor</strong> attribute. The 0 of <strong>anchor</strong> means <em>Left/Top</em> side of the quad, while 1 is to the <em>Right/Bottom</em> side. The anchor need not to be set inside the quad. An outside anchor setting as anchor=&quot;-5.0, 8.4&quot; is legal.</p>
          <p>The rectangle's color is determined by the color attribute, represented in the #AARRGGBB hexadecimal format.</p>
          <p>You can use one color to set a single-colored rectangle, two colors to create a left-to-right gradient rectangle, or four colors to assign colors to each of the four corners.  If 4 colors are assigned, the first color is for Top-Left corner, the second color is for Top-Right corner, the third color is for Bottom-Left corner, and the last color is for Bottom-Right corner.</p>
          <p>You can use <strong>alpha</strong> attribute to apply transperency to a quad element. The <strong>alpha</strong> attribute works independently. It is not affected by the alpha channel of quad colors. Even if you set the quad colors with low alpha, you can add an <strong>alpha</strong> attribute to make the quad more transparent.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>w</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>h</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>color</td>
          <td>hex number[]</td>
          <td></td>
          </tr>
          <tr>
          <td>anchor</td>
          <td>number[2]</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>For example, the following line of XML code defines a translucent green rectangle:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Quad2D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"quad_1"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"200"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"100"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"120, 50"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#8020dd44"</span> <span className="hljs-attr">anchor</span>=<span className="hljs-string">"0.5, 0"</span> /&gt;</span>
          </div></code></pre>
          <p>You can also individually assign colors to the left and right sides, or to the four corners, as demonstrated here:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Quad2D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"quad_2"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"200"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"40"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"30"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"240, 150"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#8020dd44 #ff8080dd"</span> <span className="hljs-attr">anchor</span>=<span className="hljs-string">"0.5, 0"</span> /&gt;</span>
          <span className="hljs-tag">&lt;<span className="hljs-name">Quad2D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"quad_3"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"150"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"60"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"-20"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"300, 200"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#8020dd44 #555555 #8080dd #33667788"</span> <span className="hljs-attr">anchor</span>=<span className="hljs-string">"0.5, 0"</span> /&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:<br />
          <strong><em>Note:</em></strong> The color attribute cannot be omitted.</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0"</span><br/>
            r=<span className="hljs-string">"0"</span><br/>
            w=<span className="hljs-string">"1"</span><br/>
            h=<span className="hljs-string">"1"</span><br/>
            anchor=<span className="hljs-string">"0.5, 0.5"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="quad3d"><strong>Quad3D</strong></h3>
          <p><strong>Quad3D</strong> and <strong>Quad2D</strong> have the similar attributes. But <strong>Quad3D</strong> is a element that operates in a <em>3D space</em>. It can move along the direction perpendicular to the screen and is capable of unrestricted rotation.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>w</td>
          <td>number</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>h</td>
          <td>number</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>s</td>
          <td>number[3]</td>
          <td>scale</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number[3]</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>color</td>
          <td>hex number[]</td>
          <td></td>
          </tr>
          <tr>
          <td>anchor</td>
          <td>number[2]</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>There are some example of <strong>Quad3D</strong> definition:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">
            &lt;<span className="hljs-name">Quad3D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"quad_4"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"200"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"40"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"30, 15, 20"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"240, 150, 5"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#8020dd44 #ff8080dd"</span> /&gt;</span>
            <span className="hljs-tag">&lt;<span className="hljs-name">Quad3D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"quad_5"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"150"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"60"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"-20, 60, 90"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"300, 200, -30"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#8020dd44 #555555 #8080dd #33667788"</span> /&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:<br />
          <strong><em>Note:</em></strong> The color attribute cannot be omitted.</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0, 0"</span><br/>
            r=<span className="hljs-string">"0, 0, 0"</span><br/>
            w=<span className="hljs-string">"1"</span><br/>
            h=<span className="hljs-string">"1"</span><br/>
            anchor=<span className="hljs-string">"0.5, 0.5"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="image2d"><strong>Image2D</strong></h3>
          <p><strong>Image2D</strong> is designed for displaying images in <em>2D space</em>.</p>
          <p>You can use its <strong>src</strong> attribute to set the file path of the image (png or jpg), the <strong>w</strong> and <strong>h</strong> attributes to define its width and height, the <strong>r</strong> attribute to set its rotation angle (clockwise), and the <strong>t</strong> attribute to set its position in the x, y directions.</p>
          <p>The actual reference point of its position is defined by the <strong>anchor</strong> attribute. The 0 of <strong>anchor</strong> means <em>Left/Top</em> side of the quad, while 1 is to the <em>Right/Bottom</em> side. The anchor need not to be set inside the image area.</p>
          <p>As other types of element, you can use <strong>alpha</strong> attribute to apply transperency to an <strong>Image2D</strong>.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>src</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>w</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>h</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>anchor</td>
          <td>number[2]</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>You might notice that these attributes are almost the same as those of <strong>Quad2D</strong>, the only difference is that <strong>Image2D</strong> displays a image file while <strong>Quad2D</strong> displays a colored rectangle. The following code defines an image in the top-left corner of the screen:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Imag2D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"image_1"</span> <span className="hljs-attr">src</span>=<span className="hljs-string">"image/test.png"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"300"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"200"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"0, 0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0"</span> <span className="hljs-attr">anchor</span>=<span className="hljs-string">"0, 0"</span> /&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:<br />
          <strong><em>Note:</em></strong> The <strong>src</strong> attribute cannot be omitted.</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0"</span><br/>
            r=<span className="hljs-string">"0"</span><br/>
            w=<span className="hljs-string">"1"</span><br/>
            h=<span className="hljs-string">"1"</span><br/>
            anchor=<span className="hljs-string">"0.5, 0.5"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="image3d"><strong>Image3D</strong></h3>
          <p><strong>Image3D</strong> and <strong>Image2D</strong> have the similar attributes. But <strong>Image3D</strong> is a element that operates in a <em>3D space</em>. It can move along the direction perpendicular to the screen and is capable of unrestricted rotation.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>src</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>w</td>
          <td>number</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>h</td>
          <td>number</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number[3]</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>anchor</td>
          <td>number[2]</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>There are some example of <strong>Image3D</strong> definition:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Image3D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"image_2"</span> <span className="hljs-attr">src</span>=<span className="hljs-string">"res/test.png"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"300"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"200"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"0, 0, 0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"30, 30, 30"</span> /&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:<br />
          <strong><em>Note:</em></strong> The <strong>src</strong> attribute cannot be omitted.</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0, 0"</span><br />
            r=<span className="hljs-string">"0, 0, 0"</span><br/>
            w=<span className="hljs-string">"1"</span><br/>
            h=<span className="hljs-string">"1"</span><br/>
            anchor=<span className="hljs-string">"0.5, 0.5"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="text2d"><strong>Text2D</strong></h3>
          <p><strong>Text2D</strong> shares a similar concept with the previously introduced <strong>Quad2D</strong> and <strong>Image2D</strong>. They all belong to the Quad family, which represents a flat rectangle element.</p>
          <p>However, unlike the other elements, the aspect ratio of text is primarily determined by the font and its text content, rather than arbitrary settings. To accommodate this, <strong>Text2D</strong> offers a fundamental size attribute that allows you to reasonably set the font height calculated in pixels and derive its block size based on the font.</p>
          <p>The <strong>font</strong> attribute defines the font referenced by this <strong>Text2D</strong> element. All referenced fonts should be defined in font config (the path is config/font.yml) to work properly. The <strong>align</strong> attribute defines the alignment of <strong>Text2D</strong> element, where 0 is left-aligned, 0.5 is center-aligned, and 1 is right-aligned.</p>
          <p>Moreover, <strong>Text2D</strong> offers <strong>w</strong> and <strong>h</strong> attributes, that enable you to override the native aspect ratio determined by the font and size attribute with custom dimensions, although this is not frequently used in most applications.</p>
          <p>Regarding translation and rotation, the <strong>t</strong> and <strong>r</strong> attributes operate in the same way as in <strong>Quad2D</strong> and <strong>Image2D</strong>.</p>
          <p>There is a notable difference that the <strong>anchor</strong> point by default is the Top-Left corner, while <strong>Quad2D</strong> and <strong>Image2D</strong> have the default anchor at their center.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>size</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>font</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>text</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>align</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>color</td>
          <td>hex number</td>
          <td></td>
          </tr>
          <tr>
          <td>anchor</td>
          <td>number[2]</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>The following code defines a <strong>Text2D</strong> element in the top-left corner of the screen:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Text2D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"text_1"</span> <span className="hljs-attr">size</span>=<span className="hljs-string">"64"</span> <span className="hljs-attr">font</span>=<span className="hljs-string">"AzoSans-Bold"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#80ffc0ff"</span> <span className="hljs-attr">text</span>=<span className="hljs-string">"This is **Text2D**"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"0, 0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0"</span>/&gt;</span>
          </div></code></pre>
          <p>If the font and size attributes are omitted, default font and default size will be applied. And if other attributes are omitted, the default settings are:</p>
          <pre className="hljs hljs-content"><code><div>
            size=<span className="hljs-string">"7.5"</span><br/>
            font=<span className="hljs-string">"BASE_FONT"</span><br/>
            t=<span className="hljs-string">"0, 0"</span><br/>
            r=<span className="hljs-string">"0"</span><br/>
            text=<span className="hljs-string">""</span><br/>
            aligh=<span className="hljs-string">"0"</span><br/>
            anchor=<span className="hljs-string">"0, 0"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="text3d"><strong>Text3D</strong></h3>
          <p>Similarly, <strong>Text3D</strong> is the 3D version of <strong>Text2D</strong>. It possesses the 3D versions of <strong>t</strong> and <strong>r</strong> attributes to control translation and rotation.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>size</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>font</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>text</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>align</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number[3]</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>color</td>
          <td>hex number</td>
          <td></td>
          </tr>
          <tr>
          <td>anchor</td>
          <td>number[2]</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>A basic example of <strong>Text3D</strong> follows:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Text3D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"text_2"</span> <span className="hljs-attr">size</span>=<span className="hljs-string">"12"</span> <span className="hljs-attr">font</span>=<span className="hljs-string">"AzoSans-Bold"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#80ffc0ff"</span> <span className="hljs-attr">text</span>=<span className="hljs-string">"This is **Text2D**"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"60, 20, 0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"30, 20, 0"</span>/&gt;</span>
          </div></code></pre>
          <p>If the font and size attributes are omitted, default font and default size will be applied. And if other attributes are omitted, the default settings are:</p>
          <pre className="hljs hljs-content"><code><div>
            size=<span className="hljs-string">"12"</span><br/>
            font=<span className="hljs-string">"BASE_FONT"</span><br/>
            t=<span className="hljs-string">"0, 0, 0"</span><br/>
            r=<span className="hljs-string">"0, 0, 0"</span><br/>
            anchor=<span className="hljs-string">"0.5, 0.5"</span><br/>
            color=<span className="hljs-string">"#00000000"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="video"><strong>Video</strong></h3>
          <p><strong>Video</strong> is designed for displaying video in <em>2D space</em>.</p>
          <p>You can use its src attribute to set the file path of the video, the <strong>w</strong> and <strong>h</strong> attributes to define its width and height, and the <strong>t</strong> and <strong>r</strong> attribute to set its position and rotation.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>src</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>w</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>h</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number</td>
          <td>degrees</td>
          </tr>
          </tbody>
          </table>
          <p>The following example code defines a <strong>Video</strong>:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Video</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"movie_1"</span> <span className="hljs-attr">src</span>=<span className="hljs-string">"video/my_movie.mp4"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"1920"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"1080"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"960, 540"</span>/&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:<br />
          <strong><em>Note:</em></strong> The src attribute cannot be omitted.</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0"</span><br/>
            r=<span className="hljs-string">"0"</span><br/>
            w=<span className="hljs-string">"64"</span><br/>
            h=<span className="hljs-string">"64"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="model3d"><strong>Model3D</strong></h3>
          <p><strong>Model3D</strong> is designed for rendering 3D models in <em>3D space</em>, using the glTF standard format. You can use its src attribute to set the file path, the <strong>r</strong> attribute to define its rotation angle, and the <strong>t</strong> attribute to define its position, and the s attribute to set its scaling along the x, y, and z directions.</p>
          <p>You can use <strong>alpha</strong> attribute to adjust the transparency of your model. However, you should use this attribute carefully, because the 3D rendering is based on the depth testing and hence even a 3D model is transperent, it might block the display of other 3D elements behind it.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>src</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>s</td>
          <td>number[3]</td>
          <td>scale</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number[3]</td>
          <td>degrees</td>
          </tr>
          </tbody>
          </table>
          <p>The following example code defines a <strong>Model3D</strong>:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Model3D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"model_1"</span> <span className="hljs-attr">src</span>=<span className="hljs-string">"model/my_model.gltf"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"0, 0, 0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0, 60, 0"</span> <span className="hljs-attr">s</span>=<span className="hljs-string">"100, 100, 100"</span> /&gt;</span>
          </div></code></pre>
          <p>It is essential to mention that the <em>3D space</em> of Noah is based on mm (millimeters). If the model you're using is based on cm (centimeters) or m (meters) as its default unit, then the s attribute within the XML must be set accordingly with the relative unit ratio to ensure that the model can be displayed correctly.</p>
          <p>You should set proper <strong>Light3D</strong> elements to illuminate the <em>3D space</em> before a <strong>Model3D</strong> is defined.</p>
          <p>If some attributes are omitted, the default settings of <strong>Model3D</strong> are:</p>
          <pre className="hljs hljs-content"><code><div>
            t=<span className="hljs-string">"0, 0, 0"</span><br/>
            s=<span className="hljs-string">"1, 1, 1"</span><br/>
            r=<span className="hljs-string">"0, 0, 0"</span><br/>
            alpha=<span className="hljs-string">"1.0"</span><br/>
            enabled=<span className="hljs-string">"1"</span><br/>
          </div></code></pre>
          <hr />
          <h3 id="light3d"><strong>Light3D</strong></h3>
          <p><strong>Light3D</strong> is a element designed to be used in conjunction with <strong>Model3D</strong>. It allows you to illuminate models in <em>3D space</em>. While you may not directly see it displayed on the screen, the settings for the position and direction of <strong>Light3D</strong> reflects in the rendering effects of your 3D models.</p>
          <p>In practical applications, <strong>Light3D</strong> comes in 4 types: <em>AMBIENT</em>, <em>DIRECTION</em>, <em>OMNI</em> (point light source), and <em>SPOT</em> (spotlight). You can freely choose the type that best suits your desired effect.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>type</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td>milimeters</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number[3]</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>intensity</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>attenuation</td>
          <td>number[3]</td>
          <td></td>
          </tr>
          <tr>
          <td>cutoff</td>
          <td>number</td>
          <td>degrees</td>
          </tr>
          <tr>
          <td>color</td>
          <td>hex number</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>For instance, the following XML code defines an <em>OMNI</em> light:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Light3D</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"light"</span> <span className="hljs-attr">type</span>=<span className="hljs-string">"OMNI"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"50, 120, 620"</span> <span className="hljs-attr">color</span>=<span className="hljs-string">"#809020"</span> <span className="hljs-attr">intensity</span>=<span className="hljs-string">"50.0"</span> <span className="hljs-attr">attenuation</span>=<span className="hljs-string">"0.1, 0.1, 0.1, 0.1"</span> /&gt;</span>
          </div></code></pre>
          <p>There are different attributes take effect on different types of <strong>Light3D</strong>:</p>
          <ul>
          <li>The <em>AMBIENT</em> light takes only the <strong>color</strong> attribute.</li>
          <li>The <em>DIRECTION</em> light takes <strong>color</strong>, <strong>intensity</strong>, and <strong>r</strong> attributes.</li>
          <li>The <em>OMNI</em> light takes <strong>color</strong>, <strong>intensity</strong>, <strong>attenuation</strong>, and <strong>t</strong> attributes.</li>
          <li>The <em>SPOT</em> light takes <strong>color</strong>, <strong>intensity</strong>, <strong>attenuation</strong>, <strong>cutoff</strong>, <strong>t</strong>, and <strong>r</strong> attributes.</li>
          </ul>
          <p>The concept of <strong>Light3D</strong> is the same as lights in real world. A <em>SPOT</em> light or an <em>OMNI</em> light needs its position to be determined, and hence the <strong>t</strong> attribute matters. A &quot;<em>DIRECTION</em>&quot; light or a <em>SPOT</em> light needs its direction to be determined, and hence the <strong>r</strong> attribute matters. If some attributes are set but not used by the particular type of <strong>Light3D</strong>, the attributes are simply ignored and make no harm.</p>
          <hr />
          <h3 id="button"><strong>Button</strong></h3>
          <p><strong>Button</strong> is designed for displaying buttons in <em>2D space</em>.</p>
          <p>You can use its <strong>appearance</strong> attribute to set the file path of yml, which is used to design the appearance of different state of button. The <strong>w</strong> and <strong>h</strong> attributes to define its width and height, the <strong>r</strong> attribute to set its rotation angle (clockwise), the <strong>t</strong> attribute to set its position in the x, y directions, and <strong>alpha</strong> attribute to apply transperency to a <strong>Button</strong> element.</p>
          <p>The actual reference point of its position is defined by the <strong>anchor</strong> attribute. The 0 of <strong>anchor</strong> means <em>Left/Top</em> side of button, while 1 is to <em>Right/Bottom</em> side. The anchor need not to be set inside the image area.</p>
          <p>For buttons, you can enable interaction with Noah by assigning a Lua function to the <strong>callback</strong> attribute.</p>
          <p>Additionally, you can change the button to toggle mode by setting the <strong>mode</strong> attribute to <em>TOGGLE</em> and use the <strong>toggleon</strong> attribute to determine the button's state, where 0 represents toggle off and 1 represents toggle on.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>w</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>h</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>anchor</td>
          <td>number[2]</td>
          <td></td>
          </tr>
          <tr>
          <td>appearance</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>callback</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>mode</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>toggleon</td>
          <td>boolean</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>There are some example of button definition:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Button</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"button_1"</span> <span className="hljs-attr">appearance</span>=<span className="hljs-string">"image/btn.yml"</span> <span className="hljs-attr">w</span>=<span className="hljs-string">"300"</span> <span className="hljs-attr">h</span>=<span className="hljs-string">"200"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"0, 0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0"</span> <span className="hljs-attr">callback</span>=<span className="hljs-string">"click_btn"</span> /&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:<br />
          <strong><em>Note:</em></strong> The <strong>appearance</strong> attribute cannot be omitted.</p>
          <pre className="hljs hljs-content"><code><div>
            enabled=<span className="hljs-string">"1"</span><br />
            w=<span className="hljs-string">"1"</span><br/>
            h=<span className="hljs-string">"1"</span><br/>
            t=<span className="hljs-string">"0, 0"</span><br/>
            r=<span className="hljs-string">"0"</span><br/>
            alpha=<span className="hljs-string">"1"</span><br/>
            anchor=<span className="hljs-string">"0.5, 0.5"</span><br/>
            callback=<span className="hljs-string">""</span><br/>
            mode=<span className="hljs-string">""</span><br/>
          </div></code></pre>
          <h3 id="buttom-yml">Buttom YML</h3>
          <p>The button’s appearance is defined in a YAML file, which includes two primary attributes:</p>
          <ul>
          <li><strong>Texture</strong>: Specifies the default look of the button, requiring the file path of an image (either PNG or JPG).</li>
          <li><strong>State</strong>: Defines the button’s appearance in various states, which supports three states:
          <ul>
          <li><strong>Normal</strong>: Default(and toggle on) appearance.</li>
          <li><strong>Press</strong>: Apperarance when pressed(and toggle of).</li>
          <li><strong>Disable</strong>: <strong><em>Note: Unknown State</em></strong></li>
          </ul>
          </li>
          </ul>
          <p>For each state, the following attributes must be configured:</p>
          <ul>
          <li>duration: The animation time, in seconds, for transitions between states.</li>
          <li>texture: Specifies the appearance of the button in the given state, requiring the path to an image file (PNG or JPG).</li>
          <li>color: Blending color defined by four numbers representing RGBA (Red, Green, Blue, Alpha) values.</li>
          <li>s: Scale factor, which determines the button’s scaling.</li>
          <li>t: Transform, defining the button’s translation on the screen.</li>
          </ul>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>duration</td>
          <td>number</td>
          <td>seconds</td>
          </tr>
          <tr>
          <td>texture</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>color</td>
          <td>number[3]</td>
          <td></td>
          </tr>
          <tr>
          <td>s</td>
          <td>number[3]</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[3]</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>For instance, the following YML code defines a button:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-attr">texture:</span> <span className="hljs-string">"image/example.png"</span><br />
          <span className="hljs-attr">state:</span><br />
              {'  '}<span className="hljs-attr">normal:</span><br />
                {'  '}{'  '}<span className="hljs-attr">duration:</span> <span className="hljs-number">0.1</span><br />
                {'  '}{'  '}<span className="hljs-attr">texture:</span> <span className="hljs-string">"image/example.png"</span><br />
                {'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[1.0,</span> <span className="hljs-number">1.0</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}<span className="hljs-attr">s:</span> <span className="hljs-string">[1.0,</span> <span className="hljs-number">1.0</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}<span className="hljs-attr">t:</span> <span className="hljs-string">[0.0,</span> <span className="hljs-number">0.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
              {'  '}<span className="hljs-attr">press:</span><br />
                {'  '}{'  '}<span className="hljs-attr">duration:</span> <span className="hljs-number">0.1</span><br />
                {'  '}{'  '}<span className="hljs-attr">texture:</span> <span className="hljs-string">"image/example.png"</span><br />
                {'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.5,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">0.9</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}<span className="hljs-attr">s:</span> <span className="hljs-string">[1.2,</span> <span className="hljs-number">1.2</span><span className="hljs-string">,</span> <span className="hljs-number">1.2</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}<span className="hljs-attr">t:</span> <span className="hljs-string">[0.0,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
              {'  '}<span className="hljs-attr">disable:</span><br />
                {'  '}<span className="hljs-attr">duration:</span> <span className="hljs-number">0.1</span><br />
                {'  '}<span className="hljs-attr">texture:</span> <span className="hljs-string">"image/example.png"</span><br />
                {'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.5,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}<span className="hljs-attr">s:</span> <span className="hljs-string">[1.0,</span> <span className="hljs-number">1.0</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}<span className="hljs-attr">t:</span> <span className="hljs-string">[0.0,</span> <span className="hljs-number">0.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
          </div></code></pre>
          <hr />
          <h3 id="slider"><strong>Slider</strong></h3>
          <p><strong>Slider</strong> is designed for displaying sliders in <em>2D space</em>.</p>
          <p>You can use its <strong>appearance</strong> attribute to set the file path of yml, which is used to design the appearance of different part of slider. The <strong>r</strong> attribute to set its rotation angle (clockwise), the <strong>t</strong> attribute to set its position in the x, y directions, and <strong>alpha</strong> attribute to apply transperency to a <strong>Slider</strong> element.</p>
          <p>Unlike buttons, the width and height of a slider are not controlled by the w and h attributes but are instead configured through a YAML file specified in the appearance attribute. And the <strong>anchor</strong> attribute is always &quot;0.5, 0.5&quot;.</p>
          <p>For sliders, you can enable interaction with Noah by assigning a Lua function to the <strong>callback</strong> attribute.</p>
          <table class="styled-table">
          <thead>
          <tr>
          <th>Attributes</th>
          <th>Type</th>
          <th>Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>id</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>enabled</td>
          <td>boolean</td>
          <td></td>
          </tr>
          <tr>
          <td>alpha</td>
          <td>number</td>
          <td></td>
          </tr>
          <tr>
          <td>t</td>
          <td>number[2]</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>r</td>
          <td>number</td>
          <td>pixels</td>
          </tr>
          <tr>
          <td>appearance</td>
          <td>string</td>
          <td></td>
          </tr>
          <tr>
          <td>callback</td>
          <td>string</td>
          <td></td>
          </tr>
          </tbody>
          </table>
          <p>There are some example of slider definition:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-tag">&lt;<span className="hljs-name">Slider</span> <span className="hljs-attr">id</span>=<span className="hljs-string">"slider_2"</span> <span className="hljs-attr">appearance</span>=<span className="hljs-string">"image/slider.yml"</span> <span className="hljs-attr">t</span>=<span className="hljs-string">"0, 0"</span> <span className="hljs-attr">r</span>=<span className="hljs-string">"0"</span> <span className="hljs-attr">callback</span>=<span className="hljs-string">"slide"</span> /&gt;</span>
          </div></code></pre>
          <p>If some attributes are omitted, the default settings are:<br />
          <strong><em>Note:</em></strong> The <strong>appearance</strong> attribute cannot be omitted.</p>
          <pre className="hljs hljs-content"><code><div>
            enabled=<span className="hljs-string">"1"</span><br />
            t=<span className="hljs-string">"0, 0"</span><br/>
            r=<span className="hljs-string">"0"</span><br/>
            alpha=<span className="hljs-string">"1"</span><br/>
            anchor=<span className="hljs-string">"0.5, 0.5"</span><br/>
            callback=<span className="hljs-string">""</span><br/>
          </div></code></pre>
          <h3 id="slider-yml">Slider YML</h3>
          <p>The slider's appearance is defined in a YAML file with the following main attributes:</p>
          <ul>
          <li><strong>start_pos</strong>: Defines the starting position of the slider.</li>
          <li><strong>end_pos</strong>: Defines the ending position of the slider.<br />
          <strong><em>Note</em></strong>: The slider's position 0 is its center point, so the sum of <strong>start_pos</strong> and <strong>end_pos</strong> should ideally be 0. Additionally, the value of <strong>end_pos</strong> - <strong>start_pos</strong> should match the <strong>size</strong> attribute in the Fill, Foreground, and Background Configuration to ensure proper display of the slider.</li>
          <li><strong>behavior</strong>: Specifies the slider's behavior (currently only supports the NORMAL behavior).</li>
          <li><strong>nobe</strong>: Defines the appearance of the slider's button.</li>
          <li><strong>fill</strong>: Defines the appearance when the slider is filled.</li>
          <li><strong>foreground</strong>: Specifies the foreground appearance of the slider.</li>
          <li><strong>background</strong>: Specifies the background appearance of the slider.</li>
          </ul>
          <p><strong>Nobe Configuration</strong></p>
          <p>The nobe, or button, has the following configurable properties:</p>
          <ul>
          <li><strong>offset</strong>: The offset position of the nobe.</li>
          <li><strong>texture</strong>: Defines the appearance of the nobe, requiring the path to an image file (PNG or JPG).</li>
          <li><strong>state</strong>: Determines the nobe's state with two possible values:
          <ul>
          <li><strong>Normal</strong>: Default appearance.</li>
          <li><strong>Disable</strong>: Disabled appearance.</li>
          <li>Each state allows customization of the color, with color adjustments possible based on the current slider value (ranging from 0 to 1).</li>
          </ul>
          </li>
          </ul>
          <p><strong>Fill, Foreground, and Background Configuration</strong></p>
          <p>Each of the fill, foreground, and background sections includes the following properties:</p>
          <ul>
          <li><strong>offset</strong>: Offset position within the slider.</li>
          <li><strong>texture</strong>: Specifies the visual texture, requiring an image path (PNG or JPG).</li>
          <li><strong>slice</strong>: (Uncertain attribute; details unknown)</li>
          <li><strong>size</strong>: Defines the width and height of slider.</li>
          <li><strong>state</strong>: Defines the visual state with two possible values:
          <ul>
          <li><strong>Normal</strong>: Default appearance.</li>
          <li><strong>Disable</strong>: Disabled appearance.</li>
          <li>Colors for each state can also vary depending on the slider's current value, allowing for dynamic visual feedback based on the slider's position (value from 0 to 1).</li>
          </ul>
          </li>
          </ul>
          <p>For instance, the following YML code defines a slider:</p>
          <pre className="hljs hljs-content"><code><div><span className="hljs-string">%YAML</span> <span className="hljs-number">1.2</span><br />
          
          <span className="hljs-attr">start_pos:</span> <span className="hljs-number">-240.0</span><br />
          <span className="hljs-attr">end_pos:</span> <span className="hljs-number">240.0</span><br />
          <span className="hljs-attr">behavior:</span><br />
            {'  '}<span className="hljs-attr">type:</span> <span className="hljs-string">"NORMAL"</span><br />
          <span className="hljs-attr">nobe:</span><br /> 
            {'  '}<span className="hljs-attr">offset:</span> <span className="hljs-string">[0.0,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
            {'  '}<span className="hljs-attr">texture:</span> <span className="hljs-string">"image/slider_nobe.png"</span> <br />
            {'  '}<span className="hljs-attr">state:</span><br />
              {'  '}{'  '}<span className="hljs-attr">noraml:</span><br />
                {'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.5</span><br />
                  {'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">1.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
              {'  '}{'  '}{'  '}<span className="hljs-attr">disable:</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.5,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
          {'  '}<span className="hljs-attr">fill:</span><br />
            {'  '}{'  '}<span className="hljs-attr">offset:</span> <span className="hljs-string">[0.0,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">texture:</span> <span className="hljs-string">"image/slider_progress.png"</span><br />
            {'  '}{'  '}<span className="hljs-attr">slice:</span> <span className="hljs-string">[10.0,</span> <span className="hljs-number">10.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">size:</span> <span className="hljs-string">[480.0,</span> <span className="hljs-number">15.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">state:</span><br />
              {'  '}{'  '}{'  '}<span className="hljs-attr">noraml:</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.5</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
               {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">1.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
              {'  '}{'  '}{'  '}<span className="hljs-attr">disable:</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.5,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
          {'  '}<span className="hljs-attr">foreground:</span><br />
            {'  '}{'  '}<span className="hljs-attr">offset:</span> <span className="hljs-string">[0.0,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">texture:</span> <span className="hljs-string">"image/slider_fg.png"</span><br />
            {'  '}{'  '}<span className="hljs-attr">slice:</span> <span className="hljs-string">[12.0,</span> <span className="hljs-number">12.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">size:</span> <span className="hljs-string">[486.0,</span> <span className="hljs-number">15.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">state:</span><br />
              {'  '}{'  '}{'  '}<span className="hljs-attr">noraml:</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.5</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">1.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
              {'  '}{'  '}{'  '}<span className="hljs-attr">disable:</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.5,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
          {'  '}<span className="hljs-attr">background:</span><br />
            {'  '}{'  '}<span className="hljs-attr">offset:</span> <span className="hljs-string">[0.0,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">texture:</span> <span className="hljs-string">"image/slider_bg.png"</span><br />
            {'  '}{'  '}<span className="hljs-attr">slice:</span> <span className="hljs-string">[12.0,</span> <span className="hljs-number">12.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">,</span> <span className="hljs-number">0.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">size:</span> <span className="hljs-string">[486.0,</span> <span className="hljs-number">15.0</span><span className="hljs-string">]</span><br />
            {'  '}{'  '}<span className="hljs-attr">state:</span><br />
              {'  '}{'  '}{'  '}<span className="hljs-attr">noraml:</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">0.5</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">0.6</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-bullet">-</span> <span className="hljs-attr">value:</span> <span className="hljs-number">1.0</span><br />
                  {'  '}{'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.2,</span> <span className="hljs-number">0.8</span><span className="hljs-string">,</span> <span className="hljs-number">0.4</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
              {'  '}{'  '}{'  '}<span className="hljs-attr">disable:</span><br />
                {'  '}{'  '}{'  '}{'  '}<span className="hljs-attr">color:</span> <span className="hljs-string">[0.5,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">0.5</span><span className="hljs-string">,</span> <span className="hljs-number">1.0</span><span className="hljs-string">]</span><br />
          </div></code></pre>
        </div>
      </div>
    </div>
  );
}

export default NoahXMLReferencePage;
