import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
import hello from './screenshot/hello.png';
/*eslint-disable */

function NoahFirstBuildPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="hello-noah">Hello, Noah!</h1>
          <p>You can assign a <strong>noah project</strong> to <em>noah-theatre</em> to run with:</p>
          <pre class="hljs"><code><div>$ ./noah_theatre-x64-linux project/hello_noah
          </div></code></pre>
          <p>If everything goes well, a window shows on your screen like this:</p>
          <p><img src={hello} alt="screenshot" width={480}/></p>
          <p>Great! You've successfully run a <strong>noah project</strong>!</p>
          <p>In this case, <code>project/hello_noah</code> is the path to your <strong>noah project</strong>. Since the &quot;hello_noah&quot; project is designed to render only a text line, <em>Noah</em> renders it.</p>
          <p>You can close the window by <strong>CTRL+ESC</strong>.</p>
          <blockquote>
          <h4 id="about-log">About Log</h4>
          <p>The log of <em>Noah</em> is kept in the <code>log/</code> folder. If any errors occur please copy the log files out and send them back to MindtronicAI.</p>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default NoahFirstBuildPage;
