import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPICommunicationPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Communication APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Establish Connection</h3>
              <li><a href="#noahcommconnect">NoahCommConnect</a></li>
              <li><a href="#noahcommdisconnect">NoahCommDisconnect</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Publish Data</h3>
              <li><a href="#noahcommgetchannelname">NoahCommGetChannelName</a></li>
              <li><a href="#noahcommpublishnumber">NoahCommPublishNumber</a></li>
              <li><a href="#noahcommpublishboolean">NoahCommPublishBoolean</a></li>
              <li><a href="#noahcommpublishstring">NoahCommPublishString</a></li>
              <li><a href="#noahcommunpublish">NoahCommUnPublish</a></li>
              <li><a href="#noahcommsetnumber">NoahCommSetNumber</a></li>
              <li><a href="#noahcommsetboolean">NoahCommSetBoolean</a></li>
              <li><a href="#noahcommsetstring">NoahCommSetString</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Subscribe Data</h3>
              <li><a href="#noahcommsubscribe">NoahCommSubscribe</a></li>
              <li><a href="#noahcommunsubscribe">NoahCommUnSubscribe</a></li>
              <li><a href="#noahcommgetnumber">NoahCommGetNumber</a></li>
              <li><a href="#noahcommgetboolean">NoahCommGetBoolean</a></li>
              <li><a href="#noahcommgetstring">NoahCommGetString</a></li>
              <li><a href="#noahsetsubscribedimage">NoahSetSubscribedImage</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Send/Read Message</h3>
              <li><a href="#noahcommsendstr">NoahCommSendStr</a></li>
              <li><a href="#noahcommgetsenderlist">NoahCommGetSenderList</a></li>
              <li><a href="#noahcommismessageavailable">NoahCommIsMessageAvailable</a></li>
              <li><a href="#noahcommreadstr">NoahCommReadStr</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="noah-communication-functions">
            Noah Communication Functions
          </h2>
          <p>
            The <strong>Noah Communication Functions</strong> facilitate
            communication and data exchange between processes.
          </p>
          <hr />
          <h3 id="noahcommconnect">NoahCommConnect</h3>
          <h4 id="definition">Definition</h4>
          <p>Start a client thread to connect to a specific host and port.</p>
          <h4 id="description">Description</h4>
          <p>
            <code>
              NoahCommConnect(string channel_id, string host_ip, number
              host_port)
            </code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>channel_id</code> (string):
              </strong>{' '}
              Channel name of current client.
            </li>
            <li>
              <strong>
                <code>host_ip</code> (string):
              </strong>{' '}
              The Ip address of the server to connect to
            </li>
            <li>
              <strong>
                <code>host_port</code> (number):
              </strong>{' '}
              The port number on the server to connect to
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if the connection is successful,{' '}
            <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Connect to the host at 127.0.0.1, port 3000 and register with
            &quot;example_channel&quot;
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommConnect(
                <span className="hljs-string">&quot;example_channel&quot;</span>
                ,<span className="hljs-string">&quot;127.0.0.1&quot;</span>,
                <span className="hljs-number">3000</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommdisconnect">NoahCommDisconnect</a> - Disconnect
            from server <br />
            <a href="#noahcommgetchannelname">NoahCommGetChannelName</a> - Get
            communication&apos;s channel name <br />
          </p>
          <hr />
          <h3 id="noahcommdisconnect">NoahCommDisconnect</h3>
          <h4 id="definition">Definition</h4>
          <p>Disconnect from the host and close the client thread.</p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommDisconnect()</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <p>This function has no parameter.</p>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>Disconnect from the host.</p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahCommDisconnect()</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommconnect">NoahCommConnect</a> - Connect to server
            <br />
            <a href="#noahcommgetchannelname">NoahCommGetChannelName</a> - Get
            communication&apos;s channel name <br />
          </p>
          <hr />
          <h3 id="noahcommgetchannelname">NoahCommGetChannelName</h3>
          <h4 id="definition">Definition</h4>
          <p>Get registered channel name.</p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommGetChannelName()</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <p>This function has no parameter.</p>
          <h4 id="return-value">Return Value</h4>
          <p>The channel name of registered channel.</p>
          <h4 id="example">Example</h4>
          <p>Get registered channel name.</p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahCommGetChannelName()</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommconnect">NoahCommConnect</a> - Connect to server
            <br />
            <a href="#noahcommdisconnect">NoahCommDisconnect</a> - Disconnect
            from server <br />
          </p>
          <hr />
          <h3 id="noahcommpublishnumber">NoahCommPublishNumber</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Publish a service identified by the given <em>data_id</em> to set
            values in <em>number</em> data type.
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommPublishNumber(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if the <code>data_id</code> is not duplicated,
            <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Publish a service named &quot;number_publisher&quot; to set value in
            <em>number</em> data type.
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommPublishNumber(
                <span className="hljs-string">
                  &quot;number_publisher&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommpublishboolean">NoahCommPublishBoolean</a> -
            Publish a service in <em>boolean</em> data type <br />
            <a href="#noahcommpublishstring">NoahCommPublishString</a> - Publish
            a service in <em>String</em> data type <br />
            <a href="#noahcommunpublish">NoahCommUnPublish</a> - Unpublish a
            service <br />
            <a href="#noahcommsetnumber">NoahCommSetNumber</a> - Set value in{' '}
            <em>number</em> data type to a published service <br />
            <a href="#noahcommsetboolean">NoahCommSetBoolean</a> - Set value in{' '}
            <em>boolean</em> data type to a published service <br />
            <a href="#noahcommsetstring">NoahCommSetString</a> - Set value in{' '}
            <em>String</em> data type to a published service <br />
          </p>
          <hr />
          <h3 id="noahcommpublishboolean">NoahCommPublishBoolean</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Publish a service identified by the given <em>data_id</em> to set
            values in <em>boolean</em> data type.
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommPublishBoolean(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code>
            if the <code>data_id</code> is not duplicated, <code>False</code>
            otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Publish a service named &quot;boolean_publisher&quot; to set value
            in <em>boolean</em> data type.
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommPublishBoolean(
                <span className="hljs-string">
                  &quot;boolean_publisher&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommpublishnumber">NoahCommPublishNumber</a> - Publish
            a service in <em>number</em> data type <br />
            <a href="#noahcommpublishstring">NoahCommPublishString</a> - Publish
            a service in <em>String</em> data type <br />
            <a href="#noahcommunpublish">NoahCommUnPublish</a> - Unpublish a
            service <br />
            <a href="#noahcommsetnumber">NoahCommSetNumber</a> - Set value in{' '}
            <em>number</em> data type to a published service <br />
            <a href="#noahcommsetboolean">NoahCommSetBoolean</a> - Set value in{' '}
            <em>boolean</em> data type to a published service <br />
            <a href="#noahcommsetstring">NoahCommSetString</a> - Set value in{' '}
            <em>String</em> data type to a published service <br />
          </p>
          <hr />
          <h3 id="noahcommpublishstring">NoahCommPublishString</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Publish a service identified by the given <em>data_id</em> to set
            values in <em>String</em> data type.
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommPublishString(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if the <code>data_id</code> is not duplicated,
            <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Publish a service named &quot;string_publisher&quot; to set value in
            <em>number</em> data type.
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommPublishString(
                <span className="hljs-string">
                  &quot;string_publisher&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommpublishnumber">NoahCommPublishNumber</a> - Publish
            a service in <em>number</em> data type <br />
            <a href="#noahcommpublishboolean">NoahCommPublishBoolean</a> -
            Publish a service in <em>boolean</em> data type <br />
            <a href="#noahcommunpublish">NoahCommUnPublish</a> - Unpublish a
            service <br />
            <a href="#noahcommsetnumber">NoahCommSetNumber</a> - Set value in{' '}
            <em>number</em> data type to a published service <br />
            <a href="#noahcommsetboolean">NoahCommSetBoolean</a> - Set value in{' '}
            <em>boolean</em> data type to a published service <br />
            <a href="#noahcommsetstring">NoahCommSetString</a> - Set value in{' '}
            <em>String</em> data type to a published service <br />
          </p>
          <hr />
          <h3 id="noahcommunpublish">NoahCommUnPublish</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Unpublish service indentified by the given <em>data_id</em>
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommUnPublish(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if the <em>data_id</em> is a published service,
            <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>Unpublish service named &quot;published_service&quot;</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommUnPublish(
                <span className="hljs-string">
                  &quot;published_service&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommpublishnumber">NoahCommPublishNumber</a> - Publish
            a service in <em>number</em> data type <br />
            <a href="#noahcommpublishboolean">NoahCommPublishBoolean</a> -
            Publish a service in <em>boolean</em> data type <br />
            <a href="#noahcommpublishstring">NoahCommPublishString</a> - Publish
            a service in <em>String</em> data type <br />
            <a href="#noahcommsetnumber">NoahCommSetNumber</a> - Set value in{' '}
            <em>number</em> data type to a published service <br />
            <a href="#noahcommsetboolean">NoahCommSetBoolean</a> - Set value in{' '}
            <em>boolean</em> data type to a published service <br />
            <a href="#noahcommsetstring">NoahCommSetString</a> - Set value in{' '}
            <em>String</em> data type to a published service <br />
          </p>
          <hr />
          <h3 id="noahcommsetnumber">NoahCommSetNumber</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Set value in <em>number</em> data type for the specified
            <code>data_id</code>
            service
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommSetNumber(string data_id, number data)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
            <li>
              <strong>
                <code>data</code> (number):
              </strong>
              The value to be set for the service
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if set number successfully, <code>False</code>
            otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Set <code>10</code> to the service named
            <code>number_publisher</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommSetNumber(
                <span className="hljs-string">
                  &quot;number_publisher&quot;
                </span>
                ,<span className="hljs-number">10</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommpublishnumber">NoahCommPublishNumber</a> - Publish
            a service in <em>number</em> data type <br />
            <a href="#noahcommpublishboolean">NoahCommPublishBoolean</a> -
            Publish a service in <em>boolean</em> data type <br />
            <a href="#noahcommpublishstring">NoahCommPublishString</a> - Publish
            a service in <em>String</em> data type <br />
            <a href="#noahcommunpublish">NoahCommUnPublish</a> - Unpublish a
            service <br />
            <a href="#noahcommsetboolean">NoahCommSetBoolean</a> - Set value in{' '}
            <em>boolean</em> data type to a published service <br />
            <a href="#noahcommsetstring">NoahCommSetString</a> - Set value in{' '}
            <em>String</em> data type to a published service <br />
          </p>
          <hr />
          <h3 id="noahcommsetboolean">NoahCommSetBoolean</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Set value in <em>boolean</em> data type for the specified
            <code>data_id</code>
            service
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommSetBoolean(string data_id, boolean data)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
            <li>
              <strong>
                <code>data</code> (boolean):
              </strong>
              The value to be set for the service
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if set boolean successfully, <code>False</code>
            otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Set <code>1</code> to the service named
            <code>boolean_publisher</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommSetBoolean(
                <span className="hljs-string">
                  &quot;boolean_publisher&quot;
                </span>
                , <span className="hljs-string">&quot;1&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommpublishnumber">NoahCommPublishNumber</a> - Publish
            a service in <em>number</em> data type <br />
            <a href="#noahcommpublishboolean">NoahCommPublishBoolean</a> -
            Publish a service in <em>boolean</em> data type <br />
            <a href="#noahcommpublishstring">NoahCommPublishString</a> - Publish
            a service in <em>String</em> data type <br />
            <a href="#noahcommunpublish">NoahCommUnPublish</a> - Unpublish a
            service <br />
            <a href="#noahcommsetnumber">NoahCommSetNumber</a> - Set value in
            {` `} <em> number </em> data type to a published service <br />
            <a href="#noahcommsetstring">NoahCommSetString</a> - Set value in
            {` `} <em>String</em> data type to a published service <br />
          </p>
          <hr />
          <h3 id="noahcommsetstring">NoahCommSetString</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Set value in <em>string</em> data type for the specified
            <code>data_id</code> service
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommSetString(string data_id, string data)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
            <li>
              <strong>
                <code>data</code> (string):
              </strong>
              The value to be set for the service
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if set string successfully, <code>False</code>
            otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Set <code>example</code> to the service named
            <code>string_publisher</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommSetString(
                <span className="hljs-string">
                  &quot;string_publisher&quot;
                </span>
                , <span className="hljs-string">&quot;example&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommpublishnumber">NoahCommPublishNumber</a> - Publish
            a service in <em>number</em> data type <br />
            <a href="#noahcommpublishboolean">NoahCommPublishBoolean</a> -
            Publish a service in <em>boolean</em> data type <br />
            <a href="#noahcommpublishstring">NoahCommPublishString</a> - Publish
            a service in <em>String</em> data type <br />
            <a href="#noahcommunpublish">NoahCommUnPublish</a> - Unpublish a
            service <br />
            <a href="#noahcommsetnumber">NoahCommSetNumber</a> - Set value in{' '}
            <em>number</em> data type to a published service <br />
            <a href="#noahcommsetboolean">NoahCommSetBoolean</a> - Set value in{' '}
            <em>boolean</em> data type to a published service <br />
          </p>
          <hr />
          <h3 id="noahcommsubscribe">NoahCommSubscribe</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Subscribe a service identify by the given <em>data_id</em> to
            receive values
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommSubscribe(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if the <code>data_id</code> is not duplicated,
            <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Subscribe a service named <code>example_subscriber</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommSubscribe(
                <span className="hljs-string">
                  &quot;example_subscriber&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommunsubscribe">NoahCommUnSubscribe</a> - Unsubscribe
            a service <br />
            <a href="#noahcommgetnumber">NoahCommGetNumber</a> - Get value in{' '}
            <em>number</em> data type from a subscribed service <br />
            <a href="#noahcommgetboolean">NoahCommGetBoolean</a> - Get value in{' '}
            <em>boolean</em> data type from a subscribed service <br />
            <a href="#noahcommgetstring">NoahCommGetString</a> - Get value in{' '}
            <em>String</em> data type from a subscribed service <br />
            <a href="#noahsetsubscribedimage">NoahSetSubscribedImage</a> - Get
            image from a subscribed service and set src to Image2D or Image3D
            element <br />
          </p>
          <hr />
          <h3 id="noahcommunsubscribe">NoahCommUnSubscribe</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Unsubscribe the service identified by the given <em>data_id</em>
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommUnSubscribe(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if the <em>data_id</em> is a subscribed service,
            <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Unsubscribe the service named <code>example_subscriber</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommUnSubscribe(
                <span className="hljs-string">
                  &quot;example_subscriber&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsubscribe">NoahCommSubscribe</a> - Subscribe a
            service <br />
            <a href="#noahcommunsubscribe">NoahCommUnSubscribe</a> - Unsubscribe
            a service <br />
            <a href="#noahcommgetnumber">NoahCommGetNumber</a> - Get value in{' '}
            <em>number</em> data type from a subscribed service <br />
            <a href="#noahcommgetboolean">NoahCommGetBoolean</a> - Get value in{' '}
            <em>boolean</em> data type from a subscribed service <br />
            <a href="#noahcommgetstring">NoahCommGetString</a> - Get value in{' '}
            <em>String</em> data type from a subscribed service <br />
            <a href="#noahsetsubscribedimage">NoahSetSubscribedImage</a> - Get
            image from a subscribed service and set src to Image2D or Image3D
            element <br />
          </p>
          <hr />
          <h3 id="noahcommgetnumber">NoahCommGetNumber</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Get value in <em>number</em> data type for the specified
            <code>data_id</code> service
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommGetNumber(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            The value in <em>number</em> data type from the subscribed service,
            0 otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Get number from the service named <code>example_subscriber</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                number_value = NoahCommGetNumber(
                <span className="hljs-string">
                  &quot;example_subscriber&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsubscribe">NoahCommSubscribe</a> - Subscribe a
            service <br />
            <a href="#noahcommunsubscribe">NoahCommUnSubscribe</a> - Unsubscribe
            a service <br />
            <a href="#noahcommgetboolean">NoahCommGetBoolean</a> - Get value in{' '}
            <em>boolean</em> data type from a subscribed service <br />
            <a href="#noahcommgetstring">NoahCommGetString</a> - Get value in{' '}
            <em>String</em> data type from a subscribed service <br />
            <a href="#noahsetsubscribedimage">NoahSetSubscribedImage</a> - Get
            image from a subscribed service and set src to Image2D or Image3D
            element <br />
          </p>
          <hr />
          <h3 id="noahcommgetboolean">NoahCommGetBoolean</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Get value in <em>boolean</em> data type for the specified
            <code>data_id</code>
            service
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommGetBoolean(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            The value in <em>boolean</em> data type from the subscribed service,
            <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Get boolean from the service named <code>example_subscriber</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                boolean_value = NoahCommGetBoolean(
                <span className="hljs-string">
                  &quot;example_subscriber&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsubscribe">NoahCommSubscribe</a> - Subscribe a
            service <br />
            <a href="#noahcommunsubscribe">NoahCommUnSubscribe</a> - Unsubscribe
            a service <br />
            <a href="#noahcommgetnumber">NoahCommGetNumber</a> - Get value in{' '}
            <em>number</em> data type from a subscribed service <br />
            <a href="#noahcommgetstring">NoahCommGetString</a> - Get value in{' '}
            <em>String</em> data type from a subscribed service <br />
            <a href="#noahsetsubscribedimage">NoahSetSubscribedImage</a> - Get
            image from a subscribed service and set src to Image2D or Image3D
            element <br />
          </p>
          <hr />
          <h3 id="noahcommgetstring">NoahCommGetString</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Get value in <em>string</em> data type for the specified
            <code>data_id</code>
            service
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommGetString(string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            The string in <em>string</em> data type from the subscribed service,
            empty string otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Get string from the service named <code>example_subscriber</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                string_value = NoahCommGetString(
                <span className="hljs-string">
                  &quot;example_subscriber&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsubscribe">NoahCommSubscribe</a> - Subscribe a
            service <br />
            <a href="#noahcommunsubscribe">NoahCommUnSubscribe</a> - Unsubscribe
            a service <br />
            <a href="#noahcommgetnumber">NoahCommGetNumber</a> - Get value in{' '}
            <em>number</em> data type from a subscribed service <br />
            <a href="#noahcommgetboolean">NoahCommGetBoolean</a> - Get value in{' '}
            <em>boolean</em> data type from a subscribed service <br />
            <a href="#noahsetsubscribedimage">NoahSetSubscribedImage</a> - Get
            image from a subscribed service and set src to Image2D or Image3D
            element <br />
          </p>
          <hr />
          <h3 id="noahsetsubscribedimage">NoahSetSubscribedImage</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Get the image for the specified <code>data_id</code> service and set
            the <em>Image2D</em> or <em>Image3D</em> texture for the specified
            <code>image_id</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahSetSubscribedImage(string image_id, string data_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>image_id</code> (string):
              </strong>
              The unique identifier for the <em>Image2D</em> or <em>Image3D</em>
              element.
            </li>
            <li>
              <strong>
                <code>data_id</code> (string):
              </strong>
              The unique identifier for the service.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if the image is successfully retrieved and the
            texture is successfully set, <code>False</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Get image from the service named <code>example_subscriber</code> and
            set texture to <code>external_image</code> element.
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetSubscribedImage(
                <span className="hljs-string">&quot;external_image&quot;</span>,
                <span className="hljs-string">
                  &quot;example_subscriber&quot;
                </span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsubscribe">NoahCommSubscribe</a> - Subscribe a
            service <br />
            <a href="#noahcommunsubscribe">NoahCommUnSubscribe</a> - Unsubscribe
            a service <br />
            <a href="#noahcommgetnumber">NoahCommGetNumber</a> - Get value in{' '}
            <em>number</em> data type from a subscribed service <br />
            <a href="#noahcommgetboolean">NoahCommGetBoolean</a> - Get value in{' '}
            <em>boolean</em> data type from a subscribed service <br />
            <a href="#noahcommgetstring">NoahCommGetString</a> - Get value in{' '}
            <em>String</em> data type from a subscribed service <br />
          </p>
          <hr />
          <h3 id="noahcommsendstr">NoahCommSendStr</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Send message to the channel identified by the given
            <em>channel_id</em>
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommSendStr(string channel_id, string message)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>channel_id</code> (string):
              </strong>
              The unique identifier for the channel.
            </li>
            <li>
              <strong>
                <code>message</code> (string):
              </strong>
              The message string
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            <code>True</code> if sends message successfully, <code>False</code>
            otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Send <code>example_message</code> to the channel named
            <code>example_channel</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahCommSendStr(
                <span className="hljs-string">&quot;example_channel&quot;</span>
                ,
                <span className="hljs-string">&quot;example_message&quot;</span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommgetsenderlist">NoahCommGetSenderList</a> - Get
            list of channels which send messages
            <a href="#noahcommismessageavailable">NoahCommIsMessageAvailable</a>
            - Check is the message available from the speficied channel <br />
            <a href="#noahcommreadstr">NoahCommReadStr</a> - Read string from
            another channel <br />
          </p>
          <hr />
          <h3 id="noahcommgetsenderlist">NoahCommGetSenderList</h3>
          <h4 id="definition">Definition</h4>
          <p>Get list of channels which send messages to the current channel</p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommGetSenderList()</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <p>This function has no parameter</p>
          <h4 id="return-value">Return Value</h4>
          <p>
            Sender list in <em>vector</em> data type
          </p>
          <h4 id="example">Example</h4>
          <p>Get sender list</p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahCommGetSenderList()</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsendstr">NoahCommSendStr</a> - Send string to
            another channel <br />
            <a href="#noahcommismessageavailable">NoahCommIsMessageAvailable</a>
            - Check is the message available from the speficied channel <br />
            <a href="#noahcommreadstr">NoahCommReadStr</a> - Read string from
            another channel <br />
          </p>
          <hr />
          <h3 id="noahcommismessageavailable">NoahCommIsMessageAvailable</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Check is there any message availabel from the channel identified by
            the given <code>channel_id</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommIsMessageAvailable(string channel_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>channel_id</code> (string):
              </strong>
              The unique identifier for the channel.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <ul>
            <li>
              <code>True</code> if there&quot;s message available,
              <code>False</code> otherwise.
            </li>
          </ul>
          <h4 id="example">Example</h4>
          <p>
            Check if there&quot;s message available from the channel named
            <code>example_channel</code> and store the returned value to
            <code>is_msg_available</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                is_msg_available = NoahCommIsMessageAvailable(
                <span className="hljs-string">&quot;example_channel&quot;</span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsendstr">NoahCommSendStr</a> - Send string to
            another channel <br />
            <a href="#noahcommgetsenderlist">NoahCommGetSenderList</a> - Get
            list of channels which send messages <br />
            <a href="#noahcommreadstr">NoahCommReadStr</a> - Read string from
            another channel <br />
          </p>
          <hr />
          <h3 id="noahcommreadstr">NoahCommReadStr</h3>
          <h4 id="definition">Definition</h4>
          <p>
            Read message from the channel identified by the given
            <code>channel_id</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            <code>NoahCommReadStr(string channel_id)</code>
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>channel_id</code> (string):
              </strong>
              The unique identifier for the channel.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Message string if the message reads successfully, empty string
            otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            Read message from the channel named <code>example_channel</code> and
            store the return value to the <code>msg</code>
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                msg = NoahCommReadStr(
                <span className="hljs-string">&quot;example_channel&quot;</span>
                )
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <p>
            <a href="#noahcommsendstr">NoahCommSendStr</a> - Send string to
            another channel <br />
            <a href="#noahcommgetsenderlist">NoahCommGetSenderList</a> - Get
            list of channels which send messages <br />
            <a href="#noahcommismessageavailable">NoahCommIsMessageAvailable</a>
            - Check is the message available from the speficied channel <br />
            <a href="#noahcommreadstr">NoahCommReadStr</a> - Read string from
            another channel <br />
          </p>
          <hr />
          {/* <h2 id="communicationclient-class-reference-c">
            CommunicationClient Class Reference (C++)
          </h2>
          <p>
            The communication client is used for exchanging data with the host
            process.
          </p>
          <hr /> */}
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPICommunicationPage;
