import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIMusicPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Music APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#noahgetmediaduration">NoahGetMediaDuration</a></li>
              <li><a href="#noahgetmediatitle">NoahGetMediaTitle</a></li>
              <li><a href="#noahgetmediaartist">NoahGetMediaArtist</a></li>
              <li><a href="#noahgetmediaalbum">NoahGetMediaAlbum</a></li>
              <li><a href="#noahgetmediacomment">NoahGetMediaComment</a></li>
              <li><a href="#noahgetmediawidth">NoahGetMediaWidth</a></li>
              <li><a href="#noahgetmediaheight">NoahGetMediaHeight</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahgetmediaduration">NoahGetMediaDuration</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetMediaDuration(string media_path)</code></p>
          <h4 id="description">Description</h4>
          <p>The <code>NoahGetMediaDuration</code> function is designed to get stream duration of media file in second within a given <em>media_path</em> (relative to project path or absolute path). If the provided <em>media_path</em> is invalid, Noah will return 0.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>media_path</code> (string):</strong> The media file path.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return stream length in second if <em>media_path</em> valid, Otherwise returns 0.</p>
          <h4 id="example">Example</h4>
          <p>To get stream duration of media file in second within the media_path as &quot;media/sample.mp4&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> sec = NoahGetMediaTitle(<span class="hljs-string">"media/sample.mp4"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"Duration of media/sample.mp4 is: "</span> .. sec .. <span class="hljs-string">"s"</span>)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Duration of media/sample.mp4 is: 2s
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmediatitle">NoahGetMediaTitle</a> - Get media title of media file.</li>
          <li><a href="#noahgetmediaartist">NoahGetMediaArtist</a> - Get artist information of the media file.</li>
          <li><a href="#noahgetmediaalbum">NoahGetMediaAlbum</a> - Get album information of the media file.</li>
          <li><a href="#noahgetmediacomment">NoahGetMediaComment</a> - Get comment information of the media file.</li>
          <li><a href="#noahgetmediawidth">NoahGetMediaWidth</a> - Get width size of video channel information from the media file.</li>
          <li><a href="#noahgetmediaheight">NoahGetMediaHeight</a> - Get height size of video channel information from the media file.</li>
          </ul>
          <hr />
          <h3 id="noahgetmediatitle">NoahGetMediaTitle</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetMediaTitle(string media_path)</code></p>
          <h4 id="description">Description</h4>
          <p>The <code>NoahGetMediaTitle</code> function is designed to get title information of media file within a given <em>media_path</em> (relative to project path or absolute path). If the provided <em>media_path</em> is invalid, Noah will return empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>media_path</code> (string):</strong> The media file path.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return title information if <em>media_path</em> valid, Otherwise returns empty string.</p>
          <h4 id="example">Example</h4>
          <p>To get title information of media file within the media_path as &quot;media/sample.mp4&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> title = NoahGetMediaTitle(<span class="hljs-string">"media/sample.mp4"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"The title of media/sample.mp4 is: "</span> .. title)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>The title of media/sample.mp4 is: Sample video
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmediaduration">NoahGetMediaDuration</a> - Get stream duration of media file in second.</li>
          <li><a href="#noahgetmediaartist">NoahGetMediaArtist</a> - Get artist information of the media file.</li>
          <li><a href="#noahgetmediaalbum">NoahGetMediaAlbum</a> - Get album information of the media file.</li>
          <li><a href="#noahgetmediacomment">NoahGetMediaComment</a> - Get comment information of the media file.</li>
          <li><a href="#noahgetmediawidth">NoahGetMediaWidth</a> - Get width size of the media file.</li>
          <li><a href="#noahgetmediaheight">NoahGetMediaHeight</a> - Get height size of the media file.</li>
          </ul>
          <hr />
          <h3 id="noahgetmediaartist">NoahGetMediaArtist</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetMediaArtist(string media_path)</code></p>
          <h4 id="description">Description</h4>
          <p>The <code>NoahGetMediaArtist</code> function is designed to get artist information of media file within a given <em>media_path</em> (relative to project path or absolute path). If the provided <em>media_path</em> is invalid, Noah will return empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>media_path</code> (string):</strong> The media file path.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return artist information if <em>media_path</em> valid, Otherwise returns empty string.</p>
          <h4 id="example">Example</h4>
          <p>To get artist information of media file within the media_path as &quot;media/sample.mp4&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> artist = NoahGetMediaArtist(<span class="hljs-string">"media/sample.mp4"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"The artist of media/sample.mp4 is: "</span> .. artist)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>The artist of media/sample.mp4 is: Anonymous
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmediaduration">NoahGetMediaDuration</a> - Get stream duration of media file in second.</li>
          <li><a href="#noahgetmediatitle">NoahGetMediaTitle</a> - Get media title of media file.</li>
          <li><a href="#noahgetmediaalbum">NoahGetMediaAlbum</a> - Get album information of the media file.</li>
          <li><a href="#noahgetmediacomment">NoahGetMediaComment</a> - Get comment information of the media file.</li>
          <li><a href="#noahgetmediawidth">NoahGetMediaWidth</a> - Get width of video channel information from the media file.</li>
          <li><a href="#noahgetmediaheight">NoahGetMediaHeight</a> - Get height of video channel information from the media file.</li>
          </ul>
          <hr />
          <h3 id="noahgetmediaalbum">NoahGetMediaAlbum</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetMediaAlbum(string media_path)</code></p>
          <h4 id="description">Description</h4>
          <p>The <code>NoahGetMediaAlbum</code> function is designed to get album information of media file within a given <em>media_path</em> (relative to project path or absolute path). If the provided <em>media_path</em> is invalid, Noah will return empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>media_path</code> (string):</strong> The media file path.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return album information if <em>media_path</em> valid, Otherwise returns empty string.</p>
          <h4 id="example">Example</h4>
          <p>To get album information of media file within the media_path as &quot;media/sample.mp4&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> album = NoahGetMediaAlbum(<span class="hljs-string">"media/sample.mp4"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"The album of media/sample.mp4 is: "</span> .. album)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>The album of media/sample.mp4 is: Mindtronic AI Samples
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmediaduration">NoahGetMediaDuration</a> - Get stream duration of media file in second.</li>
          <li><a href="#noahgetmediatitle">NoahGetMediaTitle</a> - Get media title of media file.</li>
          <li><a href="#noahgetmediaartist">NoahGetMediaArtist</a> - Get artist information of the media file.</li>
          <li><a href="#noahgetmediacomment">NoahGetMediaComment</a> - Get comment information of the media file.</li>
          <li><a href="#noahgetmediawidth">NoahGetMediaWidth</a> - Get width size of the media file.</li>
          <li><a href="#noahgetmediaheight">NoahGetMediaHeight</a> - Get height size of the media file.</li>
          </ul>
          <hr />
          <h3 id="noahgetmediacomment">NoahGetMediaComment</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetMediaComment(string media_path)</code></p>
          <h4 id="description">Description</h4>
          <p>The <code>NoahGetMediaComment</code> function is designed to get comment information of media file within a given <em>media_path</em> (relative to project path or absolute path). If the provided <em>media_path</em> is invalid, Noah will return empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>media_path</code> (string):</strong> The media file path.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return comment information if <em>media_path</em> valid, Otherwise returns empty string.</p>
          <h4 id="example">Example</h4>
          <p>To get comment information of media file within the media_path as &quot;media/sample.mp4&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> comment = NoahGetMediaComment(<span class="hljs-string">"media/sample.mp4"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"The comment of media/sample.mp4 is: "</span> .. comment)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>The comment of media/sample.mp4 is: Noah sample video
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmediaduration">NoahGetMediaDuration</a> - Get stream duration of media file in second.</li>
          <li><a href="#noahgetmediatitle">NoahGetMediaTitle</a> - Get media title of media file.</li>
          <li><a href="#noahgetmediaartist">NoahGetMediaArtist</a> - Get artist information of the media file.</li>
          <li><a href="#noahgetmediaalbum">NoahGetMediaAlbum</a> - Get album information of the media file.</li>
          <li><a href="#noahgetmediawidth">NoahGetMediaWidth</a> - Get width size of the media file.</li>
          <li><a href="#noahgetmediaheight">NoahGetMediaHeight</a> - Get height size of the media file.</li>
          </ul>
          <hr />
          <h3 id="noahgetmediawidth">NoahGetMediaWidth</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetMediaWidth(string media_path)</code></p>
          <h4 id="description">Description</h4>
          <p>The <code>NoahGetMediaWidth</code> function is designed to get width size of video channel in media file within a given <em>media_path</em> (relative to project path or absolute path). If the provided <em>media_path</em> is invalid or do not have video channel, Noah will return 0 as its size.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>media_path</code> (string):</strong> The media file path.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return the width of its video channal if <em>media_path</em> valid, Otherwise returns 0.</p>
          <h4 id="example">Example</h4>
          <p>To get width of media file within the media_path as &quot;media/sample.mp4&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> width = NoahGetMediaWidth(<span class="hljs-string">"media/sample.mp4"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"The width of media/sample.mp4 is: "</span> .. width)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>The width of media/sample.mp4 is: 1920
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmediaduration">NoahGetMediaDuration</a> - Get stream duration of media file in second.</li>
          <li><a href="#noahgetmediatitle">NoahGetMediaTitle</a> - Get media title of media file.</li>
          <li><a href="#noahgetmediaartist">NoahGetMediaArtist</a> - Get artist information of the media file.</li>
          <li><a href="#noahgetmediaalbum">NoahGetMediaAlbum</a> - Get album information of the media file.</li>
          <li><a href="#noahgetmediacomment">NoahGetMediaComment</a> - Get comment information of the media file.</li>
          <li><a href="#noahgetmediaheight">NoahGetMediaHeight</a> - Get height size of the media file.</li>
          </ul>
          <hr />
          <h3 id="noahgetmediaheight">NoahGetMediaHeight</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetMediaHeight(string media_path)</code></p>
          <h4 id="description">Description</h4>
          <p>The <code>NoahGetMediaHeight</code> function is designed to get height size of video channel in media file within a given <em>media_path</em> (relative to project path or absolute path). If the provided <em>media_path</em> is invalid or do not have video channel, Noah will return 0 as its size.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>media_path</code> (string):</strong> The media file path.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return the height of its video channal if <em>media_path</em> valid, Otherwise returns 0.</p>
          <h4 id="example">Example</h4>
          <p>To get height of media file within the media_path as &quot;media/sample.mp4&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> height = NoahGetMediaHeight(<span class="hljs-string">"media/sample.mp4"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"The height of media/sample.mp4 is: "</span> .. height)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>The height of media/sample.mp4 is: 1080
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmediaduration">NoahGetMediaDuration</a> - Get stream duration of media file in second.</li>
          <li><a href="#noahgetmediatitle">NoahGetMediaTitle</a> - Get media title of media file.</li>
          <li><a href="#noahgetmediaartist">NoahGetMediaArtist</a> - Get artist information of the media file.</li>
          <li><a href="#noahgetmediaalbum">NoahGetMediaAlbum</a> - Get album information of the media file.</li>
          <li><a href="#noahgetmediacomment">NoahGetMediaComment</a> - Get comment information of the media file.</li>
          <li><a href="#noahgetmediawidth">NoahGetMediaWidth</a> - Get width size of the media file.</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIMusicPage;
