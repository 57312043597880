import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahLuaAPIBasicScreenPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Screen APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#noahgetscenesizepx">NoahGetSceneSizePx</a></li>
              <li><a href="#noahgetscenewidthpx">NoahGetSceneWidthPx</a></li>
              <li><a href="#noahgetsceneheightpx">NoahGetSceneHeightPx</a></li>
              <li><a href="#noahgetscenesizemm">NoahGetSceneSizeMm</a></li>
              <li><a href="#noahgetscenewidthmm">NoahGetSceneWidthMm</a></li>
              <li><a href="#noahgetsceneheightmm">NoahGetSceneHeightMm</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Screen Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahgetscenesizepx">NoahGetSceneSizePx</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetSceneSizePx(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the <strong>sizepx</strong> attribute of a specified
            scene, returning a <strong>vec2</strong> object. This object
            provides access to the width (x) and height (y) values via the dot
            operator. This function is exclusively applicable to Scene elements.
            If the provided scene parameter does not correspond to a Scene
            element, Noah will log an error message and return vec2(0, 0).
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec2</strong> object representing the size of the
            scene in pixels.
          </p>
          <h4 id="example">Example</h4>
          <p>To get the scene size in pixels of the scene &quot;main&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> sceneSize =
                NoahGetSceneSizePx(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Width: &quot;</span> ..
                sceneSize.x ..
                <span className="hljs-string">&quot;px, Height: &quot;</span>
                .. sceneSize.y ..
                <span className="hljs-string">&quot;px&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Width: 100px, Height: 50px</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetscenewidthpx">NoahGetSceneWidthPx</a> - Retrieves
              the x value of the eye position in pixels.
            </li>
            <li>
              <a href="#noahgetsceneheightpx">NoahGetSceneHeightPx</a> -
              Retrieves the y value of the eye position in pixels.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetscenewidthpx">NoahGetSceneWidthPx</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetSceneWidthPx(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Fetches the width (x value) of the <strong>sizepx</strong> attribute
            for a specified scene. This function is tailored for
            <strong>Scene</strong> elements. If the <em>scene</em> parameter
            does not match a <strong>Scene</strong> element, Noah logs an error
            message and returns <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the width value of the scene in pixels.</p>
          <h4 id="example">Example</h4>
          <p>To obtain the scene width in pixels of &quot;main&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> sceneWidth =
                NoahGetSceneWidthPx(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Scene width: &quot;</span>
                .. sceneWidth ..
                <span className="hljs-string">&quot;px&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Scene width: 100px</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetscenesizepx">NoahGetSceneSizePx</a> - Retrieves
              the eye position in pixels.
            </li>
            <li>
              <a href="#noahgetsceneheightpx">NoahGetSceneHeightPx</a> -
              Retrieves the y value of the eye position in pixels.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetsceneheightpx">NoahGetSceneHeightPx</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetSceneHeightPx(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Fetches the height (y value) of the <strong>sizepx</strong>
            attribute for a specified scene. This function is tailored for
            <strong>Scene</strong> elements. If the <em>scene</em> parameter
            does not match a <strong>Scene</strong> element, Noah logs an error
            message and returns <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the height value of the scene in pixels.</p>
          <h4 id="example">Example</h4>
          <p>To obtain the scene height in pixels of &quot;main&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> sceneWidth =
                NoahGetSceneWidthPx(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Scene height: &quot;</span>
                .. sceneHeight ..
                <span className="hljs-string">&quot;px&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Scene height: 100px</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetscenesizepx">NoahGetSceneSizePx</a> - Retrieves
              the eye position in pixels.
            </li>
            <li>
              <a href="#noahgetscenewidthpx">NoahGetSceneWidthPx</a> - Retrieves
              the x value of the eye position in pixels.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetscenesizemm">NoahGetSceneSizeMm</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetSceneSizeMm(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the <strong>sizemm</strong> attribute of a specified
            scene, returning a <strong>vec2</strong> object. This object enables
            access to the dimensions in millimeters (mm) through the x (width)
            and y (height) values using the dot operator. This function is
            exclusively applicable to <strong>Scene</strong> elements. If the
            scene parameter does not correspond to a <strong>Scene</strong>
            element, Noah logs an error message and returns
            <strong>vec2(0, 0)</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec2</strong> object representing the dimensions
            of the scene in millimeters.
          </p>
          <h4 id="example">Example</h4>
          <p>To obtain the scene width in millimeters of &quot;main&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> sceneWidthMm =
                NoahGetSceneSizeMm(
                <span className="hljs-string">&quot;main&quot;</span>).x<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Scene width: &quot;</span>
                .. sceneWidthMm ..
                <span className="hljs-string">&quot;mm&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Scene width: 100mm</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetscenewidthmm">NoahGetSceneWidthMm</a> - Retrieves
              the width of the scene in millimeters.
            </li>
            <li>
              <a href="#noahgetsceneheightmm">NoahGetSceneHeightMm</a> -
              Retrieves the height of the scene in millimeters.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetscenewidthmm">NoahGetSceneWidthMm</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetSceneWidthMm(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Fetches the width (x value) of the <strong>sizemm</strong> attribute
            for a specified scene. This function is designed for
            <strong>Scene</strong> elements. If the <em>scene</em> parameter
            does not match a <strong>Scene</strong> element, Noah logs an error
            message and returns <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the width value of the scene in millimeters.</p>
          <h4 id="example">Example</h4>
          <p>To obtain the scene width in millimeters of &quot;main&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> sceneWidthMm =
                NoahGetSceneWidthMm(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Scene width: &quot;</span>
                .. sceneWidthMm ..
                <span className="hljs-string">&quot;mm&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Scene width: 100mm</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetscenesizemm">NoahGetSceneSizeMm</a> - Retrieves
              the size of the scene in millimeters.
            </li>
            <li>
              <a href="#noahgetsceneheightmm">NoahGetSceneHeightMm</a> -
              Retrieves the height of the scene in millimeters.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetsceneheightmm">NoahGetSceneHeightMm</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetSceneHeightMm(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Fetches the height (y value) of the <strong>sizemm</strong>
            attribute for a specified scene. This function is specific to
            <strong>Scene</strong> elements. If the <em>scene</em> parameter
            does not align with a <strong>Scene</strong> element, Noah logs an
            error message and returns <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the height value of the scene in millimeters.</p>
          <h4 id="example">Example</h4>
          <p>To obtain the height in millimeters of &quot;main&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> sceneHeightMm =
                NoahGetSceneHeightMm(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Scene height: &quot;</span>
                .. sceneHeightMm ..
                <span className="hljs-string">&quot;mm&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Scene height: 50mm</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetscenesizemm">NoahGetSceneSizeMm</a> - Retrieves
              the size of the scene in millimeters.
            </li>
            <li>
              <a href="#noahgetscenewidthmm">NoahGetSceneWidthMm</a> - Retrieves
              the width of the scene in millimeters.
            </li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIBasicScreenPage;
