import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab4Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab4_title">Lab4 Communication</h1>
          <h1 id="how-to-send-data-to-other-client">How to send data to other client</h1>
          <p>In the previous section, we learned how to receive data published by other clients from the host. Now, let's discuss how to publish data to the host. To do this, we need to create a <code>Publisher</code>.</p>
          <h2 id="create-a-publisher">Create a Publisher</h2>
          <p>To publish data, the client needs to send a request to the host with the <code>data_id</code>. The host then allocates a block of shared memory, which is used to store the data that the client wishes to share with other clients. After the memory block is allocated, the host sends the memory address back to the client.</p>
          <p>Unlike subscribing the data, Noah API requires different publishers to be created for different data types. Currently, three types of publishers are supported: <code>number</code>, <code>boolean</code>, and <code>string</code>.</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommPublishNumber(<span class="hljs-built_in">string</span> data_id)<br />
            NoahCommPublishBoolean(<span class="hljs-built_in">string</span> data_id)<br/>
            NoahCommPublishString(<span class="hljs-built_in">string</span> data_id)<br/>
          </div></code></pre>

          <p>where the <code>data_id</code> is the identifier used to identify the corresponding data. After calling these functions, a <code>boolean</code> is returned to indicate whether the publishment was successful.</p>
          <p>In the Lua script <code>script.lua</code>, you can add the following code in <code>Initialize()</code> function to publish on the host to store the data:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommPublishNumber(<span class="hljs-string">"ac_temp"</span>)
            NoahCommPublishBoolean(<span class="hljs-string">"is_ac_on"</span>)<br/>
            NoahCommPublishString(<span class="hljs-string">"ac_mode"</span>)<br/>
          </div></code></pre><br/>

          <h2 id="set-data-to-the-host">Set data to the host</h2>
          <p>After calling APIs above, the host then allocates a block of shared memory to store the data. So no data is stored at the  corresponding address yet. You can add the following code in <code>Update()</code> function to set the data to the memory space:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommSetNumber(<span class="hljs-string">"ac_temp"</span>, <span class="hljs-number">24.0</span>)
            NoahCommSetBoolean(<span class="hljs-string">"is_ac_on"</span>, <span class="hljs-number">1</span>)<br/>
            NoahCommSetString(<span class="hljs-string">"ac_mode"</span>, <span class="hljs-string">"auto"</span>)<br/>
          </div></code></pre>

          <p>The APIs will set different types of data to the specified <code>data_id</code> based on the API we called. Please ensure that the data types used when setting the data match the types used when publishing the data.</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommSetNumber(<span class="hljs-built_in">string</span> data_id, number data)
            NoahCommSetBoolean(<span class="hljs-built_in">string</span> data_id, boolean data)<br/>
            NoahCommSetString(<span class="hljs-built_in">string</span> data_id, <span class="hljs-built_in">string</span> data)<br/>
          </div></code></pre><br/>

          <h2 id="destroy-a-publisher">Destroy a Publisher</h2>
          <p>When there's no longer need to update this data, such as when ending the Noah project, you need to unpublish the data. You can add the following code to the <code>Terminate()</code> function in the <code>script.lua</code> to unpublish the data:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommUnPublish(<span class="hljs-string">"ac_temp"</span>)<br />
            NoahCommUnPublish(<span class="hljs-string">"is_ac_on"</span>)<br/>
            NoahCommUnPublish(<span class="hljs-string">"ac_mode"</span>)<br/>
          </div></code></pre>

          <p>The <code>NoahCommUnPublish(string data_id)</code> function will unpublish the data with the given <code>data_id</code>. If no published data exists for the specified <code>data_id</code>, an error message will be output:</p>
          <pre class="hljs hljs-content"><code><div>
            [CommClient] Channel is not created
          </div></code></pre>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab4Page;
