import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function OmniFaqPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>FAQ</h1>
          <h2>Q: How does the DVR features work in OMNI?</h2>
          <p>
            The OMNI device shall be able to loop record 3 camera images
            simulataneouly, and split the each recording by 3-min chunk. All the
            chunks are stored in the S/D card and can be viewed separarely via
            device panel or via cellphone Apps. The chunks will be automatically
            overwitten only when the S/D is out of space. You may find all the
            video chunks in the path below: Dashcam/[Cabin|Front|Rear]/Rec$
            /[timestamp/].mp4 Emergency recording is triggered when the vehicle
            collision is detected. The recording video is stored in
            protected/[Cabin|Front|Rear]. Please note that the video files under
            this folder will NOT overwritten in the loop recording for evidence
            protection. So if this folder had occupied too much S/D space,
            you&apos;ll need to delete them manually if you want to free up some
            space.
          </p>
          <h2>Q: How does the event video features work in OMNI?</h2>
          <p>
            Event recording, instead, is not retained in th S/D card but is
            being uploaded to the cloud. When the internet connection is not
            present at which the event is happening, the event video will be
            dropped. For the short disconnection sometimes due to cellur station
            issues, the event log (event type, location where the event
            happened, sensor data, etc) will be buffered for a certain period
            and uploaded later until the internet connection is resumed.
          </p>
          <h2>Q: If I encounter problems in DVR, how to troubleshooting?</h2>
          <p>
            1. MicroSD Card Spec: Ensure that your MicroSD card is big enough
            size and supports enough recording speed. Using a class 10 (C10)
            MicroSD card with a size of 64GB or higher is recommended.
          </p>
          <p>
            2. DVR Settings: Press the ‘Menu’ button on the OMNI device,
            navigate to ’DVR Settings’ using the ‘Up’ or ‘Down’ button, and
            press the ‘Enter’ button to enter the DVR setting page. Ensure that
            the selected DVR recording options are turned ON.
          </p>
          <p>
            3. SD Indicator: Go back to the camera view by pressing the ‘Menu’
            button a few times until the screen shows the camera view. When the
            system reads the MicroSD card successfully, an SD indicator at the
            bottom right of the screen should be visible.
          </p>
          <p>
            4. Recording Indicator: In the camera view, check if there is a red
            dot flashing at the top left corner of the display. The flashing red
            dot indicates the device is recording camera frames to the MicroSD
            card.
          </p>
          <p>
            5. Format the MicroSD Card: If any of the above indicators are not
            shown up correctly, go to the DVR setting page, and select the
            ‘Format SD Card’ item by pressing the ‘Enter’ button to format the
            MicroSD card. The display may hold for a few seconds while
            formatting the MicroSD card. After it is completed, you will hear an
            audio playing “SD card format done.” Then, go back to the camera
            view. The indicators should appear as expected now. After waiting
            for about 10 min, you may remove the MicroSD card from the OMNI
            device and obtain multiple continuous video recordings in the folder
            ‘dashcam’.
          </p>
          <h2>
            Q: How does the Driver Monitoring System (DMS) enhance safety?
          </h2>
          <p>
            The DMS tracks driver behavior, such as attentiveness, eye closure
            (indicating sleep), and head position, to detect and alert on signs
            of drowsiness, distraction, or other unsafe behaviors.
          </p>
          <h2>Q: Why are alerts sometimes not triggered?</h2>
          <p>
            Alerts from our system are specifically designed to trigger under
            certain conditions for maximum relevance and accuracy. Below are the
            velocity thresholds for different alerts:
          </p>
          <ul>
            <li>
              <strong>Driver Monitoring System (DMS) Alerts:</strong> Triggered
              when the vehicle speed exceeds 15 km/h.
            </li>
            <li>
              <strong>Time To Collision (TTC) Alerts:</strong> Triggered at
              speeds above 40 km/h.
            </li>
            <li>
              <strong>Headway Monitoring Warning (HMW) Alerts:</strong>{' '}
              Triggered at speeds over 45 km/h.
            </li>
            <li>
              <strong>Lane Departure Warning (LDW) Alerts:</strong> Triggered
              when the vehicle is traveling faster than 60 km/h.
            </li>
          </ul>
          <p>
            These velocity thresholds are set to ensure that alerts are
            meaningful and reduce unnecessary distractions at lower speeds.
          </p>
          <h2>Q: How to enable Face ID feature?</h2>
          <p>
            Face ID Authentication automatically activates as the driver enters
            the cockpit, providing seamless and secure access.
          </p>
          <h2>Q: Does the Face ID feature encounter driver privacy issues?</h2>
          <p>
            The Face ID feature is designed with privacy in mind. It only
            uploads the facial features extracted by the DMS and does not upload
            the actual image of the driver. The only exception is when an
            unknown driver is detected; in such cases, the image is uploaded for
            security purposes. This approach ensures driver privacy is respected
            while maintaining the integrity and security of the fleet management
            system.
          </p>
          <h2>
            Q: If the internet is disconnected, does the trajectory data store
            offline and upload to the cloud when the internet resumes?
          </h2>
          <p>
            Yes, the OMNI system is designed to handle situations where the
            internet connection is lost. In such cases, the system stores the
            trajectory data offline for up to an hour. Once the internet
            connection is re-established, the stored data is automatically
            uploaded to the cloud. This ensures that important trajectory
            information is not lost and is accurately reflected in the
            cloud-based fleet management system when connectivity resumes.
          </p>
          <h2>Q: Why are there sometimes no event clips for certain events?</h2>
          <p>
            There are a couple of reasons why event clips might not always be
            available:
          </p>
          <ul>
            <li>
              <strong>Concurrent Event Recording:</strong> If two events occur
              in close succession, the recording of the second event might be
              blocked if the first event&apos;s clip is still being recorded.
              The system needs to complete the recording of one event before it
              can start recording another.
            </li>
            <li>
              <strong>Weak Internet Signal:</strong> In cases where the internet
              signal is weak, the event clip may not upload in time. A strong
              and stable internet connection is required for timely uploading of
              event clips to the cloud.
            </li>
            <li>
              <strong>No SD Card or Internet Disconnection:</strong> Event clips
              will not be recorded if an SD card is not inserted in the device
              or if there is an internet disconnection. This is to reduce usage
              of the SD card’s storage capacity.
            </li>
          </ul>
          <p>
            These factors can affect the availability of event clips in the
            system, and ensuring a stable internet connection can help mitigate
            these issues.
          </p>

          <h2>
            Q: Why is there sometimes no video available in on-demand streaming?
          </h2>
          <p>
            Occasionally, you might experience an absence of video in the
            on-demand streaming feature. This issue typically arises due to
            limitations in the 4G bandwidth or weak signal strength. When the 4G
            connection is not strong enough to support live video streaming, the
            video feed may not be available. Ensuring a strong and stable 4G
            connection is key to uninterrupted video streaming.
          </p>
          <h2>Q: How to get the fleet management account?</h2>
          <p>Please aquire it from Mindtronic AI sales.</p>
          <h2>Q: Could I integrate the data in the cloud to our own system?</h2>
          <p>
            Yes, Mindtronic AI provides a set of API for helping usess integrate
            the data to their own system. Please contact Mindtronic AI sales for
            the latest cloud API.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OmniFaqPage;
