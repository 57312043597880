import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahTutorialLab2Page3() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="before-you-begin">Lab2 Lua Script</h1>
          <h1 id="introduce-to-default-lua-functions">Introduce to default Lua functions</h1>
          <p>In <code>script.lua</code>, two Lua scripts, <code>keyboard</code> and <code>mouse</code>, are imported. These scripts describe the behaviors of the keyboard and mouse respectively. Both scripts include several predefined Lua functions that do not require explicit invocation; instead, they are automatically executed during initialization and are isolated for specific event handling.</p>
          <p>The following sections will provide an overview of these two Lua scripts and their default functions.</p>
          <h2 id="keyboardlua">keyboard.lua</h2>
          <p>The <code>keyboard.lua</code> script is designed to handle keyboard events. It includes two predefined functions:</p>
          <ul>
            <li><strong><code>KeyPress(key)</code></strong>:
                <ul>
                    <li>Triggered when a key is pressed.</li>
                    <li>The <code>key</code> parameter captures the specific key that was pressed.</li>
                </ul>
            </li>
            <li><strong><code>KeyRelease(key)</code></strong>:
                <ul>
                    <li>Triggered when a key is released.</li>
                    <li>The <code>key</code> parameter stores the released key.</li>
                </ul>
            </li>
          </ul>
          <pre class="hljs hljs-content"><code><div>
            function KeyPress(key) <br />
            {'  '}{'  '}print(key .. " is pressed.") <br />
            end <br /> <br />

            function KeyRelease(key) <br />
            {'  '}{'  '}print(key .. " is released.") <br />
            end <br />
          </div></code></pre><br />
          
          <h2 id="mouselua">mouse.lua</h2>
          <p>The <code>mouse.lua</code> script handles mouse-related events. It defines four types of events for user interaction:</p>
          <ul>
            <li>
                <p><strong><code>Mouse Move:</code></strong>:</p>
                <ul>
                    <li>Function <code>MouseMove(window, x, y)</code>.</li>
                    <li>Triggered when the mouse is moved.</li>
                    <li>The <code>x</code> and <code>y</code> parameters store the mouse position in pixels.</li>
                </ul>
            </li>
            <li>
                <p><strong><code>Mouse Press</code></strong>:</p>
                <ul>
                    <li>The functions like <code>MouseLeftButtonPress(window, x, y)</code>.</li>
                    <li>Triggered when a mouse button is pressed, and it supports <code>Left</code>, <code>Right</code> and <code>Middle</code>(scroll wheel) button.</li>
                    <li>The <code>window</code> parameter stores the name of window(<code>id</code> defined in <code>noah.yml</code>) where mouse pressed, and the <code>x</code> and <code>y</code> parameters store the position where mouse pressed in pixels.</li>
                </ul>
            </li>
            <li>
                <p><strong><code>Mouse Release</code></strong>:</p>
                <ul>
                    <li>The functions like <code>MouseLeftButtonRelease(window, x, y)</code>.</li>
                    <li>Triggered when a mouse button is released, and it supports <code>Left</code>, <code>Right</code> and <code>Middle</code>(scroll wheel) button.</li>
                    <li>The <code>window</code> parameter stores the name of window(<code>id</code> defined in <code>noah.yml</code>) where mouse released, and the <code>x</code> and <code>y</code> parameters store the position where mouse released in pixels.</li>
                </ul>
            </li>
            <li>
                <p><strong><code>Wheel Scroll</code></strong>:</p>
                <ul>
                    <li>Function <code>MouseWheelScroll(window, x, y, delta)</code>.</li>
                    <li>Triggered when the scroll wheel is used.</li>
                    <li>The <code>window</code> parameter stores the name of window(<code>id</code> defined in <code>noah.yml</code>) where wheel scrolled, and the <code>x</code> and <code>y</code> parameters store the position where wheel scrolled in pixels.</li>
                    <li>The <code>delta</code> parameter has 2 values, 1 and -1, which represent <em>scroll up</em> and <em>scroll down</em> respectively.</li>
                </ul>
            </li>
          </ul>
          <pre class="hljs hljs-content"><code><div><span class="hljs-function">
            <span class="hljs-keyword">function</span> <span class="hljs-title">MouseMove</span><span class="hljs-params">(window, x, y)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse move on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y)<br />
            <span class="hljs-keyword">end</span><br />
    <br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">MouseLeftButtonPress</span><span class="hljs-params">(window, x, y)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse press on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y)<br />
            <span class="hljs-keyword">end</span><br />
    <br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">MouseRightButtonPress</span><span class="hljs-params">(window, x, y)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse press on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y)<br />
            <span class="hljs-keyword">end</span><br />
    <br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">MouseMiddleButtonPress</span><span class="hljs-params">(window, x, y)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse press on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y)<br />
            <span class="hljs-keyword">end</span><br />
    <br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">MouseLeftButtonRelease</span><span class="hljs-params">(window, x, y)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse release on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y)<br />
            <span class="hljs-keyword">end</span><br />
    <br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">MouseRightButtonRelease</span><span class="hljs-params">(window, x, y)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse release on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y)<br />
            <span class="hljs-keyword">end</span><br />
    <br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">MouseMiddleButtonRelease</span><span class="hljs-params">(window, x, y)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse release on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y)<br />
            <span class="hljs-keyword">end</span><br />
    <br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">MouseWheelScroll</span><span class="hljs-params">(window, x, y, delta)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"mouse scroll on window "</span> .. window .. <span class="hljs-string">" at "</span> .. x .. <span class="hljs-string">", "</span> .. y .. <span class="hljs-string">" by "</span> .. delta)<br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre><br />
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab2Page3;
