import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab1Page5() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab1_title">Lab1 UI Layout Construction</h1>
          <h1 id="conclusion">Conclusion</h1>
          <h2 id="summary">Summary</h2>
          <ul>
          <li>Understand the tags and attributes of Noah Basic Element.</li>
          <li>Prepare UI Assets.</li>
          <li>Layout UI in Figma.</li>
          <li>Place UI in the XML file.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab1Page5;
