import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab4Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab4_title">Lab4 Communication</h1>
          <h1 id="how-to-receive-data-from-other-client">How to receive data from other client</h1>
          <p>In the previous section, we successfully established a connection. Now, let's discuss how to receive data from the host. To do this, we need to create a <code>Subscriber</code>.</p>
          <h2 id="create-a-subscriber">Create a Subscriber</h2>
          <p>Clients can subscribe to data published by another client by sending a request with the appropriate <code>data_id</code>. The host will verify if the <code>data_id</code> is valid and has been published. If valid, the host sends the shared memory address to the subscribing client.</p>
          <p>To facilitate this, the first thing we need to do is to subscribe a data. In the Lua script <code>script.lua</code>, you can add the following code to the <code>Initialize()</code> function:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">if</span> NoahCommConnect(<span class="hljs-string">"consumer"</span>, <span class="hljs-string">"127.0.0.1"</span>, <span class="hljs-number">5050</span>) <span class="hljs-keyword">then</span><br />
            {'  '}{'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"NoahCommConnect success"</span>)<br/>
            <span class="hljs-keyword">else</span><br/>
            {'  '}{'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"NoahCommConnect failed"</span>)<br/>
            <span class="hljs-keyword">end</span><br/><br/>

            NoahCommSubscribe(<span class="hljs-string">"vehicle_speed"</span>)<br/>
            NoahCommSubscribe(<span class="hljs-string">"music_index"</span>)<br/>
            NoahCommSubscribe(<span class="hljs-string">"is_playing"</span>)<br/>
            NoahCommSubscribe(<span class="hljs-string">"music_name"</span>)<br/>
            NoahCommSubscribe(<span class="hljs-string">"image"</span>)
          </div></code></pre>

          <p>The code subscribe five data, which <code>data_id</code> are <code>vehicle_speed</code>, <code>music_index</code>, <code>is_playing</code>, <code>music_name</code>, <code>image</code> respectively. If the <code>data_id</code> is valid and has been published, the function will return a boolean indicating whether the subscription was successful.</p><br/>
          
          <h2 id="receive-data-from-the-host">Receive data from the host</h2>
          <p>Thus we have successfully subscribe the data, now we are able to access the data by the Get APIs:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommGetNumber(<span class="hljs-built_in">string</span> data_id)<br/>
            NoahCommGetBoolean(<span class="hljs-built_in">string</span> data_id)<br/>
            NoahCommGetString(<span class="hljs-built_in">string</span> data_id)<br/>
            NoahSetSubscribedImage(<span class="hljs-built_in">string</span> image_id, <span class="hljs-built_in">string</span> data_id)<br/>
          </div></code></pre>

          <p>These APIs are used to get different types of data. Currently, there are four data types supported.</p>
          <ul>
            <li>The APIs for fetching <code>number</code>, <code>string</code>, and <code>boolean</code> types require the <code>data_id</code> parameter to identify which data to retrieve.</li>
            <li>The API for fetching image data is special in that it requires both <code>image_id</code> and <code>data_id</code> parameters, meaning you retrieve the image data with <code>data_id</code> and assign it to the <code>src</code> attribute of an <strong>Image2D</strong> or <strong>Image3D</strong> element with the given <code>image_id</code>.</li>
          </ul>

          <p>In the Lua script <code>script.lua</code>, you can add the following code to the <code>Update()</code> function to get the data of corresponding type:
          <strong><em>Note:</em></strong> Please ensure the data types retrieved match the expected types; otherwise, an error will occur.</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br/>
            {'  '}<span class="hljs-keyword">local</span> vehicle_speed = NoahCommGetNumber(<span class="hljs-string">"vehicle_speed"</span>)<br/>
            {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"vehicle_speed: "</span> .. vehicle_speed)<br/>
            {'  '}UpdateMeter(vehicle_speed)<br/>
            {'  '}<br/>
            {'  '}<span class="hljs-keyword">local</span> music_index = NoahCommGetNumber(<span class="hljs-string">"music_index"</span>)<br/>
            {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"music_index: "</span> .. music_index)<br/>
            {'  '}<br/>
            {'  '}<span class="hljs-keyword">local</span> is_playing = NoahCommGetBoolean(<span class="hljs-string">"is_playing"</span>)<br/>
            {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"is_playing: "</span> .. <span class="hljs-built_in">tostring</span>(is_playing))<br/>
            {'  '}<br/>
            {'  '}<span class="hljs-keyword">local</span> music_name = NoahCommGetString(<span class="hljs-string">"music_name"</span>)<br/>
            {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"music_name: "</span> .. music_name)<br/>
            {'  '}NoahSetText(<span class="hljs-string">"music_name"</span>, music_name)<br/>
            {'  '}<br/>
            {'  '}NoahSetSubscribedImage(<span class="hljs-string">"external_image"</span>, <span class="hljs-string">"image"</span>)<br/>
            <span class="hljs-keyword">end</span><br/>
          </div></code></pre><br/>

          <h2 id="destroy-a-subscriber">Destroy a Subscriber</h2>
          <p>When there's no longer need to retrieve this data, such as when ending the Noah project, you need to unsubscribe from the data. You can add the following code to the <code>Terminate()</code> function in the <code>script.lua</code> to unsubscribe the data:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommUnSubscribe(<span class="hljs-string">"vehicle_speed"</span>)<br />
            NoahCommUnSubscribe(<span class="hljs-string">"music_index"</span>)<br/>
            NoahCommUnSubscribe(<span class="hljs-string">"is_playing"</span>)<br/>
            NoahCommUnSubscribe(<span class="hljs-string">"music_name"</span>)<br/>
            NoahCommUnSubscribe(<span class="hljs-string">"image"</span>)<br/>
          </div></code></pre>
          
          <p>The <code>NoahCommUnSubscribe(string data_id)</code> function will unsubscribe the data with the given <code>data_id</code>. The function will return a boolean indicating whether the unsubscription was successful.</p>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab4Page;
