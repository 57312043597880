import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahTutorialLab3Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab3_title"> Lab3 Button and Slider</h1>
          <h1 id="concept-of-ui-components">Concept of UI components</h1>
          <p>A UI component is a reusable user interface building block designed to simplify the design and maintenance of user interactions. Each UI component should possess the following characteristics:</p>
          <ol>
          <li><strong>Customizable Visual Appearance</strong>: This defines how the component appears to the end user, helping to convey its function and offering an intuitive way to interact with it.</li>
          <li><strong>Event Trigger</strong>: This refers to the dynamic behavior activated when an end user interacts with the UI components.
          In Noah, the appearance attribute is used to specify a UI component's look, while the callback attribute acts as the event trigger, outlining the dynamic behavior that occurs.</li>
          </ol>
          <p>To streamline the process, templates are employed for UI components that share the same appearance, preventing repetitive definitions. When introducing a control item, it's necessary to design a Lua function to handle its behavior. The attribute to define the UI components varies among different types of them.</p>
          <p>The supported UI components include:</p>
          <ul>
          <li>Button</li>
          <li>Slider</li>
          </ul>
          <p>We’ll talk about how to use a UI component and write examples for each kind of UI component in the following sections.</p>
          <p>Let’s start from the lab project project/demo_UI_components in the Noah Lab Package.
          Replace the main scene main.xml with a background-only scene:</p>
          <pre class="hljs hljs-content"><code><div><span class="hljs-meta">&lt;?xml version="1.0" encoding="UTF-8"?&gt;</span><br />
          <br />
          <span class="hljs-tag">&lt;<span class="hljs-name">Scene</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"main"</span> <span class="hljs-attr">sizepx</span>=<span class="hljs-string">"1920, 720"</span> <span class="hljs-attr">sizemm</span>=<span class="hljs-string">"346, 130"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">eyepos</span>=<span class="hljs-string">"0, 90, 650"</span>&gt;</span><br />
            {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Quad2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"background"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"0, 0"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"1920"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"720"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0, 0"</span> <span class="hljs-attr">color</span>=<span class="hljs-string">"#ff404040 #ff404040 #ff202020 #ff181818"</span>/&gt;</span><br />
          <span class="hljs-tag">&lt;/<span class="hljs-name">Scene</span>&gt;</span><br />
          </div></code></pre><br />
          <p>Follow the next sections to learn how to use UI components.</p><br />
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab3Page;
