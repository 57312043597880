import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported
import ffmpegVersionImg from './screenshot/ffmpeg_version.png';
import glVersionImg from './screenshot/GL_version.png';
import noahVersionImg from './screenshot/version.png';

/*eslint-disable */

function NoahInstallationPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="installation">Installation</h1>
          <h2 id="system-requirement">System Requirement</h2>
          <ul>
          <li>
          <p>Use this package on <strong>Ubuntu 20.04</strong> system.</p>
          </li>
          <li>
          <p><strong>ffmpeg-4.2.7</strong> is required. If you don't have ffmpeg-4.2.7 on your system, follow the steps below to install it:</p>
          <ol>
          <li>
          <p>Download the ffmpeg-4.2.7 source code here:
          https://ffmpeg.org/download.html#releases</p>
          </li>
          <li>
          <p>Unzip the file.</p>
          </li>
          <li>
          <p>Build and install:</p>
          <pre class="hljs"><code><div>$ cd ffmpeg-4.2.7
          $ sudo ./configure
          $ make
          $ sudo make install
          </div></code></pre>
          <p>The configuration and compiling may take some minutes, please wait.</p>
          </li>
          <li>
          <p>Check the installed libraries and version:</p>
          <pre class="hljs"><code><div>$ ffmpeg -version
          </div></code></pre>
          <p>If the installation is correctly done, you can see something like this:</p>
          <p><img src={ffmpegVersionImg} alt="screenshot" /></p>
          </li>
          <li>
          <p>Don't delete your building folder.</p>
          </li>
          </ol>
          </li>
          <li>
          <p><strong>OpenGL ES 3.1</strong> or newer version is required. If you are not sure what version of OpenGL is available on your system, just run <em>noah-theatre</em> to check it:
          <code>      $ ./noah_theatre-x64-linux      </code>
          If the available OpenGL version exists, information shows:
          <img src={glVersionImg} alt="screenshot" /></p>
          </li>
          </ul>
          <h2 id="installation">Installation</h2>
          <ol>
          <li>Get the Noah pacakge from your sales.</li>
          <li>Unzip the Noah packages.</li>
          </ol>
          <pre class="hljs"><code><div>$ tar -xf noah_104a.tar.gz
          $ cd noah_104a
          $ tar -xf noah.bin_104a.tar.gz
          $ tar -xf noah.lab_104a.tar.gz
          $ tar -xf noah.doc_104a.tar.gz
          </div></code></pre>
          <ol start="3">
          <li>Go to the bin package folder.</li>
          </ol>
          <pre class="hljs"><code><div>$ cd noah.bin_104a
          </div></code></pre>
          <ol start="4">
          <li>Check the version of noah</li>
          </ol>
          <pre class="hljs"><code><div>$ source cshrc.noah
          $ noah_theatre-x64-linux –version
          </div></code></pre>
          <p>If the installation succeeds, the terminal will show:</p>
          <p><img src={noahVersionImg} alt="screenshot" /></p>
        </div>
      </div>
    </div>
  );
}

export default NoahInstallationPage;
