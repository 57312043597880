import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */
function NoahLuaAPIReferencePage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-105a">version 105a</h4>
          <hr />
          <h3 id="basic-noah-functions">Basic Noah Functions</h3>
          <Link to="/noah/api/camera" className="scroll-link side-nav-item">
            Camera
          </Link>
          <Link to="/noah/api/screen" className="scroll-link side-nav-item">
            Screen
          </Link>
          <Link to="/noah/api/transform" className="scroll-link side-nav-item">
            Transform
          </Link>
          <Link to="/noah/api/shape" className="scroll-link side-nav-item">
            Shape
          </Link>
          <Link to="/noah/api/color" className="scroll-link side-nav-item">
            Color
          </Link>
          <Link to="/noah/api/font" className="scroll-link side-nav-item">
            Font
          </Link>
          <Link to="/noah/api/imgae" className="scroll-link side-nav-item">
            Image
          </Link>
          <Link to="/noah/api/3DScene" className="scroll-link side-nav-item">
            3D Scene
          </Link>
          <h3 id="basic-noah-functions">Media</h3>
          <Link to="/noah/api/filesystem" className="scroll-link side-nav-item">
            Filesystem
          </Link>
          <Link to="/noah/api/video" className="scroll-link side-nav-item">
            Video
          </Link>
          <Link to="/noah/api/music" className="scroll-link side-nav-item">
            Music
          </Link>
          <h3 id="basic-noah-functions">Animation Noah Functions</h3>
          <Link to="/noah/api/animation" className="scroll-link side-nav-item">
            Animation
          </Link>
          <h3 id="basic-noah-functions">UI Component</h3>
          <Link to="/noah/api/slider" className="scroll-link side-nav-item">
            Slider
          </Link>
          <Link to="/noah/api/button" className="scroll-link side-nav-item">
            Button
          </Link>
          <h3 id="basic-noah-functions">Noah Communication Functions</h3>
          <Link
            to="/noah/api/communication"
            className="scroll-link side-nav-item"
          >
            Communication
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIReferencePage;
