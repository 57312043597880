import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab4Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab4_title">Lab4 Communication</h1>
          <h1 id="introduction">Send/Read message</h1>
          <p>In addition to publishing and subscribing to data, Noah also allows for direct message communication between clients. This section introduces how to send and receive messages between clients.</p>
          <h2 id="send-message-to-other-client">Send Message to Other Client</h2>
          <p>When you want to send a message to another client in real time instead of setting text by publishing data. You can add the following code in your Lua script to send a message to the specified client:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommSendStr(<span class="hljs-string">"producer"</span>, <span class="hljs-string">"hello"</span>)
          </div></code></pre>

          <p>The <code>NoahCommSendStr(string channssel_id, string message)</code> function will send the message content <code>message</code> to the client identified by <code>channel_id</code>. If no client exists for the specified channel_id, an error message will be displayed:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommConnect failed
          </div></code></pre><br/>

          <h2 id="receive-message-from-other-client">Receive Message from Other Client</h2>
          <p>Similarly, Noah provides an API to receive messages from other clients. You can add the following code in your Lua script to receive a message from a specific client:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommReadStr(<span class="hljs-string">"producer"</span>)
          </div></code></pre>

          <p>The <code>NoahCommReadStr(string channel_id)</code> function will read the message from the client identified by <code>channel_id</code>. If the client has not sent any messages, this function will return an empty string. After receiving the message, the message buffer will be clear so the return will be an empty string when callubg this API again.</p>
          <p>To avoid excessive calls to the <code>NoahCommReadStr()</code> API, you can add the following code in your Lua script to check which clients have sent messages to you:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommGetSenderList()
          </div></code></pre>

          <p>The <code>NoahCommGetSenderList()</code> function will return a vector containing the list of clients that have sent messages. If no client has sent any messages, it will return an empty vector.</p>
          <p>Alternatively, if you have a specific target or just want to verify if a message has been sent from a particular client, you can add the following code in your Lua script to check if there is a message from a specific client:</p>
          <pre class="hljs hljs-content"><code><div>
            NoahCommIsMessageAvailable(<span class="hljs-string">"producer"</span>)
          </div></code></pre>

          <p>The <code>NoahCommIsMessageAvailable(string channel_id)</code> function checks if the client identified by <code>channel_id</code> has sent a message and returns a <code>boolean</code> indicating whether a message has been received.</p><br/>
          
          <h3 id="example-receive-message-from-a-client">Example: Receive Message from a Client</h3>
          <p>Here is an example of how to receive a message from a specific client. You can add this to the <code>Update</code> function in your main Lua script:</p>
          <pre class="hljs hljs-content"><code><div>
              {'  '}<span class="hljs-keyword">local</span> is_producer_msg_available = NoahCommIsMessageAvailable(<span class="hljs-string">"producer"</span>)<br />
              {'  '}<span class="hljs-keyword">while</span> is_producer_msg_available <span class="hljs-keyword">do</span><br/>
              {'  '}{'  '}<span class="hljs-keyword">local</span> msg = NoahCommReadStr(<span class="hljs-string">"producer"</span>)<br/>
              {'  '}{'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"msg: "</span> .. msg)<br/>
              {'  '}{'  '}is_producer_msg_available = NoahCommIsMessageAvailable(<span class="hljs-string">"producer"</span>)<br/>
              {'  '}<span class="hljs-keyword">end</span><br/>
          </div></code></pre>

          <p>This example will check if the client <code>producer</code> has sent messages in each <code>Update()</code> iteration. And if there's message available, the example calls <code>NoahCommReadStr()</code> function to retrieve the message.</p>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab4Page;
