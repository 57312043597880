import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIBasicImagePage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Image APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#noahsetimage">NoahSetImage</a></li>
              <li><a href="#noahgetimage">NoahGetImage</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahsetimage">NoahSetImage</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetImage(string target, string image)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>image</em> to <em>target</em>, which is a <strong>Image2D</strong> or <strong>Image3D</strong> element.</p>
          <p>This function takes effect on only <strong>Image2D</strong> and <strong>Image3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Model3D</strong> node.</li>
          <li><strong><code>image</code> (string):</strong> The path of image file.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To let node &quot;sample_model&quot; use &quot;image/hotairballoon.jpg&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetImage(<span class="hljs-string">"sample_model"</span>, <span class="hljs-string">"image/hotairballoon.jpg"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetimage">NoahGetImage</a> - Get image path current used.</li>
          </ul>
          <hr />
          <h3 id="noahgetimage">NoahGetImage</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetImage(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the image path of <em>target</em>, which is a <strong>Image2D</strong> or <strong>Image3D</strong> element.</p>
          <p>This function takes effect on only <strong>Image2D</strong> and <strong>Image3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns an empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Image2D</strong> or <strong>Image3D</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return a string, which is image path of the node target.</p>
          <h4 id="example">Example</h4>
          <p>To get image path of node &quot;sample_model&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> <span class="hljs-built_in">path</span> = NoahGetModel(<span class="hljs-string">"sample_model"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"Image path : "</span> .. <span class="hljs-built_in">path</span>)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Image path : image/hotairballoon.jpg
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetimage">NoahSetImage</a> - Set image to node.</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIBasicImagePage;
