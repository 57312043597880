import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
import btnStep6 from './image/btn_step_6.png';
import btnStep11 from './image/btn_step_11.png';
/*eslint-disable */

function NoahTutorialLab3Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab3_title"> Lab3 Button and Slider</h1>
          <h1 id="button">Button</h1>
          <p>In this section, we’ll talk about how to use buttons to switch between different pages in order to learn how buttons work.</p>
          <h2 id="instructions-to-add-a-button">Instructions to Add a Button</h2>
          <ol>
            <li>
              <p>In the main scene, add a Button tag in a menu group after the Quad2D for background:</p>
              <pre class="hljs hljs-content"><code><div>
                <span class="hljs-tag">&lt;<span class="hljs-name">Group2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"menu"</span> <span class="hljs-attr">enabled</span>=<span class="hljs-string">"1"</span>&gt;</span><br />
                {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Button</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".icon_ADAS"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"240, 240"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">callback</span>=<span class="hljs-string">"EnterPageADAS"</span> <span class="hljs-attr">appearance</span>=<span class="hljs-string">"ui/btn_ADAS.yml"</span>/&gt;</span><br />
                <span class="hljs-tag">&lt;/<span class="hljs-name">Group2D</span>&gt;</span>
              </div></code></pre>
              <ul>
                <li>A button was defined with the <code>appearance</code> attribute set to <code>ui/btn_ADAS.yml</code>, giving it the visual style defined in the UI template ui/btn_ADAS.yml.</li>
                <li>The <code>callback</code> attribute was set to <code>EnterPageADAS</code>, ensuring that the corresponding Lua function is invoked when a user interacts with the button.</li>
              </ul>
            </li>
            
            <li>
              <p>Run the project and check the modified scene in a terminal. You’ll see error telling that Noah cannot find the UI template <code>ui/btn_ADAS.yml</code> since it’s not yet defined. Error message:</p>
              <pre class="hljs hljs-content"><code><div>
                terminate called after throwing an instance of 'YAML::BadFile'
                what():  bad file: demo_UI_components/ui/btn_ADAS.yml
              </div></code></pre>
            </li>

            <li>
              <p>Create a directory named <code>demo_UI_components/ui</code>.</p>
              <pre class="hljs hljs-content"><code><div>
                $ mkdir -p demo_UI_components/ui
              </div></code></pre>
            </li>

            <li>
              <p>In the <code>demo_UI_components/ui/</code> directory, create a UI template file named <code>btn_ADAS.yml</code> to define the visual of a button for opening the ADAS page:</p>
            </li>
          </ol>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-string">%YAML</span> <span class="hljs-number">1.2</span><br />

            <span class="hljs-attr">texture:</span> <span class="hljs-string">"image/icon_ADAS.png"</span><br />
            <span class="hljs-attr">state:</span><br />
              {'  '}<span class="hljs-attr">normal:</span><br />
              {'  '}{'  '}<span class="hljs-attr">duration:</span> <span class="hljs-number">0.1</span><br />
              {'  '}{'  '}<span class="hljs-attr">texture:</span> <span class="hljs-string">"image/icon_ADAS.png"</span><br />
              {'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
              {'  '}{'  '}<span class="hljs-attr">s:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
              {'  '}{'  '}<span class="hljs-attr">t:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
              {'  '}<span class="hljs-attr">press:</span><br />
              {'  '}{'  '}<span class="hljs-attr">duration:</span> <span class="hljs-number">0.1</span><br />
              {'  '}{'  '}<span class="hljs-attr">texture:</span> <span class="hljs-string">"image/icon_ADAS.png"</span><br />
              {'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.5,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.9</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
              {'  '}{'  '}<span class="hljs-attr">s:</span> <span class="hljs-string">[1.2,</span> <span class="hljs-number">1.2</span><span class="hljs-string">,</span> <span class="hljs-number">1.2</span><span class="hljs-string">]</span><br />
              {'  '}{'  '}<span class="hljs-attr">t:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
              {'  '}<span class="hljs-attr">disable:</span><br />
              {'  '}{'  '}<span class="hljs-attr">duration:</span> <span class="hljs-number">0.1</span><br />
              {'  '}{'  '}<span class="hljs-attr">texture:</span> <span class="hljs-string">"image/icon_ADAS.png"</span><br />
              {'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.5,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
              {'  '}{'  '}<span class="hljs-attr">s:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
              {'  '}{'  '}<span class="hljs-attr">t:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
          </div></code></pre>

          <ol start="5">
            <li>
              <p>Copy the image <code>icon_ADAS.png</code> for the visual of an ADAS button in directory <code>demo_ui_components/image/</code> according to the UI template <code>btn_ADAS.yml</code></p>
            </li>

            <li>
              <p>Check the scene again after adding the UI template for ADAS button:</p>
              <ul>
                <li>A scene with an ADAS button will be displayed, as shown in the image below:
                        <img src={ btnStep6} alt="alt text" width={640}/></li>
              </ul>
            </li>

            <li>
              <p>Tap on the ADAS button, and you will see an error telling that there is no Lua function named <code>EnterPageADAS</code>. Error message:</p>
              <pre class="hljs hljs-content"><code><div>
                Error message: [string &quot;EnterPageADAS()&quot;]:1: attempt to call a nil value (global 'EnterPageADAS')
              </div></code></pre>
            </li>

            <li>
              <p>Create a Lua script named <code>state.lua</code> in directory <code>demo_UI_components/script</code> with the following content to switch between the ADAS page and the main page containing the main menu:</p>
              <pre class="hljs hljs-content"><code><div>
                current_page = <span class="hljs-string">"menu"</span><br /><br />

                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">GoToPage</span><span class="hljs-params">(page)</span></span><br />
                {'  '}{'  '}NoahSetEnabled(<span class="hljs-string">"menu"</span>, page == <span class="hljs-string">"menu"</span>)<br />
                {'  '}{'  '}NoahSetEnabled(<span class="hljs-string">"page_ADAS"</span>, page == <span class="hljs-string">"page_ADAS"</span>)<br />
                {'  '}{'  '}current_page = page<br />
                <span class="hljs-keyword">end</span><br /><br />

                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">EnterPageADAS</span><span class="hljs-params">(e)</span></span> <br />
                {'  '}{'  '}GoToPage(<span class="hljs-string">"page_ADAS"</span>)<br />
                <span class="hljs-keyword">end</span>
              </div></code></pre>
              <ul>
                <li>In the <code>EnterPageADAS()</code>, it calls <code>GoToPage()</code> to show only the ADAS page and hide all pages that’s not the current_page using <code>NoahSetEnabled()</code>, which is a Noah API used to set the <code>enabled</code> attribute of a Noah element.</li>
                <li>By calling <code>NoahSetEnabled(string target, bool enabled)</code>, you can enable or disable the specified <em>target</em> element.</li>
                <li><strong><em>Note:</em></strong> You have to add the <code>require(&quot;state&quot;)</code> in the main script <code>script.lua</code> as well to make <code>state.lua</code> works</li>
              </ul>
            </li>

            <li>
              <p>Tap on the ADAS button triggers a callback function, which disables the group with the id <code>.menu</code>, and the ADAS button will be invisible because it’s disabled accordingly.</p>
            </li>
          </ol>

          <h2 id="complete-the-pages">Complete the Pages</h2>
          <p>Let’s complete the menu with tree different pages: main page, ADAS page, as well as the A/C page for air conditioner control.</p>
          <ul>
            <li>
              <p>First, create an UI template <code>btn_AC.yml</code> with textures set to <code>image/icon_AC.png</code></p>
              <ul>
                <li>This can be achieve by creating a copy of <code>btn_ADAS.yml</code>, rename it to <code>btn_AC.yml</code>, and then set the texture property to <code>image/icon_AC.png</code></li>
              </ul>
            </li>

            <li>
              <p>Next, create an UI template <code>btn_home.yml</code> with textures set to <code>image/home.png</code>. This can also be achieve by creating a copy of other YAML files.</p>
            </li>

            <li>
              <p>Then, provide a complete set of dynamic behavior using Lua functions for switch between different pages (menu / page_ADAS / page_AC) in <code>demo_UI_components/script/state.lua</code></p>
              <pre class="hljs hljs-content"><code><div>current_page = <span class="hljs-string">"menu"</span><br />
              
                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">GoToPage</span><span class="hljs-params">(page)</span></span><br />
                    {'  '}NoahSetEnabled(<span class="hljs-string">"menu"</span>, page == <span class="hljs-string">"menu"</span>)<br />
                    {'  '}NoahSetEnabled(<span class="hljs-string">"page_ADAS"</span>, page == <span class="hljs-string">"page_ADAS"</span>)<br />
                    {'  '}NoahSetEnabled(<span class="hljs-string">"page_AC"</span>, page == <span class="hljs-string">"page_AC"</span>)<br />
                    {'  '}current_page = page<br />
                <span class="hljs-keyword">end</span><br /><br />

                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">EnterPageADAS</span><span class="hljs-params">(e)</span></span> <br />
                        {'  '}GoToPage(<span class="hljs-string">"page_ADAS"</span>)<br />
                <span class="hljs-keyword">end</span><br /><br />

                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">EnterPageAC</span><span class="hljs-params">(e)</span></span><br />
                    {'  '}GoToPage(<span class="hljs-string">"page_AC"</span>) <br />
                <span class="hljs-keyword">end</span><br /><br />

                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">BackToMenu</span><span class="hljs-params">(e)</span></span><br />
                    {'  '}GoToPage(<span class="hljs-string">"menu"</span>) <br />
                <span class="hljs-keyword">end</span><br /><br />
              </div></code></pre>
            </li>
          </ul>

          <p>Once the Lua functions and UI templates for buttons are defined, we can add buttons to switch between different pages. Replace the content of main scene <code>main.xml</code> with the following content:</p>
          <pre class="hljs hljs-content"><code><div><span class="hljs-meta">&lt;?xml version="1.0" encoding="UTF-8"?&gt;</span><br />

            <span class="hljs-tag">&lt;<span class="hljs-name">Scene</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"main"</span> <span class="hljs-attr">sizepx</span>=<span class="hljs-string">"1920, 720"</span> <span class="hljs-attr">sizemm</span>=<span class="hljs-string">"346, 130"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">eyepos</span>=<span class="hljs-string">"0, 90, 650"</span>&gt;</span><br />
              {'  '}<br />
              {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Quad2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"background"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"0, 0"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"1920"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"720"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0, 0"</span> <span class="hljs-attr">color</span>=<span class="hljs-string">"#ff404040 #ff404040 #ff202020 #ff181818"</span>/&gt;</span> <br />
              {'  '}<br />
              {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Group2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"menu"</span> <span class="hljs-attr">enabled</span>=<span class="hljs-string">"1"</span>&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Button</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".icon_ADAS"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"240, 240"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">callback</span>=<span class="hljs-string">"EnterPageADAS"</span> <span class="hljs-attr">appearance</span>=<span class="hljs-string">"ui/btn_ADAS.yml"</span>/&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Button</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".icon_AC"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"480, 240"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">callback</span>=<span class="hljs-string">"EnterPageAC"</span> <span class="hljs-attr">appearance</span>=<span class="hljs-string">"ui/btn_AC.yml"</span>/&gt;</span><br />
              {'  '}<span class="hljs-tag">&lt;/<span class="hljs-name">Group2D</span>&gt;</span><br />
              {'  '}<br />
              {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Group2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"page_ADAS"</span> <span class="hljs-attr">enabled</span>=<span class="hljs-string">"0"</span>&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Image2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".camera"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"480, 380"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"640"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"480"</span> <span class="hljs-attr">src</span>=<span class="hljs-string">"external:camera_A"</span>/&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Text2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".msg"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"840, 140"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"48"</span> <span class="hljs-attr">text</span>=<span class="hljs-string">""</span> <span class="hljs-attr">font</span>=<span class="hljs-string">"Montserrat_MEDIUM"</span>/&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Text2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".title"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"840, 60"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"48"</span> <span class="hljs-attr">font</span>=<span class="hljs-string">"Montserrat_MEDIUM"</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"ADAS"</span>/&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Button</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".btn_home"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"1860, 60"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"64"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"64"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">callback</span>=<span class="hljs-string">"BackToMenu"</span> <span class="hljs-attr">appearance</span>=<span class="hljs-string">"ui/btn_home.yml"</span>/&gt;</span><br />
              {'  '}<span class="hljs-tag">&lt;/<span class="hljs-name">Group2D</span>&gt;</span><br />
              {'  '}<br />
              {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Group2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"page_AC"</span> <span class="hljs-attr">enabled</span>=<span class="hljs-string">"0"</span>&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Text2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".title"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"840, 60"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"48"</span> <span class="hljs-attr">font</span>=<span class="hljs-string">"Montserrat_MEDIUM"</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"A/C Control"</span>/&gt;</span><br />
              {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Button</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".btn_home"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"1860, 60"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"64"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"64"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">callback</span>=<span class="hljs-string">"BackToMenu"</span> <span class="hljs-attr">appearance</span>=<span class="hljs-string">"ui/btn_home.yml"</span>/&gt;</span><br />
              {'  '}<span class="hljs-tag">&lt;/<span class="hljs-name">Group2D</span>&gt;</span><br />
              {'  '}<br />
              <span class="hljs-tag">&lt;/<span class="hljs-name">Scene</span>&gt;</span><br />
          </div></code></pre><br />

          <p>Page <code>page_ADAS</code> and <code>page_AC</code> were added with additional <code>Button</code> instances triggering the switch between pages. The Button with id <code>.icon_AC</code> will trigger <code>EnterPageAC()</code> when tapped, and Button with id <code>.btn_home</code> will trigger <code>BackToMenu()</code> according to callback attributes in main scene.</p>
          <p>And the appearance attributes select different UI templates (*.yml) for the visual effects of the Buttons.</p>
          <p>Now we can heck the scene again by running <code>noah_theatre</code>. You can switch between the main menu, the ADAS page, and the A/C pages by taping on the buttons now.</p>
          <p><img src={btnStep11} alt="alt text"/></p>
          <h2 id="conclusion">Conclusion</h2>
          <p>Let’s review the basic workflow of adding a Button:</p>
          <ol>
            <li>Define a UI template for Button</li>
            <li>Define a Lua function for dynamic behavior</li>
            <li>Add a Button element in a scene, with appearance set to an UI template, and callback set to a Lua function.</li>
          </ol>

          <p>The same workflow applies to all kinds of UI components. It would be easier to learn the next UI component once you are familiar with the workflow. You can find the project created by going through this section in the <code>project/demo_UI_components/</code> directory included in the demo_project package.</p>
          <p>For advance usage, a <code>Button</code> component is always in one of the three states: the <strong>normal</strong>, <strong>press</strong>, and the <strong>disable</strong> state:</p>
          
          <ul>
            <li>The <code>normal</code> state is the regular state that it’s not pressed.</li>
            <li>The <code>press</code> state is the state when you are taping on it.</li>
            <li>The <code>disable</code> state is the state when an UI component was disabled.</li>
          </ul>

          <p>You can define different appearances for different states in YAML file. Available attributes for each state are as follows:</p>
          <ul>
            <li><code>s</code> - for scale</li>
            <li><code>t</code> - for translate transform</li>
            <li><code>texture</code> - for image texture</li>
            <li><code>color</code> - for color weights, and</li>
            <li><code>duration</code> - for the duration of animation displayed when entering the state.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab3Page;
