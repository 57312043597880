import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab4Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab4_title">Lab4 Communication</h1>
          <h3 id="before-you-begin">Before you begin</h3>
          <p>Upon completing this tutorial, you'll learn how to establish communication with host and other clients, and how to publish or subscribe data from the host.</p>
          <h2 id="prerequisites">Prerequisites</h2>
          <ul>
            <li>Complete Lab 3.</li>
          </ul>

          <h2 id="what-youll-learn">What you’ll learn</h2>
          <ul>
            <li>How to establish communication with host</li>
            <li>How to subscribe data from the host</li>
            <li>How to publish data to the host</li>
            <li>How to send or read messages to/from other client</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab4Page;
