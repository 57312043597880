import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
import sliderStep5 from './image/slider_step_5.png';
import sliderStep9 from './image/slider_step_9.png';
import sliderStep10 from './image/slider_step_10.png';
import sliderAsset from './image/slider_asset.png';
import sliderVisual from './image/slider_visual.png';
/*eslint-disable */

function NoahTutorialLab3Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab3_title"> Lab3 Button and Slider</h1>
          <h1 id="slider">Slider</h1>
          <p>In this section, we’ll focus on adding a slider for temperature control in the air conditioner (A/C) control page.</p>
          <h2 id="instructions-to-add-a-slider">Instructions to Add a Slider</h2>
          <ol>
            <li>
              <p>Continue from the previos section, open the main scene <code>main.xml</code> in directory <code>project/demo_UI_components</code>, and add a <code>Slider</code> as well as a <code>Text2D</code> element for displaying the target temperature in the <code>page_AC</code> group:</p>
              <pre class="hljs hljs-content"><code><div><span class="hljs-tag">&lt;<span class="hljs-name">Slider</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".temperature_slider"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"960, 400"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0"</span> <span class="hljs-attr">callback</span>=<span class="hljs-string">"AC_OnSlider"</span> <span class="hljs-attr">appearance</span>=<span class="hljs-string">"ui/slider.yml"</span>/&gt;</span> 
                <span class="hljs-tag">&lt;<span class="hljs-name">Text2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">".temperature_info"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"900, 300"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"54"</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"test"</span> <span class="hljs-attr">font</span>=<span class="hljs-string">"Montserrat_MEDIUM"</span>/&gt;</span>
              </div></code></pre>
            </li>

            <li>
              <p>Check the scene by running the <code>noah_theatre</code>. You’ll see an error telling that Noah cannot find the UI template <code>ui/btn_ADAS.yml</code> since it’s not yet defined.</p>
              <pre class="hljs hljs-content"><code><div>terminate called after throwing an instance of 'YAML::BadFile'
              what():  bad file: demo_UI_components/ui/slider.yml
              </div></code></pre>
            </li>

            <li>
              <p>In the <code>demo_UI_components/ui/</code> directory, create a UI template file named <code>slider.yml</code> with the following content:</p>
            </li>
          </ol>

          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-string">%YAML</span> <span class="hljs-number">1.2</span><br />
            {'  '}<br />
            {'  '}<span class="hljs-attr">start_pos:</span> <span class="hljs-number">-240.0</span><br />
            {'  '}<span class="hljs-attr">end_pos:</span> <span class="hljs-number">240.0</span><br />
            {'  '}<span class="hljs-attr">behavior:</span><br />
            {'  '}{'  '}<span class="hljs-attr">type:</span> <span class="hljs-string">"NORMAL"</span><br />
            {'  '}<span class="hljs-attr">nobe:</span> <br />
            {'  '}{'  '}<span class="hljs-attr">offset:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}<span class="hljs-attr">texture:</span> <span class="hljs-string">"image/nobe.png"</span><br />
            {'  '}{'  '}<span class="hljs-attr">state:</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">normal:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.5</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">1.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">disable:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.5,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}<span class="hljs-attr">fill:</span><br />
            {'  '}{'  '}<span class="hljs-attr">offset:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}<span class="hljs-attr">texture:</span> <span class="hljs-string">"image/slider_progress.png"</span><br />
            {'  '}{'  '}<span class="hljs-attr">slice:</span> <span class="hljs-string">[10.0,</span> <span class="hljs-number">10.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}<span class="hljs-attr">size:</span> <span class="hljs-string">[250.0,</span> <span class="hljs-number">30.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}<span class="hljs-attr">state:</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">normal:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.5</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">1.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">disable:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.5,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}<span class="hljs-attr">foreground:</span><br />
            {'  '}  <span class="hljs-attr">offset:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
            {'  '}  <span class="hljs-attr">texture:</span> <span class="hljs-string">"image/slider_fg.png"</span><br />
            {'  '}  <span class="hljs-attr">slice:</span> <span class="hljs-string">[12.0,</span> <span class="hljs-number">12.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
            {'  '}  <span class="hljs-attr">size:</span> <span class="hljs-string">[256.0,</span> <span class="hljs-number">30.0</span><span class="hljs-string">]</span><br />
            {'  '}  <span class="hljs-attr">state:</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">normal:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.5</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">1.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">disable:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.5,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}<span class="hljs-attr">background:</span><br />
            {'  '}{'  '}<span class="hljs-attr">offset:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}<span class="hljs-attr">texture:</span> <span class="hljs-string">"image/slider_bg.png"</span><br />
            {'  '}{'  '}<span class="hljs-attr">slice:</span> <span class="hljs-string">[12.0,</span> <span class="hljs-number">12.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}<span class="hljs-attr">size:</span> <span class="hljs-string">[256.0,</span> <span class="hljs-number">30.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}<span class="hljs-attr">state:</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">normal:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.5</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">1.0</span><br />
            {'  '}{'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
            {'  '}{'  '}{'  '}<span class="hljs-attr">disable:</span><br />
            {'  '}{'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.5,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br />
          </div></code></pre>

          <ol start="4">
            <li>
              <p>Provide the following image files referenced in <code>slider.yml</code> for visual of sliders. You can find these images in the <code>demo_ui_components/image</code> directory:</p>
              <ul>
                <li>ui/nobe.png</li>
                <li>ui/slider_bg.png</li>
                <li>ui/slider_fg.png</li>
                <li>ui/slider_progress.png</li>
              </ul>
            </li>

            <li>
              <p>Run the noah_theatre again. Tap on the A/C button to switch to <code>page_AC</code>, and you will see a slider displayed in the A/C control page, as shown in the following image:</p>
              <p><img src={sliderStep5} alt="alt text" width={720}/></p>
            </li>

            <li>
              <p>Drag the indicator of the slider, and Noah will show an error telling that the Lua function <code>AC_OnSlider()</code> referenced by the <code>callback</code> attribute hasn’t been defined yet, as shown below:</p>
              <pre class="hljs hljs-content"><code><div>
                Error message: [string &quot;AC_OnSlider(1.000000)&quot;]:1: attempt to call a nil value (global 'AC_OnSlider')
              </div></code></pre>
            </li>

            <li>
              <p>Add script file <code>page_AC.lua</code> in directory <code>demo_UI_components/script</code> with the following content:</p>
              <pre class="hljs hljs-content"><code><div>
                <span class="hljs-keyword">local</span> ac_temperature = <span class="hljs-number">30.0</span><br />
                <span class="hljs-keyword">local</span> is_temperature_updated = <span class="hljs-literal">true</span><br /><br />

                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">AC_OnSlider</span><span class="hljs-params">(f)</span></span><br />
                {'  '}ac_temperature = <span class="hljs-number">16</span> + f*<span class="hljs-number">14</span><br />
                {'  '}is_temperature_updated = <span class="hljs-literal">true</span><br />
                <span class="hljs-keyword">end</span><br /><br />

                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">UpdatePageAC</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}<span class="hljs-keyword">if</span> is_temperature_updated <span class="hljs-keyword">then</span><br />
                {'  '}{'  '}is_temperature_updated = <span class="hljs-literal">false</span><br />
                {'  '}{'  '}d = <span class="hljs-built_in">math</span>.<span class="hljs-built_in">floor</span>(ac_temperature)<br />
                {'  '}{'  '}NoahSetText(<span class="hljs-string">"page_AC.temperature_info"</span>, <span class="hljs-built_in">tostring</span>(d)..<span class="hljs-string">" °C"</span>)<br />
                {'  '}<span class="hljs-keyword">end</span><br />
                <span class="hljs-keyword">end</span>
              </div></code></pre>

              <ul>
                <li>In the Lua script <code>page_AC.lua</code>, a variable <code>ac_temperature</code> was defined as the target temperature with default value 30.0 in Celsius.</li>
                <li>When a user’s dragging the slider and the slider received the event for value change, according to the callback attribute <code>callback=&quot;AC_OnSlider&quot;</code> in main scene (<code>main.xml</code>), the function <code>AC_OnSlider()</code> will be called. The variable <code>ac_temperature</code> will keep the latest target temperature calculated.</li>
              </ul>
            </li>

            <li>
              <p>The final thing to do is to update the text for displaying target temperature by calling <code>UpdatePageAC()</code> in function <code>Update()</code> defined in the main script, <code>script/script.lua</code>. Replace the function <code>Update()</code> with the following content:</p>
              <pre class="hljs hljs-content"><code><div>
                <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}UpdatePageAC(interval)<br />
                <span class="hljs-keyword">end</span><br />
              </div></code></pre>

              <ul>
                <li>And then reference to the page_AC.lua in the main script as follows:</li>
              </ul>

              <pre class="hljs hljs-content"><code><div>
                <span class="hljs-built_in">require</span>(<span class="hljs-string">"page_AC"</span>)
              </div></code></pre>
            </li>

            <li>
              <p>Check the scene again by running the noah_theatre:</p>
              <ul>
                <li>After entered the page for A/C control by taping on the A/C button, you will see a Slider with a Text2D displaying the current target temperature (30 °C by default) in Celsius, as illustrated in the following image</li>
              </ul>
              <p><img src={sliderStep9} alt="alt text" width={720}/></p>
            </li>

            <li>
              <p>When you drag the indicator of the slider to the left-most position, you’ll see the Slider is blue, and the <code>Text2D</code> displays the minimum target temperature 16 °C.</p>
              <p><img src={sliderStep10} alt="alt text" width={720}/></p>
            </li>
          </ol>

          <h2 id="conclusion">Conclusion</h2>
          <p>Let’s review the workflow of adding a Slider:</p>
          <ul>
            <li>Define a UI template for Slider</li>
            <li>Define a Lua function for dynamic behavior (the AC_OnSlider in the project ivi)</li>
            <li>Add a Slider element in a scene, with appearance set to an UI template, and callback set to a Lua function.</li>
          </ul>

          <p>The workflow of adding a Slider is similar to the workflow of adding a Button. The only difference is that in the callback for a Slider, there is a value <code>f</code> passed to the callback to represent the current value of a <code>Slider</code> component. The value <code>f</code> ranges from 0 to 1 so that you can convert it easily.</p>
          <p>To convert the value <code>f</code> into a custom range between <code>min_value</code> and <code>max_value</code>, you can use the following expression:
          <code>    target_value = min_value + f * (max_value - min_value)    </code></p>
          <p>For advance use, a UI template for Slider Component consists of the following parts:</p>
          <ul>
            <li><code>start_pos</code> – the start position of a slider in pixels</li>
            <li><code>end_pos</code> – the end position of a slider in pixels</li>
            <li><code>behavior</code> – a reserved attribute, and must have type: &quot;NORMAL&quot; as the child node to make slider work</li>
            <li><code>nobe</code> – the visual effect of the indicator corresponds to the value of a slider</li>
            <li><code>fill</code> – the visual effect of the slider bar corresponds to the value of a slider</li>
            <li><code>background</code> – the visual effect of the background overlay of slider bar corresponds to the value of a slider</li>
            <li><code>foreground</code> - the visual effect of the foreground overlay of slider bar corresponds to the value of a slider</li>
          </ul>

          <p>The following image shows the different parts of a slider separately:</p>
          <p><img src={sliderAsset} alt="alt text"/></p>
          <p>The individual slider parts are rendered in the following order:</p>
          <ol>
            <li>background</li>
            <li>fill</li>
            <li>foreground</li>
            <li>nobe</li>
          </ol>

          <p>And the one in charge of preparing the UI Template or images for a <code>Slider</code> should be aware of this rendering order to have a correct visual of a slider.</p>
          <ul>
            <li>The <code>nobe</code> part would be drawn in a location according to the current slider value.</li>
            <li>The <code>fill</code> part will cover a smaller area than the <code>background</code> part when the current slider value is less than 1.</li>
          </ul>

          <p>The final visual effect of the slider formed is as follows:</p>
          <p><img src={sliderVisual} alt="alt text" width={720}/></p>
          <p>For each individual slider parts mentioned above, the following attributes can be defined for customized visual:</p>
          <ul>
            <li><code>offset</code> – 2D translate transform for offset from default location of the slider</li>
            <li><code>texture</code> – the image texture used for the individual slider part</li>
            <li><code>state</code> - the weights of the color applied according to the current value of the slider and whether the slider is disabled or not. The state node can have the following attributes:</li>
            <li><code>disable</code> – the weights of the color applied when a slider is disabled</li>
            <li><code>normal</code> – a list of the visual states of a slider identified by a referenced slider value</li>
          </ul>

          <p>A slider visual state of a slider must have the following attributes:</p>
          <ul>
            <li><code>value</code> – the reference value when calculating the interpolated color</li>
            <li><code>color</code> – the color to be applied when the current value is close to the value attribute.</li>
          </ul>

          <p>For example, the following attributes define a blue slider at value 0, with light blue as the color at value 0.5, green as the color at value 1 and with a gray color when the slider is disabled.</p>
          <pre class="hljs hljs-content"><code><div>
            {'  '}<span class="hljs-attr">normal:</span><br />
            {'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.0</span><br/>
            {'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br/>
            {'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">0.5</span><br/>
            {'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[1.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br/>
            {'  '}{'  '}<span class="hljs-bullet">-</span> <span class="hljs-attr">value:</span> <span class="hljs-number">1.0</span><br/>
            {'  '}{'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.0,</span> <span class="hljs-number">1.0</span><span class="hljs-string">,</span> <span class="hljs-number">0.0</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br/>
            {'  '}<span class="hljs-attr">disable:</span><br/>
            {'  '}{'  '}<span class="hljs-attr">color:</span> <span class="hljs-string">[0.5,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">0.5</span><span class="hljs-string">,</span> <span class="hljs-number">1.0</span><span class="hljs-string">]</span><br/>
          </div></code></pre>

          <p>You can find the project created by going through this section in the <code>project/demo_UI_components/</code> directory included in the lab package.</p>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab3Page;
