import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function OmniAdasPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>ADAS (Advanced Driver-Assistance Systems)</h1>
          <h2>Introduction</h2>
          <p>
            Advanced Driver-Assistance Systems (ADAS) are a suite of safety
            technologies designed to improve driving safety and efficiency. ADAS
            leverages a blend of sensors, cameras, and artificial intelligence
            to assist drivers in navigating and responding to road conditions
            and traffic environments.
          </p>
          <h2>Key Features and Functions</h2>
          <h3>TTC(Time To Collision/Forward Collision Warning):</h3>
          <p>
            The TTC, or Time To Collision/Forward Collision Warning, is an
            advanced safety feature in our ADAS. It alerts drivers to potential
            forward collisions, providing crucial reaction time to avoid or
            mitigate accidents. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Collision Risk Alert:</strong> The system calculates the
              time to collision with objects ahead and issues warnings when a
              potential collision is detected, enhancing driver awareness and
              response time.
            </li>
            <li>
              <strong>Proactive Safety Measure:</strong> By alerting drivers
              early to potential forward collisions, TTC acts as a proactive
              measure to prevent accidents and enhance road safety.
            </li>
          </ul>
          <h3>HMW (Headway Monitoring Warning)</h3>
          <p>
            The HMW, or Headway Monitoring Warning, feature of our ADAS helps
            maintain safe following distances. It alerts drivers if the headway,
            or distance to the vehicle ahead, is too short. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Safe Distance Monitoring:</strong> The system continuously
              monitors the distance to the vehicle ahead and provides alerts if
              the headway falls below a safe threshold, encouraging safe driving
              practices.
            </li>
            <li>
              <strong>Enhancing Traffic Safety:</strong> By ensuring drivers
              maintain a safe following distance, HMW contributes to reducing
              rear-end collisions and improving overall traffic safety.
            </li>
          </ul>
          <h3>LDWS(Lane Departure Warning):</h3>
          <p>
            The LDWS, or Lane Departure Warning System, is a critical feature in
            our ADAS that alerts drivers when they unintentionally deviate from
            their lane. This feature is key in preventing accidents caused by
            lane drift. Key aspects include:
          </p>
          <ul>
            <li>
              <strong>Lane Deviation Alerts:</strong> The system monitors lane
              markings and alerts the driver if the vehicle begins to drift out
              of its lane without a turn signal, aiding in maintaining lane
              discipline.
            </li>
            <li>
              <strong>Reducing Lane-Drift Accidents:</strong> By alerting
              drivers to unintentional lane departures, LDWS plays a significant
              role in reducing accidents caused by lane drift, especially on
              highways and during long drives.
            </li>
          </ul>
          <h3>Sensor Fusion in TTC, HMW, and LDW Systems</h3>
          <p>
            Sensor Fusion is a sophisticated technology implemented in our TTC
            (Time To Collision), HMW (Headway Monitoring Warning), and LDW (Lane
            Departure Warning) systems. It intelligently integrates data from
            multiple sensors to enhance accuracy and reliability of the alerts.
            Key aspects of Sensor Fusion in these systems include:
          </p>
          <ul>
            <li>
              <strong>TTC/HMW Sensor Fusion:</strong> For the TTC and HMW,
              Sensor Fusion combines data on velocity and acceleration. Alerts
              for potential collisions or unsafe headway are issued based on
              these parameters. If the vehicle&apos;s velocity is too low, or if
              deceleration is detected, the system intelligently refrains from
              triggering an alert, preventing unnecessary warnings.
            </li>
            <li>
              <strong>LDW Sensor Fusion:</strong> The LDW system utilizes Sensor
              Fusion to identify unintentional lane departures. The system only
              triggers alerts when it detects potential unintentional lane
              crossing, such as during drowsiness or distraction, and the
              vehicle is above a certain speed threshold. This approach ensures
              that alerts are contextually appropriate and relevant,
              significantly enhancing driver safety.
            </li>
          </ul>
          <p>
            By employing Sensor Fusion in these safety systems, we ensure that
            the alerts are not only accurate but also contextually appropriate,
            enhancing the effectiveness of our DMS in promoting safer driving
            conditions.
          </p>
          <h2>Settings</h2>
          <p>
            You can configure ADAS features by navigating to OMNI → Main Menu →
            ADAS Settings.
          </p>
          <h3>Enabling:</h3>
          <p>
            You possess the flexibility to activate or deactivate alerts for
            each specific type of alarm. Notably, even when an alarm is muted,
            the corresponding event will continue to be uploaded to the cloud.
          </p>
          <h3>Threshold:</h3>
          <p>
            Adjust the sensitivity settings of each ADAS feature to suit your
            driving style and comfort.
          </p>
          <h3>Blinker:</h3>
          <p>
            The Lane Departure Warning System (LDWS) incorporates enhanced
            detection capabilities, which can be integrated with the
            driver&apos;s status or the vehicle&apos;s turn signal. When the
            blinker option is activated, the LDWS will issue alerts every time
            the driver changes lanes without using the directional blinker. If
            this feature is deactivated, LDWS activation will be contingent
            solely upon instances where the driver appears inattentive and
            simultaneously crosses lane markings.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OmniAdasPage;
