import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIAnimationPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Animation APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a></li>
              <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a></li>
              <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a></li>
              <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a></li>
              <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a></li>
              <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a></li>
              <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a></li>
              <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a></li>
              <li><a href="#noahanimationstop">NoahAnimationStop</a></li>
              <li><a href="#noahanimationstopall">NoahAnimationStopAll</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahanimationtranslateto">NoahAnimationTranslateTo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationTranslateTo(string target, number x, number y, number z, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize a moving animation for <em>target</em> to the <em>x, y, z</em> position in milliseconds. The animation will start after a delay of <em>countdown</em> milliseconds and last for <em>duration</em> milliseconds. The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>Legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>. If <em>target</em> is not a <strong>3D element</strong>, the <em>z</em> value is ignored.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>x</code> (number):</strong> The target x-coordinate position.</li>
          <li><strong><code>y</code> (number):</strong> The target y-coordinate position.</li>
          <li><strong><code>z</code> (number):</strong> The target z-coordinate position.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To move node &quot;anime_image&quot; to (640, 480, 0) in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationTranslateTo(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">640</span>, <span class="hljs-number">480</span>, <span class="hljs-number">0</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationtranslateby">NoahAnimationTranslateBy</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationTranslateBy(string target, number x, number y, number z, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize a moving animation for <em>target</em> with a offset of <em>x, y, z</em> values in milliseconds. The animation will start after a delay of <em>countdown</em> milliseconds and last for <em>duration</em> milliseconds.The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>Legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>. If <em>target</em> is not a <strong>3D element</strong>, the <em>z</em> value is ignored.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>x</code> (number):</strong> The target x-coordinate offset.</li>
          <li><strong><code>y</code> (number):</strong> The target y-coordinate offset.</li>
          <li><strong><code>z</code> (number):</strong> The target z-coordinate offset.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To move node &quot;anime_image&quot; to (current x + 20, current y + 40, current z) in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationTranslateBy(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">20</span>, <span class="hljs-number">40</span>, <span class="hljs-number">0</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationrotateto">NoahAnimationRotateTo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationRotateTo(string target, number x, number y, number z, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize a rotating animation for <em>target</em> to the <strong>Euler angles</strong> specified by <em>x, y, z</em> degrees. The animation will start after a delay of <em>countdown</em> milliseconds and last for <em>duration</em> milliseconds. The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>Legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>. If <em>target</em> is a <strong>2D element</strong>, the <em>x</em> and <em>y</em> values are ignored.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>x</code> (number):</strong> The target x Euler angle in degree.</li>
          <li><strong><code>y</code> (number):</strong> The target y Euler angle in degree.</li>
          <li><strong><code>z</code> (number):</strong> The target z Euler angle in degree.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To rotate node &quot;anime_image&quot; to (90, 45, 0) in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationRotateTo(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">90</span>, <span class="hljs-number">45</span>, <span class="hljs-number">0</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationrotateby">NoahAnimationRotateBy</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationRotateBy(string target, number x, number y, number z, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize a rotating animation for <em>target</em>. The <strong>Euler angles</strong> of <em>target</em> will shift by <em>x, y, z</em> degrees after a delay of <em>countdown</em> milliseconds, and last for <em>duration</em> milliseconds. The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>Legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>. If <em>target</em> is a <strong>2D element</strong>, the <em>x</em> and <em>y</em> values are ignored.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>x</code> (number):</strong> The target x Euler angle offset in degree.</li>
          <li><strong><code>y</code> (number):</strong> The target y Euler angle offset in degree.</li>
          <li><strong><code>z</code> (number):</strong> The target z Euler angle offset in degree.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To rotate node &quot;anime_image&quot; to (current rotate x + 20, current rotate y + 35, 0) in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationRotateBy(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">20</span>, <span class="hljs-number">35</span>, <span class="hljs-number">0</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationscaleto">NoahAnimationScaleTo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationScaleTo(string target, number x, number y, number z, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize a scaling animation for <em>target</em> to end with the scale specified by <em>x, y, z</em>. The animation will start after a delay of <em>countdown</em> milliseconds, an last for <em>duration</em> milliseconds. The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>Legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>. If <em>target</em> is a <strong>2D element</strong>, the <em>z</em> value is ignored.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>x</code> (number):</strong> The target x-coordinate scale.</li>
          <li><strong><code>y</code> (number):</strong> The target y-coordinate scale.</li>
          <li><strong><code>z</code> (number):</strong> The target z-coordinate scale.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To scale node &quot;anime_image&quot; to (2, 2, 1) in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationScaleTo(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">2</span>, <span class="hljs-number">2</span>, <span class="hljs-number">1</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationscaleby">NoahAnimationScaleBy</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationScaleBy(string target, number x, number y, number z, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize a scaling animation for <em>target</em>, which progressively scales by <em>x, y, z</em>. The animation will start after a delay of <em>countdown</em> milliseconds and last for <em>duration</em> milliseconds. The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>The legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>. If <em>target</em> is a <strong>2D element</strong>, the <em>z</em> value is ignored.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>x</code> (number):</strong> The target x-coordinate scale offset.</li>
          <li><strong><code>y</code> (number):</strong> The target y-coordinate scale offset.</li>
          <li><strong><code>z</code> (number):</strong> The target z-coordinate scale offset.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To scale node &quot;anime_image&quot; to (current scale x + 1, current scale y + 1, current scale z) in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationScaleBy(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">1</span>, <span class="hljs-number">1</span>, <span class="hljs-number">0</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationalphato">NoahAnimationAlphaTo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationAlphaTo(string target, number alpha, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize an animation to change the transparency of <em>target</em> progressively. The end transperency is defined by <em>alpha</em>, which is a float number within the range of <strong>0.0</strong> to <strong>1.0</strong>. The animation will start after a delay of <em>countdown</em> milliseconds, and last for <em>duration</em> milliseconds. The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>The legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>alpha</code> (number):</strong> The target opacity.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To let node &quot;anime_image&quot; alpha to 0.0 in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationAlphaTo(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">0.0</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationalphaby">NoahAnimationAlphaBy</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationAlphaBy(string target, number alpha, number duration, number countdown, string curvetype)</code></p>
          <h4 id="description">Description</h4>
          <p>Initialize an animation to change the transparency of <em>target</em> progressively. The shifted value of transperency is defined by <em>alpha</em>, which is a float number within the range of <strong>0.0</strong> to <strong>1.0</strong>. The animation will start after a delay of <em>countdown</em> milliseconds, and last for <em>duration</em> milliseconds. The transition rule of animation is specified by <em>curvetype</em>.</p>
          <p>The legal <em>curvetype</em> values include: <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node.</li>
          <li><strong><code>alpha</code> (number):</strong> The target opacity offset.</li>
          <li><strong><code>duration</code> (number):</strong> The animation duration in milliseconds.</li>
          <li><strong><code>countdown</code> (number):</strong> The animation delay in milliseconds.</li>
          <li><strong><code>curvetype</code> (string):</strong> The curve function of animation, can be <strong>LINEAR</strong>, <strong>SMOOTH</strong>, and <strong>CUBIC_OUT</strong></li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return an number as animation identifier for interrupt.</p>
          <h4 id="example">Example</h4>
          <p>To let node &quot;anime_image&quot; alpha to current alpha + 0.5 in 1000 millisecond with 20 millisecond delay, use function smooth:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationAlphaBy(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationstop">NoahAnimationStop</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationStop(number animation_id)</code></p>
          <h4 id="description">Description</h4>
          <p>Use to stop sepcified animation create before. If animation_id is not valid or ended, Noah will record an error message, and no animation will stop immediately.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>animation_id</code> (number):</strong> The unique identifier of the animation.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To let move animation stop immediate:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> id = NoahAnimationAlphaBy(<span class="hljs-string">"anime_image"</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">1000</span>, <span class="hljs-number">20</span>, <span class="hljs-string">"SMOOTH"</span>)<br />
            NoahAnimationStop(id)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstopall">NoahAnimationStopAll</a> - Stop all animations.</li>
          </ul>
          <hr />
          <h3 id="noahanimationstopall">NoahAnimationStopAll</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahAnimationStopAll()</code></p>
          <h4 id="description">Description</h4>
          <p>Use to stop all running animations.</p>
          <h4 id="parameters">Parameters</h4>
          <p>This function has no parameter.</p>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To stop all animations immediate:</p>
          <pre class="hljs hljs-content"><code><div>NoahAnimationStopAll()
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahanimationtranslateto">NoahAnimationTranslateTo</a> - Translate node to position with animation.</li>
          <li><a href="#noahanimationtranslateby">NoahAnimationTranslateBy</a> - Translate node to offset with animation.</li>
          <li><a href="#noahanimationrotateto">NoahAnimationRotateTo</a> - Rotate node to specified Eular angle with animation.</li>
          <li><a href="#noahanimationrotateby">NoahAnimationRotateBy</a> - Rotate node to specified Eular angle offset with animation.</li>
          <li><a href="#noahanimationscaleto">NoahAnimationScaleTo</a> - Scale node to scale with animation.</li>
          <li><a href="#noahanimationscaleby">NoahAnimationScaleBy</a> - Scale node to scale offset with animation.</li>
          <li><a href="#noahanimationalphato">NoahAnimationAlphaTo</a> - Fade node to alpha with animation.</li>
          <li><a href="#noahanimationalphaby">NoahAnimationAlphaBy</a> - Fade node to alpha offset with animation.</li>
          <li><a href="#noahanimationstop">NoahAnimationStop</a> - Stop specified animation.</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIAnimationPage;
