import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
import designbutton from './image/4_figma_1_design_button.png';
import framebutton from './image/4_figma_2_frame_button.png';
import dimension from './image/4_figma_3_dimension.png';
import addbackground from './image/4_figma_4_add_background.png';
import addimage from './image/4_figma_5_add_image.png';
import addtext from './image/4_figma_6_add_text.png';
import addquad from './image/4_figma_7_add_quad.png';
import flowOfPlaceElement from './image/4_flow_of_place_element.png';
import layoutExample from './image/4_xml_1_layout_example.png';
import xmlSample from './image/4_xml_2_xml_sample.png';
import noahUi from './image/4_ui_1_noah_ui.png';

/*eslint-disable */

function NoahTutorialLab1Page4() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab1_title">Lab1 UI Layout Construction</h1>
          <h1 id="construct-noah-layout">Construct Noah Layout</h1>
          <p>To construct a Noah Layout, we have divided the assignment into several tasks.</p>
          <ol>
          <li>Prepare UI assets.</li>
          <li>Place UI in Figma.</li>
          <li>Place UI in XML.</li>
          </ol>
          <p><img src={flowOfPlaceElement} alt="image"/></p>
          <p>Let us describe them step by step.</p>
          <h2 id="prepare-ui-assets">Prepare UI assets</h2>
          <ol>
          <li>Navigate to the <code>project/demo_hello_noah/asset/image</code> directory. For the NOAH project, it's our convention to place 2D image files within the image folder, enabling the NOAH main program to read them.</li>
          <li>Additionally, we can organize the images further by placing them into subfolders for categorization.</li>
          </ol>
          <h2 id="place-ui-in-figma">Place UI in Figma</h2>
          <p>For the UI layout, we can use the 3rd tool to help us define the coordinate and preview. We use Figma for example here.</p>
          <ol>
          <li>
          <p>Register for a Figma account at https://www.figma.com/.</p>
          </li>
          <li>
          <p>Log in using your newly created Figma account.</p>
          </li>
          <li>
          <p>Click on the “Design File” button to create a new design file.</p>
          <ul>
          <li><img src={designbutton} alt="image"/></li>
          </ul>
          </li>
          <li>
          <p>To initiate a new canvas, click on the button shown in Images below. Create a rectangle by dragging and dropping with the mouse. Once the rectangle is formed, select it and enter the desired values into the &quot;Frame&quot; section. For our project, we've set the dimensions to 1920 x 720 with coordinates at (0, 0), matching our project's dimensions.</p>
          <ul>
          <li>
          <p><img src={framebutton} alt="image"/></p>
          </li>
          <li>
          <p><img src={dimension} alt="image" width={640} height={360}/></p>
          </li>
          </ul>
          </li>
          <li>
          <p>You can fill the canvas color by setting “Fill” section. For this example, we use 193333, the default background color of Noah.</p>
          <ul>
                <li><img src={addbackground} alt="image" width={960} height={480}/></li>
          </ul>
          </li>
          <li>
          <p>Simply drag and drop the image from your folder into the design area. Once placed, you can adjust the image's parameters or reposition it by dragging and dropping.</p>
          <ul>
          <li><img src={addimage} alt="image" width={960} height={480}/></li>
          </ul>
          </li>
          <li>
          <p>Add a text element by clicking on the button shown in image below. Create a text element by clicking on the canvas and typing in &quot;Hello, Noah!&quot;. Once placed, you can adjust the text element's parameters, or reposition it by dragging and dropping.</p>
          <ul>
          <li><img src={addtext} alt="image" width={960} height={480}/></li>
          </ul>
          </li>
          <li>
          <p>Add rectangle elements by clicking on the button shown in image below. Create a rectangle by dragging and dropping with the mouse. Once placed, you can adjust the rectangle element's  parameters, or reposition it by dragging and dropping.</p>
          <ul>
          <li><img src={addquad} alt="image" width={960} height={480}/></li>
          </ul>
          </li>
          <li>
          <p>Finally, we build a complete UI layout from the Figma.</p>
          </li>
          </ol>
          <h2 id="place-ui-in-xml">Place UI in XML</h2>
          <p>After completing the UI in Figma, you can select an element in Figma to identify its coordinates and dimensions, and then directly input these values into the XML file. Please note that if the UI element is within a group, the coordinates will be relative to that group, rather than being absolute coordinates.</p>
          <ul>
          <li>
          <p><img src={layoutExample} alt="image" width={960} height={480}/></p>
          </li>
          <li>
          <p><img src={xmlSample} alt="image" width={900} height={250}/></p>
          </li>
          </ul>
          <h2 id="run-the-project">Run the Project</h2>
          <ol>
          <li>Run Noath Theatre with project lab_demo_cluster</li>
          </ol>
          <pre class="hljs"><code><div>$ cd noah.lab_105a/
          $ noah_theatre-x64-linux project/lab_demo_cluster/
          </div></code></pre>
          <ol start="2">
          <li>A window shows on your screen like this.</li>
          </ol>
          <ul>
          <li><img src={noahUi} alt="image" width={960} height={360}/></li>
          </ul>
          <ol start="3">
          <li>Use hot key: CTRL + Esc to exit the Noah Theatre</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab1Page4;
