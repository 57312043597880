import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahDownloadPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <h1>Resources</h1>
      <h2>Pravaig</h2>
      <a href="http://gofile.me/3FXaO/WRoux3CC8">Noah Release 105a</a><br />
      <p style={{ whiteSpace: 'pre-wrap' }}>
       {'   '} released: 2024/11/25 <br />
       {'   '} Noah 105a along with Demo project. <br />
      </p>
    </div>
  );
}

export default NoahDownloadPage;
