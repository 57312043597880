import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
import noahXml from './image/4_1_noah_xml.png';
import setText from './image/4_2_set_text.png';
import setColor from './image/4_3_set_color.png';
import setRotate from './image/4_4_set_rotate.png';
/*eslint-disable */

function NoahTutorialLab2Page4() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="before-you-begin">Lab2 Lua Script</h1>
          <h1 id="introduce-to-noah-api">Introduce to Noah API</h1>
          <p>In Lua scripts, you can use the Noah-provided APIs to access or control the properties and states of <strong>Noah Basic Elements</strong>. These APIs are prefixed with <code>Noah</code> and offer a wide range of functionalities to interact with your UI components. For detailed information about each API, refer to the <a href="/api_reference">official API documentation</a>.</p>
          <p>Below, we will use the <code>demo_hello_noah</code> project to demonstrate several Noah APIs.</p>
          <h2 id="get-position">Get Position</h2>
          <p><code>NoahGetPosition(string target)</code> retrieves the position of an element with the specified <code>id</code> (<code>target</code>) and returns a <code>vec3</code> object. You can access the element's <code>x</code>, <code>y</code>, and <code>z</code> coordinates using <code>pos.x</code>, <code>pos.y</code>, and <code>pos.z</code>.</p>
          <p>You can add the following code to the <code>Update()</code> function to check an element's position. For example, if you call the <code>NoahGetPosition</code> function to get <code>image_1</code>'s position:</p><br />
          
          <pre class="hljs hljs-content"><code><div><span class="hljs-function">
            <span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}pos = NoahGetPosition(<span class="hljs-string">"image_1"</span>)<br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"pos: "</span> .. pos.x .. <span class="hljs-string">", "</span> .. pos.y .. <span class="hljs-string">", "</span> .. pos.z)<br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre>
          
          <p>Output Example: <br />
            <img src={noahXml} alt="alt text"  width={960} height={250}/></p>
          <pre class="hljs hljs-content"><code><div>
            pos: 1400.0, 120.0, 0.0
          </div></code></pre> <br />
          
          <h2 id="get-element-size">Get Element Size</h2>
          <p><code>NoahGetSize(string target)</code> retrieves the size of an element with the specified <code>id</code> (<code>target</code>) and returns a <code>vec2</code> object. The width and height can be accessed using <code>size.x</code> and <code>size.y</code>.</p>
          <p>This function is designed for the following elements:</p>
          <ul>
            <li><strong>Quad2D</strong>, <strong>Quad3D</strong></li>
            <li><strong>Image2D</strong>, <strong>Image3D</strong></li>
            <li><strong>Text2D</strong>, <strong>Text3D</strong></li>
            <li><strong>Video2D</strong></li>
          </ul>
          <p>You can add the following code to the <code>Update()</code> function to check an element's size. For example, if you call the <code>NoahGetSize</code> function to get <code>image_1</code>'s size:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}pos = NoahGetSize(<span class="hljs-string">"image_1"</span>)<br />
                {'  '}<span class="hljs-built_in">print</span>(<span class="hljs-string">"pos: "</span> .. pos.x .. <span class="hljs-string">", "</span> .. pos.y .. <span class="hljs-string">", "</span> .. pos.z)<br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre> <br />
          <p>Output Example: <br />
            <img src={noahXml} alt="alt text" width={960} height={250}/></p>
          <pre class="hljs hljs-content"><code><div>
            size: 300.0, 450.0
          </div></code></pre> <br />
          
          <h2 id="set-text-content">Set Text Content</h2>
          <p><code>NoahSetText(string target, string text)</code> sets the text content to <code>text</code> of a <strong>Text2D</strong> or <strong>Text3D</strong> element with the specified <code>id</code> (<code>target</code>). This function does not return any value.</p>
          <p>You can add the following code to the <code>Update()</code> function to set an element's text content. For example, if you call the <code>NoahSetText</code> function to set <code>hello</code>'s text content to the <code>num</code> which increases by 1 in each <code>Update</code> iteration:</p>
          
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> num = <span class="hljs-number">0</span><br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}num = num + <span class="hljs-number">1</span><br />
                {'  '}NoahSetText(<span class="hljs-string">"hello"</span>, <span class="hljs-built_in">tostring</span>(num))<br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre>
          <p>Output Example: <br />
            <img src={setText} alt="alt text" width={960} height={360}/>
          </p> <br /> 
            
          <h2 id="set-color">Set Color</h2>
          <p><code>NoahSetColor(string target, string color)</code> sets the color of a <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong> element with the specified <code>id</code> (<code>target</code>). The color is specified in <code>#AARRGGBB</code> hexadecimal format. This function does not return any value.</p>
          <p>You can add the following code to the <code>Update()</code> function to set an element's color. For example, if you call the <code>NoahSetColor</code> function to set <code>quad_1</code>'s color to the <code>color</code> which changes in each <code>Update</code> iteration:</p>
          
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> color = <span class="hljs-number">999999</span><br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}color = color - <span class="hljs-number">10</span><br />
                {'  '}<span class="hljs-keyword">local</span> hex_color = <span class="hljs-string">"#ff"</span> .. <span class="hljs-built_in">tostring</span>(color)<br />
                {'  '}NoahSetColor(<span class="hljs-string">"quad_1"</span>, hex_color)<br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre>

          <p>Output Example: <br />
            <img src={setColor} alt="alt text" width={960} height={360}/>
          </p> <br />

          <h2 id="rotate-the-element">Rotate the Element</h2>
          <p><code>NoahSetRotation2D(string target, number angle)</code> sets the rotation angle of a <code>2D element</code> with the specified <code>id</code> (<code>target</code>). The rotation is clockwise. This function does not return any value and works only on 2D elements.</p>
          <p>You can add the following code to the <code>Update()</code> function to set an element's rotation angle. For example, if you call the <code>NoahSetRotation2D</code> function to set <code>hello</code>'s rotation angle to the <code>rotate_angle</code> which increases by 1 in each <code>Update</code> iteration:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> rotate_angle = <span class="hljs-number">0</span><br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}rotate_angle = rotate_angle + <span class="hljs-number">1</span><br />
                {'  '}NoahSetRotation2D(<span class="hljs-string">"hello"</span>, rotate_angle)<br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre>

          <p>Or you can let the element rotate counterclockwise by decreasing <code>rotate_angle</code> by 1 in each <code>Update</code> iteration.</p>
          <p>Output Example:<br />
            <img src={setRotate} alt="alt text" width={960} height={360}/>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab2Page4;
