import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahTutorialLab3Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="conclusion">Conclusion</h1>
          <h2 id="summary">Summary</h2>
          <ul>
          <li>Use button component.</li>
          <li>Understand button appearance.</li>
          <li>Implement button event.</li>
          <li>Use slider component.</li>
          <li>Understand slider appearance.</li>
          <li>Implement slider event.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab3Page;
