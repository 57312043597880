import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahTutorialLab2Page6() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="before-you-begin">Lab2 Lua Script</h1>
          <h1 id="conclusion">Conclusion</h1>
          <h2 id="summary">Summary</h2>
          <ul>
          <li>Understand what Lua script is and its role in Noah projects.</li>
          <li>Learn about the functions in <code>script.lua</code>, their functionality, and lifecycle.</li>
          <li>Familiarize yourself with the predefined functions provided by Noah.</li>
          <li>Gain knowledge of the Noah API and how to interact with elements using it.</li>
          <li>Understand what a state machine is and how to use it.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab2Page6;
