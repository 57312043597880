import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIFilesystemPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Filesystem APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#noahlistdirectories">NoahListDirectories</a></li>
              <li><a href="#noahlistfiles">NoahListFiles</a></li>
              <li><a href="#noahfilterfiles">NoahFilterFiles</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahlistdirectories">NoahListDirectories</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahListDirectories(string path)</code></p>
          <h4 id="description">Description</h4>
          <p>List directories at given <em>path</em> as an array of strings.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>path</code> (string):</strong> The directory path to search.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return directory list in <em>path</em> as an array of strings.</p>
          <h4 id="example">Example</h4>
          <p>To get all directories in the path &quot;/mnt/media/&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> dirs = NoahListDirectories(<span class="hljs-string">"/mnt/media/"</span>)<br />
            <span class="hljs-keyword">local</span> numOfItems = #dirs<br />
            <span class="hljs-keyword">local</span> i<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"numOfItems = "</span> .. numOfItems)<br />
            <span class="hljs-keyword">for</span> i = <span class="hljs-number">1</span>, numOfItems <span class="hljs-keyword">do</span><br />
            {'  '}<span class="hljs-built_in">print</span>(dirs[i])<br />
            <span class="hljs-keyword">end</span>
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>
            numOfItems = 2<br />
            music<br />
            video
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahlistfiles">NoahListFiles</a> - Get list of file at given path.</li>
          <li><a href="#noahfilterfiles">NoahFilterFiles</a> - Get list of files at given directory with name filter.</li>
          </ul>
          <hr />
          <h3 id="noahlistfiles">NoahListFiles</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahListFiles(string path)</code></p>
          <h4 id="description">Description</h4>
          <p>List files at given <em>path</em> as an array of strings.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>path</code> (string):</strong> The directory path to search.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return file list in directory <em>path</em> as an array of strings.</p>
          <h4 id="example">Example</h4>
          <p>To get all files in the directory &quot;/mnt/media/music&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> files = NoahListFiles(<span class="hljs-string">"/mnt/media/music"</span>)<br />
            <span class="hljs-keyword">local</span> numOfItems = #files<br />
            <span class="hljs-keyword">local</span> i<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"numOfItems = "</span> .. numOfItems)<br />
            <span class="hljs-keyword">for</span> i = <span class="hljs-number">1</span>, numOfItems <span class="hljs-keyword">do</span><br />
            {'  '}<span class="hljs-built_in">print</span>(files[i])<br />
            <span class="hljs-keyword">end</span>
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>
            numOfItems = 5<br />
            Music1.mp3<br />
            Music2.mp3<br />
            Voice.aac<br />
            Other1.wav<br />
            Other2.wav
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahlistdirectories">NoahListDirectories</a> - Get list of directories at given path.</li>
          <li><a href="#noahfilterfiles">NoahFilterFiles</a> - Get list of files at given directory with name filter.</li>
          </ul>
          <hr />
          <h3 id="noahfilterfiles">NoahFilterFiles</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahFilterFiles(string path, string filter)</code></p>
          <h4 id="description">Description</h4>
          <p>List files at given <em>path</em> where the file names match the given <em>filter</em> .
          The <em>filter</em> must be a string consists of one or more .&lt;extension&gt; seperated by symbol &quot;|&quot;.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>path</code> (string):</strong> The directory path to search.</li>
          <li><strong><code>filter</code> (string):</strong> File type filter.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return file list in directory <em>path</em> witch name matches <em>filter</em> as an array of strings.</p>
          <h4 id="example">Example</h4>
          <p>To get all .mp3 and .aac in the directory &quot;/mnt/media/music&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> files = NoahFilterFiles(<span class="hljs-string">"/mnt/media/music"</span>, <span class="hljs-string">".mp3|.aac"</span>)<br />
            <span class="hljs-keyword">local</span> numOfItems = #files<br />
            <span class="hljs-keyword">local</span> i<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"numOfItems = "</span> .. numOfItems)<br />
            <span class="hljs-keyword">for</span> i = <span class="hljs-number">1</span>, numOfItems <span class="hljs-keyword">do</span><br />
            {'  '}<span class="hljs-built_in">print</span>(files[i])<br />
            <span class="hljs-keyword">end</span>
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>
            numOfItems = 3<br />
            Music1.mp3<br />
            Music2.mp3<br />
            Voice.aac
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahlistdirectories">NoahListDirectories</a> - Get list of directories at given path.</li>
          <li><a href="#noahlistfiles">NoahListFiles</a> - Get list of files at given directory.</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIFilesystemPage;
