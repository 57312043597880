import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import {
//   Card,
// } from 'react-bootstrap';
import i18n from './i18n';
import {
  inOmniMenu,
  inOverview,
  inNoahMenu,
  // inVehicleMenu, inDispatchMenu, inCoachMenu, inSettingsMenu,
} from './GlobalFunction';
import './css/App.css';
import './css/NavigationBar.css';
/*eslint-disable */

function NavigationBar({ user, onMenuClick }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPop, setIsPop] = useState(false);
  const { pathname } = window.location;

  useEffect(() => {
    const handleOutsideClick = () => {
      if (isPop) {
        setIsPop(false);
        onMenuClick(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [user, pathname, onMenuClick, isPop]);

  const handleUserSectionClick = (event) => {
    event.stopPropagation();
    setIsPop(true);
    onMenuClick(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleUserSectionClick(event);
    }
  };
  const accountName = user === undefined ? '' : user.split('@')[0].toUpperCase();

  return (
    <div
      className={`app-navigation ${isExpanded ? 'fixed expanded' : 'fixed'}`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {/* render the logo */}
      <div className="app-navigation-header">
        <div className="app-navigation-logo">
          <a href="/overview" aria-label="Home">
            <img
              className="navbar logo-img"
              alt=""
              src="/images/MAI_logoOnly_smooth.png"
            />
          </a>
        </div>
      </div>
      {/* render the menu */}
      <div className="app-navigation-menu">
        {/* render the fleet menu item */}
        <a
          className={
            inOverview()
              ? 'active active-exact app-navigation-menu-item'
              : 'app-navigation-menu-item'
          }
          href="/overview"
        >
          <div className="app-navigation-menu-item-icon">
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 18"
            >
              <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
            </svg>
          </div>
          <div className="app-navigation-menu-item-label">
            {i18n.t('navibar_overview')}
          </div>
        </a>
        <a
          className={
            inOmniMenu()
              ? 'active active-exact app-navigation-menu-item'
              : 'app-navigation-menu-item'
          }
          href="/omni/overview"
        >
          <div className="app-navigation-menu-item-icon">
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="4 4 27 27"
            >
              {' '}
              <g transform="translate(0,-263.13334)" id="layer1">
                <path
                  id="rect242"
                  transform="matrix(0.26458333,0,0,0.26458333,0,263.13334)"
                  d="M 20.714844 36.214844 C 16.757701 36.214844 13.572266 39.400279 13.572266 43.357422 L 13.572266 88 C 13.572266 91.957143 16.757701 95.142578 20.714844 95.142578 L 107.85742 95.142578 C 111.81456 95.142578 115 91.957143 115 88 L 115 43.357422 C 115 39.400279 111.81456 36.214844 107.85742 36.214844 L 20.714844 36.214844 z M 70.892578 56.035156 A 10.535714 10.089286 0 0 1 81.427734 66.125 A 10.535714 10.089286 0 0 1 70.892578 76.214844 A 10.535714 10.089286 0 0 1 60.357422 66.125 A 10.535714 10.089286 0 0 1 70.892578 56.035156 z M 97.767578 56.259766 A 10.535714 10.089286 0 0 1 108.30273 66.347656 A 10.535714 10.089286 0 0 1 97.767578 76.4375 A 10.535714 10.089286 0 0 1 87.232422 66.347656 A 10.535714 10.089286 0 0 1 97.767578 56.259766 z"
                  style={{
                    fillRule: 'evenodd',
                    strokeWidth: 0.99999994,
                  }}
                />
              </g>
            </svg>
          </div>
          <div className="app-navigation-menu-item-label">
            {i18n.t('navibar_omni')}
          </div>
        </a>
        <a
          className={
            inNoahMenu()
              ? 'active active-exact app-navigation-menu-item'
              : 'app-navigation-menu-item'
          }
          href="/Noah/overview"
        >
          <div className="app-navigation-menu-item-icon">
            <svg
              className="w-8 h-8 text-gray-800 dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 100 100"
            >
              <g transform="translate(0, 0)">
                {/* Outer Circle representing the UI Framework */}
                <circle cx="50" cy="50" r="48" fill="none" stroke="#444" strokeWidth="2" />
                
                {/* 3D Cube Effect */}
                <g transform="translate(25, 25)">
                  <path
                    d="M25 5 L 0 0 L 0 25 L 25 20 Z"
                    fill="none"
                    stroke="#555"
                    strokeWidth="2"
                  />
                  <path
                    d="M25 5 L 25 20 L 50 25 L 50 5 Z"
                    fill="none"
                    stroke="#555"
                    strokeWidth="2"
                  />
                  <path
                    d="M0 0 L 25 5 L 50 5 L 25 0 Z"
                    fill="none"
                    stroke="#555"
                    strokeWidth="2"
                  />
                </g>

                {/* Shading Effects to represent dynamic 3D rendering */}
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style={{ stopColor: 'rgb(29, 33, 41)', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: 'rgb(255, 255, 255)', stopOpacity: 1 }} />
                  </linearGradient>
                </defs>

                {/* Highlighting and Shading for the center */}
                <circle cx="50" cy="50" r="28" fill="url(#grad1)" opacity="0.4" />
                
                {/* The "N" for Noah */}
                <path
                  d="M 35,70 L 35,40 L 50,40 L 50,70"
                  fill="none"
                  stroke="#f8f8f2"
                  strokeWidth="3"
                />
                <path
                  d="M 50,40 L 65,40 L 65,70"
                  fill="none"
                  stroke="#f8f8f2"
                  strokeWidth="3"
                />
                
                {/* 3D shadow effect to give depth to the design */}
                <path
                  d="M 35,70 L 50,80 L 65,70"
                  fill="none"
                  stroke="#222"
                  strokeWidth="2"
                  opacity="0.3"
                />
              </g>
            </svg>
          </div>
          <div className="app-navigation-menu-item-label">
            {i18n.t('noah_title')}
          </div>
        </a>
      </div>
      {/* render the footer */}
      <div className="app-navigation-footer">
        <div className="app-navigation-user-dropdown">
          <div
            className="user-section"
            onClick={handleUserSectionClick}
            onKeyDown={handleKeyDown}
            tabIndex={0} // Makes the div focusable
            role="button" // Indicates the div acts as a button
            aria-haspopup="true"
            aria-expanded="false"
          >
            {/* render the user avatar */}
            <div className="avatar mr-2">
              <div>{accountName[0]}</div>
            </div>
            <div className="user-details">
              <div className="user-details-org" title="Manager">
                {i18n.t('info_company')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

NavigationBar.propTypes = {
  user: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
};

export default NavigationBar;
