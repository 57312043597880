import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
import noahRuntimeWorkflow from './image/Noah_runtime_workflow.png';

/*eslint-disable */

function NoahTutorialLab1Page2() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab1_title">Lab1 UI Layout Construction</h1>
          <h1 id="create-new-project">Create New Project</h1>
          <p>The Noah workflow is illustrated in the diagram below. The runtime interprets both the XML and Lua files, which dictate the UI layout and software behavior. Additionally, it can seamlessly integrate with external user libraries.</p>
          <p><img src={noahRuntimeWorkflow} alt="alt text" width={960}/></p>
          <p><strong>Lua</strong> is a lightweight, high-level, multi-paradigm programming language designed primarily for embedded use in applications. Lua is cross-platform, since the interpreter of compiled bytecode is written in ANSI C, and Lua has a relatively simple C API to embed it into applications. For more information, please go to https://www.lua.org/</p>
          <h2 id="noah-project">Noah Project</h2>
          <pre class="hljs hljs-content"><code><div>
          root<br />
          |__ noah                   # Binary for executing the Noah runtime environment.<br />
          |__ config<br />
          |____ font.yml             # Configuration file for the font manager to manage font file paths.<br />
          |____ graphic.yml          # Configuration file for Noah OpenGL (usually not modified).<br />
          |____ logger.yml           # Configuration file for managing Noah logger settings.<br />
          |__ project<br />
          |____ demo_meter           # User-specific project directory.<br />
          |________ assets           # Directory for storing assets such as images, 3D models, and other resources.<br />
          |________ script<br />
          |____________ script.lua   # Main entry point script for the Noah runtime.<br />
          |____________ keyboard.lua # Script for handling keyboard input events.<br />
          |____________ mouse.lua    # Script for handling mouse input events.<br />
          |____________ meter.lua    # Script created by user contain user defined function.<br />
          |________ noah.yml         # Configuration file for setting up the Noah window environment.<br />
          |________ main.xml         # XML file for defining the UI layout.<br />
          </div></code></pre>
          <br/>
          <ul>
            <li><strong>noah.yml (Configuration)</strong>:
              <ul>
                <li>A configuration file used to define the <em>window's size</em> and <em>position</em>. for the Noah UI. It serves as the foundational setup for your application.</li>
                <li>Because the window <code>id</code> is <code>main</code>, we'll have a <code>main.xml</code> file to describe what Noah elements are included in this project and how they are organized.</li>
              </ul>
            </li>
          </ul>
          <pre class="hljs hljs-content"><code><div>%YAML 1.2 <br />

          editing: 0   # Indicates the editing state. Default is 0. When set to 1, the Noah window will automatically adjust to fit the display resolution while maintaining the original aspect ratio.<br />
          window: <br />
            {'  '}- id: main # Unique identifier for the window; required when running multiple Noah projects simultaneously.<br />
              {'  '}{'  '}w: 1920  # Width of the window in pixels.<br />
              {'  '}{'  '}h: 720   # Height of the window in pixels.<br />
              {'  '}{'  '}x: 0     # Horizontal position of the window in pixels (not supported on embedded Wayland due to window management policy limitations).<br />
              {'  '}{'  '}y: 0     # Vertical position of the window in pixels.<br />
          </div></code></pre>

          <br/>
          <ul>
            <li><strong>main.xml (XML)</strong>:
              <ul>
                <li>Defines how the UI layout is rendered, including positioning, rotation angles, and dimensions.</li>
                <li>The XML structure is composed of various elements, similar to tags in HTML, and forms the visual framework of your application.</li>
              </ul>
            </li>
          </ul>
          <pre class="hljs hljs-content"><code><div><span class="hljs-meta">&lt;?xml version="1.0" encoding="UTF-8"?&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">Scene</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"main"</span> <span class="hljs-attr">sizepx</span>=<span class="hljs-string">"1920, 720"</span> <span class="hljs-attr">sizemm</span>=<span class="hljs-string">"346, 130"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0, 0, 0"</span> <span class="hljs-attr">eyepos</span>=<span class="hljs-string">"0, 90, 650"</span>&gt;</span><br />
              {'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Group2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"group_1"</span> <span class="hljs-attr">enabled</span>=<span class="hljs-string">"1"</span>&gt;</span><br />
                  {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Text2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"hello"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"960, 240"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"120"</span> <span class="hljs-attr">text</span>=<span class="hljs-string">"Hello, Noah"</span> /&gt;</span><br />
                  {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Quad2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"quad_1"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"960, 480"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">w</span>=<span class="hljs-string">"640"</span> <span class="hljs-attr">h</span>=<span class="hljs-string">"160"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0"</span> <span class="hljs-attr">color</span>=<span class="hljs-string">"#ffffffff"</span> /&gt;</span><br />
                  {'  '}{'  '}<span class="hljs-tag">&lt;<span class="hljs-name">Image2D</span> <span class="hljs-attr">id</span>=<span class="hljs-string">"image_1"</span> <span class="hljs-attr">src</span>=<span class="hljs-string">"asset/image/example.jpg"</span> <span class="hljs-attr">t</span>=<span class="hljs-string">"1600, 360"</span> <span class="hljs-attr">anchor</span>=<span class="hljs-string">"0.5, 0.5"</span> <span class="hljs-attr">r</span>=<span class="hljs-string">"0"</span>&gt;</span><br />
              {'  '}<span class="hljs-tag">&lt;/<span class="hljs-name">Group2D</span>&gt;</span><br />
          <span class="hljs-tag">&lt;/<span class="hljs-name">Scene</span>&gt;</span><br />
          </div></code></pre>

          <br/>
          <ul>
            <li>
              <p><strong>Assets</strong>:</p>
              <ul>
                <li>Contains the resources used for rendering the UI layout, such as <code>images</code>, <code>3D models</code>, and other assets. For example, the project introduced in this section includes a sample image stored in the <code>asset/image</code> folder.</li>
              </ul>
            </li>
            <li>
              <p><strong>Lua scripts</strong>:</p>
              <ul>
                <li>These scripts define the <em>program's logic</em> and <em>state machine</em>, acting as the backbone of the project. Lua scripts control user interactions and ensure smooth functionality throughout the application.</li>
              </ul>
            </li>
          </ul>
          <pre class="hljs hljs-content"><code><div>
            -- Import the mouse module for handling mouse input events<br />
            require("mouse")<br />
            -- Import the keyboard module for handling keyboard input events<br />
            require("keyboard")<br /><br />

            function Initialize()<br />
            {'  '}{'  '}-- Add any setup code here, such as initializing variables or loading resources<br />
            end<br /><br />

            -- @param interval: Time interval (in milliseconds or seconds) since the last update<br />
            function Update(interval)<br />
            {'  '}{'  '}-- Add logic to handle updates, such as animations, input events, or game logic<br />
            end<br /><br />

            function Terminate()<br />
            {'  '}{'  '}-- Add any cleanup code here, such as releasing resources or saving data<br />
            end
          </div></code></pre>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab1Page2;
