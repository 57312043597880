import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahLuaAPIBasicTransformPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Transform APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Element Enable</h3>
              <li><a href="#noahsetenabled">NoahSetEnabled</a></li>
              <li><a href="#noahisenabled">NoahIsEnabled</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Position - Set</h3>
              <li><a href="#noahsettotop">NoahSetToTop</a></li>
              <li><a href="#noahsettobottom">NoahSetToBottom</a></li>
              <li><a href="#noahsetposition2d">NoahSetPosition2D</a></li>
              <li><a href="#noahsetposition3d">NoahSetPosition3D</a></li>
              <li><a href="#noahsetpositionx">NoahSetPositionX</a></li>
              <li><a href="#noahsetpositiony">NoahSetPositionY</a></li>
              <li><a href="#noahsetpositionz">NoahSetPositionZ</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Position - Get</h3>
              <li><a href="#noahgetpositiononsurface">NoahGetPositionOnSurface</a></li>
              <li><a href="#noahgetpositionxonsurface">NoahGetPositionXOnSurface</a></li>
              <li><a href="#noahgetpositionyonsurface">NoahGetPositionYOnSurface</a></li>
              <li><a href="#noahisinside2d">NoahIsInside2D</a></li>
              <li><a href="#noahgetposition">NoahGetPosition</a></li>
              <li><a href="#noahgetposition2d">NoahGetPosition2D</a></li>
              <li><a href="#noahgetposition3d">NoahGetPosition3D</a></li>
              <li><a href="#noahgetpositionx">NoahGetPositionX</a></li>
              <li><a href="#noahgetpositiony">NoahGetPositionY</a></li>
              <li><a href="#noahgetpositionz">NoahGetPositionZ</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Rotation - Set</h3>
              <li><a href="#noahsetrotation2d">NoahSetRotation2D</a></li>
              <li><a href="#noahsetrotation3d">NoahSetRotation3D</a></li>
              <li><a href="#noahsetrotationx">NoahSetRotationX</a></li>
              <li><a href="#noahsetrotationy">NoahSetRotationY</a></li>
              <li><a href="#noahsetrotationz">NoahSetRotationZ</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Rotation - Get</h3>
              <li><a href="#noahgetrotation">NoahGetRotation</a></li>
              <li><a href="#noahgetrotation2d">NoahGetRotation2D</a></li>
              <li><a href="#noahgetrotation3d">NoahGetRotation3D</a></li>
              <li><a href="#noahgetrotationx">NoahGetRotationX</a></li>
              <li><a href="#noahgetrotationy">NoahGetRotationY</a></li>
              <li><a href="#noahgetrotationz">NoahGetRotationZ</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Scale - Set</h3>
              <li><a href="#noahsetscale2d">NoahSetScale2D</a></li>
              <li><a href="#noahsetscale3d">NoahSetScale3D</a></li>
              <li><a href="#noahsetscalex">NoahSetScaleX</a></li>
              <li><a href="#noahsetscaley">NoahSetScaleY</a></li>
              <li><a href="#noahsetscalez">NoahSetScaleZ</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Scale - Get</h3>
              <li><a href="#noahgetscale">NoahGetScale</a></li>
              <li><a href="#noahgetscale2d">NoahGetScale2D</a></li>
              <li><a href="#noahgetscale3d">NoahGetScale3D</a></li>
              <li><a href="#noahgetscalex">NoahGetScaleX</a></li>
              <li><a href="#noahgetscaley">NoahGetScaleY</a></li>
              <li><a href="#noahgetscalez">NoahGetScaleZ</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Transform Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahsetenabled">NoahSetEnabled</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetEnabled(string target, bool enabled)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Enables or disables the specified <em>target</em>. This function is
            designed to toggle the active state of a given target element within
            the application.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
            <li>
              <strong>
                <code>enabled</code> (bool):
              </strong>
              A boolean value indicating whether the target should be enabled (
              <code>true</code>) or disabled (<code>false</code>).
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To disable an element identified by &quot;exampleElement&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetEnabled(
                <span className="hljs-string">&quot;exampleElement&quot;</span>,
                <span className="hljs-literal">false</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahisenabled">NoahIsEnabled</a> - Checks if the
              specified target is enabled.
            </li>
          </ul>
          <hr />
          <h3 id="noahisenabled">NoahIsEnabled</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahIsEnabled(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Checks if the specified <em>target</em> is enabled, returning a
            boolean value that indicates the target&quots state.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code>(string):
              </strong>
              The unique identifier for the target element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns <code>true</code> if the target is enabled,
            <code>false</code> otherwise.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To check if an element identified by &quot;exampleElement&quot; is
            enabled:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> isEnabled =
                NoahIsEnabled(
                <span className="hljs-string">&quot;exampleElement&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Is enabled: &quot;</span> ..
                <span className="hljs-built_in">tostring</span>(isEnabled))
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Is enabled: true</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetenabled">NoahSetEnabled</a> - Enables or disables
              he specified <em>target</em>.
            </li>
          </ul>
          <hr />
          <h3 id="noahsettotop">NoahSetToTop</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetToTop(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Moves the specified <em>target</em> to the top of its group,
            affecting the rendering order of 2D elements. While this function
            alters the visual stacking for 2D components, it may not have a
            visible impact on 3D elements due to 3D rendering being determined
            by depth testing rather than component order.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To move an element identified by &quot;exampleElement&quot; to the
            top of its group:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetToTop(
                <span className="hljs-string">&quot;exampleElement&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsettobottom">NoahSetToBottom</a> - Positions the
              specified <em>target</em> at the bottom of its group.
            </li>
          </ul>
          <hr />
          <h3 id="noahsettobottom">NoahSetToBottom</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetToBottom(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Positions the specified <em>target</em> at the bottom of its group.
            This function is intended to modify the rendering sequence of 2D
            elements. Similar to <code>NoahSetToTop</code>, its effect on 3D
            elements might be negligible due to depth testing priorities in 3D
            space.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To place an element identified by &quot;exampleElement&quot; at the
            bottom of its group:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetToBottom(
                <span className="hljs-string">&quot;exampleElement&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsettotop">NoahSetToTop</a> - Positions the specified
              target at the top of its group.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetposition2d">NoahSetPosition2D</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetPosition2D(string target, number x, number y)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Repositions the specified <em>target</em> to the given coordinates (
            <em>x, y</em>) in pixels.This function is specifically designed for
            <strong>2D elements</strong>.
            Attempting to use this function on a <strong>3D element</strong> will result in an error message from Noah, and the position will not be changed.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>x</code> (number), <code>y</code> (number):</strong> The desired position in pixels.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To move a 2D element &quot;exampleElement&quot; to the coordinates (100, 150) pixels:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahSetPosition2D(
                <span className="hljs-string">"exampleElement"</span>,
                <span className="hljs-number">100</span>,
                <span className="hljs-number">150</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetpositionx">NoahSetPositionX</a> - Sets the <em>x</em> coordinate of the specified <em>target</em> to the given value.</li>
            <li><a href="#noahsetpositiony">NoahSetPositionY</a> - Sets the <em>y</em> coordinate of the specified <em>target</em> to the given value.</li>
          </ul>
          <hr />
          <h3 id="noahsetposition3d">NoahSetPosition3D</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetPosition3D(string target, number x, number y, number z)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Transitions the specified <em>target</em> to the designated coordinates (<em>x, y, z</em>) in millimiters. This function exclusively affects <strong>3D elements</strong>. Applying it to a <strong>2D element</strong> will prompt an error message from Noah, with no alteration to the position.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
            <li>
              <strong>
                <code>x</code> (number), <code>y</code> (number), <code>z</code> (number):
              </strong> The target position in millimeters.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>To position a 3D element &quot;3DObject&quot; at (10, 20, 30) millimiters:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetPosition3D(<span className="hljs-string">"3DObject"</span>,
                <span className="hljs-number">10</span>,
                <span className="hljs-number">20</span>,
                <span className="hljs-number">30</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetpositionx">NoahSetPositionX</a> - Sets the <em>x</em> coordinate of the specified <em>target</em> to the given value.
            </li>
            <li>
              <a href="#noahsetpositiony">NoahSetPositionY</a> - Sets the <em>y</em> coordinate of the specified <em>target</em> to the given value.
            </li>
            <li>
              <a href="#noahsetpositionz">NoahSetPositionZ</a> - Sets the <em>z</em> coordinate of the specified <em>target</em> to the given value.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetpositionx">NoahSetPositionX</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetPositionX(string target, number x)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Sets the <em>x</em> coordinate of the specified <em>target</em> to the given value, while the <em>y</em> and <em>z</em> coordinates remain unchanged. This function is suitable for both <strong>2D elements</strong> and <strong>3D elements</strong>. For <strong>2D elements</strong>, the unit of measurement is pixels, indicating the position on the screen. For <strong>3D elements</strong>, the unit is millimeters, reflecting the position within a three-dimensional space. Note that the interpretation of the <em>x</em> coordinate value can vary significantly between 2D and 3D contexts due to the difference in measurement units and spatial considerations.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>x</code> (number):</strong> The new <em>x</em> coordinate.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To move &quot;exampleElement&quot; to an <em>x</em> coordinate of 200 units:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetPositionX(
                <span className="hljs-string">"exampleElement"</span>,
                <span className="hljs-number">200</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetposition3d">NoahSetPosition3D</a> - Transitions the specified <em>target</em> to the designated coordinates (<em>x, y, z</em>) in millimeters.</li>
            <li><a href="#noahsetposition2d">NoahSetPosition2D</a> Repositions the specified <em>target</em> to the given coordinates (<em>x, y</em>) in pixels.</li>
            <li><a href="#noahsetpositiony">NoahSetPositionY</a> - Sets the <em>y</em> coordinate of the specified <em>target</em> to the given value.</li>
            <li><a href="#noahsetpositionz">NoahSetPositionZ</a> - Sets the <em>z</em> coordinate of the specified <em>target</em> to the given value.</li>
          </ul>
          <hr />
          <h3 id="noahsetpositiony">NoahSetPositionY</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetPositionY(string target, number y)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Sets the <em>y</em> coordinate of the specified <em>target</em> to the given value, while the <em>x</em> and <em>z</em> coordinates remain unchanged. This function is suitable for both <strong>2D elements</strong> and <strong>3D elements</strong>. For <strong>2D elements</strong>, the unit of measurement is pixels, indicating the position on the screen. For <strong>3D elements</strong>, the unit is millimeters, reflecting the position within a three-dimensional space. Note that the interpretation of the <em>y</em> coordinate value can vary significantly between 2D and 3D contexts due to the difference in measurement units and spatial considerations.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>y</code> (number):</strong> The new <em>y</em> coordinate.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To adjust &quot;exampleElement&quot; to a <em>y</em> coordinate of 300 units:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahSetPositionY(
                <span className="hljs-string">&quot;exampleElement&quot;</span>,
                <span className="hljs-number">300</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetposition3d">NoahSetPosition3D</a> - Transitions the specified <em>target</em> to the designated coordinates (<em>x, y, z</em>) in millimeters.</li>
            <li><a href="#noahsetposition2d">NoahSetPosition2D</a> Repositions the specified <em>target</em> to the given coordinates (<em>x, y</em>) in pixels.</li>
            <li><a href="#noahsetpositionx">NoahSetPositionX</a> - Sets the <em>x</em> coordinate of the specified <em>target</em> to the given value.</li>
            <li><a href="#noahsetpositionz">NoahSetPositionZ</a> - Sets the <em>z</em> coordinate of the specified <em>target</em> to the given value.</li>
          </ul>
          <hr />
          <h3 id="noahsetpositionz">NoahSetPositionZ</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetPositionZ(string target, number z)</code></p>
          <h4 id="description">Description</h4>
          <p>
            Sets the <em>z</em> coordinate of the specified <em>target</em> to the given value, while the <em>x</em> and <em>y</em> coordinates remain unchanged. This function is suitable for only <strong>3D elements</strong>. For <strong>3D elements</strong>, the unit is millimeters, reflecting the position within a three-dimensional space.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>z</code> (number):</strong> The new <em>z</em> coordinate.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To set the <em>z</em> position of &quot;3DObject&quot; to 50 millimeters:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetPositionZ(
                <span className="hljs-string">"3DObject"</span>,
                <span className="hljs-number">50</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetposition3d">NoahSetPosition3D</a> - Transitions the specified <em>target</em> to the designated coordinates (<em>x, y, z</em>) in millimeters.
            </li>
            <li>
              <a href="#noahsetpositionx">NoahSetPositionX</a> - Sets the <em>x</em> coordinate of the specified <em>target</em> to the given value.
            </li>
            <li>
              <a href="#noahsetpositiony">NoahSetPositionY</a> - Sets the <em>y</em> coordinate of the specified <em>target</em> to the given value.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetpositiononsurface">NoahGetPositionOnSurface</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetPositionOnSurface(string name)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Calculate surface position of <em>name</em>, and return a
            <strong>vec2</strong> as result. If <em>name</em> is not a valid
            node, Noah logs an error message and returns
            <strong>(0, 0)</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code> (string):
              </strong>
              The unique identifier for the scene element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec2</strong> object as the surface position of
            the node.
          </p>
          <h4 id="example">Example</h4>
          <p>To calculate surface position of &quot;background&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> pos =
                NoahGetPositionOnSurface(
                <span className="hljs-string">&quot;background&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">
                  &quot;Surface position : &quot;
                </span>
                .. pos.x ..
                <span className="hljs-string">&quot;, &quot;</span> .. pos.y)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Surface position : 960.0, 640.0</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetpositionxonsurface">NoahGetPositionXOnSurface</a>
              - Calculate surface x position of node.
            </li>
            <li>
              <a href="#noahgetpositionyonsurface">NoahGetPositionYOnSurface</a>
              - Calculate surface y position of node.
            </li>
            <li>
              <a href="#noahisinside2d">NoahIsInside2D</a> - Check global (x, y)
              is in 2d range.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetpositionxonsurface">NoahGetPositionXOnSurface</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetPositionXOnSurface(string name)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Calculate surface x-axis position of <em>name</em>, and return a
            number as result. If <em>name</em> is not a valid node, Noah logs an
            error message and returns <strong>0.0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code> (string):
              </strong>
              The unique identifier for the scene element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the surface x-axis position of the node.</p>
          <h4 id="example">Example</h4>
          <p>To calculate surface x-axis position of &quot;background&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> pos =
                NoahGetPositionXOnSurface(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">
                  &quot;Surface position : &quot;
                </span>
                .. pos)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Surface position : 960.0</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetpositiononsurface">NoahGetPositionOnSurface</a> -
              Calculate surface position of node.
            </li>
            <li>
              <a href="#noahgetpositionyonsurface">NoahGetPositionYOnSurface</a>
              - Calculate surface y position of node.
            </li>
            <li>
              <a href="#noahisinside2d">NoahIsInside2D</a> - Check global (x, y)
              is in 2d range.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetpositionyonsurface">NoahGetPositionYOnSurface</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetPositionYOnSurface(string name)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Calculate surface y-axis position of <em>name</em>, and return a
            number as result. If <em>name</em> is not a valid node, Noah logs an
            error message and returns <strong>0.0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code> (string):
              </strong>
              The unique identifier for the scene element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the surface y-axis position of the node.</p>
          <h4 id="example">Example</h4>
          <p>To calculate surface y-axis position of &quot;background&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> pos =
                NoahGetPositionYOnSurface(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">
                  &quot;Surface position : &quot;
                </span>
                .. pos)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Surface position : 640.0</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetpositiononsurface">NoahGetPositionOnSurface</a> -
              Calculate surface position of node.
            </li>
            <li>
              <a href="#noahgetpositionxonsurface">NoahGetPositionXOnSurface</a>
              - Calculate surface x position of node.
            </li>
            <li>
              <a href="#noahisinside2d">NoahIsInside2D</a> - Check global (x, y)
              is in 2d range.
            </li>
          </ul>
          <hr />
          <h3 id="noahisinside2d">NoahIsInside2D</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahIsInside2D(const char* name, f32T x, f32T y)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Calculate whether global (x, y) in <em>name</em>, where name is a
            <strong>2D Element</strong>.If <em>name</em> is not a valid node,
            Noah logs an error message and returns <strong>false</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code> (string):
              </strong>
              The unique identifier for the scene element, must be
              <strong>2D Element</strong>.
            </li>
            <li>
              <strong>
                <code>x</code> (string):
              </strong>
              global x position.
            </li>
            <li>
              <strong>
                <code>y</code> (string):
              </strong>
              global y position.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns check result in boolean.</p>
          <h4 id="example">Example</h4>
          <p>To check (960.0, 640.0) in in 2d node &quot;background&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> result =
                NoahIsInside2D(
                <span className="hljs-string">&quot;main&quot;</span>,
                <span className="hljs-number">960.0</span>,
                <span className="hljs-number">640.0</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Check result : &quot;</span>
                .. result)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Check result : true</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetpositiononsurface">NoahGetPositionOnSurface</a> -
              Calculate surface position of node.
            </li>
            <li>
              <a href="#noahgetpositionxonsurface">NoahGetPositionXOnSurface</a>
              - Calculate surface x position of node.
            </li>
            <li>
              <a href="#noahgetpositionyonsurface">NoahGetPositionYOnSurface</a>
              - Calculate surface y position of node.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetposition">NoahGetPosition</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetPosition(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the position of the specified <em>target</em>, returning a <strong>vec3</strong> object, measured in pixels for <strong>2D objects</strong> and millimeters for <strong>3D objects</strong>. This object allows access to the x, y, and z coordinates using the dot operator. This function is applicable to both <strong>2D elements</strong> and <strong>3D elements</strong>. For <strong>2D elements</strong>, the z value will always be <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec3</strong> object representing the target's position.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To get the x position of &quot;my_target&quot;:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                x_position = NoahGetPosition(
                <span className="hljs-string">&quot;my_target&quot;</span>).x<br/>
                <span className="hljs-built_in">print</span>(<span className="hljs-string">"X position: "</span> .. x_position)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>X position: 100
          </div>
          </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetposition3d">NoahGetPosition3D</a> - Obtains the position of a specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetposition2d">NoahGetPosition2D</a> - Obtains the position of a specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetpositionx">NoahGetPositionX</a> - Retrieves the x coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositiony">NoahGetPositionY</a> - Retrieves the y coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositionz">NoahGetPositionZ</a> - Retrieves the z coordinate of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetposition2d">NoahGetPosition2D</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>
              NoahGetPosition2D(string target)
            </code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Obtains the position of a specified <em>target</em> that is a <strong>2D element</strong>, returning a <strong>vec2</strong> object for accessing the x and y coordinates. This function exclusively affects <strong>2D elements</strong>; using it on a <strong>3D element</strong> results in an error and a return value of <strong>vec2(0, 0)</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec2</strong> object representing the target's 2D position.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To get the position of a 2D element &quot;my_2dTarget&quot;:
          </p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-keyword">local</span> position = NoahGetPosition2D(<span className="hljs-string">"my_2dTarget"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"X: "</span> .. position.x .. <span className="hljs-string">", Y: "</span> .. position.y)
          </div>
          </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>X: 200, Y: 100
          </div>
          </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetposition">NoahGetPosition</a> - Obtains the position of a specified <em>target</em>.</li>
            <li><a href="#noahgetposition3d">NoahGetPosition3D</a> - Obtains the position of a specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetpositionx">NoahGetPositionX</a> - Retrieves the x coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositiony">NoahGetPositionY</a> - Retrieves the y coordinate of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetposition3d">NoahGetPosition3D</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetPosition3D(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Fetches the position of the specified <em>target</em> that is a <strong>3D element</strong>, returning a <strong>vec3</strong> object. This function is intended for <strong>3D elements</strong> only; attempting to use it on a <strong>2D element</strong> triggers an error and returns <strong>vec3(0, 0, 0)</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec3</strong> object representing the target's 3D position.
          </p>
          <h4 id="example">Example</h4>
          <p>To get the 3D position of &quot;my_3dTarget&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span>
                position = NoahGetPosition3D(
                <span className="hljs-string">&quot;my_3dTarget&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">"X: "</span>
                .. position.x ..
                <span className="hljs-string">", Y: "</span>
                .. position.y ..
                <span className="hljs-string">", Z: "</span>
                .. position.z)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>X: 200, Y: 100, Z: 50
          </div>
          </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetposition">NoahGetPosition</a> - Obtains the position of a specified <em>target</em>.</li>
            <li><a href="#noahgetposition2d">NoahGetPosition2D</a> - Obtains the position of a specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetpositionx">NoahGetPositionX</a> - Retrieves the x coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositiony">NoahGetPositionY</a> - Retrieves the y coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositionz">NoahGetPositionZ</a> - Retrieves the z coordinate of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetpositionx">NoahGetPositionX</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetPositionX(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the x coordinate of the specified <em>target</em>, measured in pixels for
            <strong>2D objects</strong>
            and millimeters for
            <strong>3D objects</strong>.
            This function applies to both
            <strong>2D elements</strong> and
            <strong>3D elements</strong>,
            returning a float representing the x position in the respective unit of measurement.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns the x coordinate as a float. The unit is pixels for 2D objects and millimeters for 3D objects.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To get the x position of &quot;my_target&quot;:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> x_position = NoahGetPositionX(<span className="hljs-string">"my_target"</span>)<br/>
                <span className="hljs-built_in">print</span>(<span className="hljs-string">"X position: "</span> .. x_position)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>X position: 200</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetposition">NoahGetPosition</a> - Obtains the position of a specified <em>target</em>.</li>
            <li><a href="#noahgetposition3d">NoahGetPosition3D</a> - Obtains the position of a specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetposition2d">NoahGetPosition2D</a> - Obtains the position of a specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetpositiony">NoahGetPositionY</a> - Retrieves the y coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositionz">NoahGetPositionZ</a> - Retrieves the z coordinate of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetpositiony">NoahGetPositionY</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetPositionY(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Obtains the y coordinate of the specified <em>target</em>, measured in pixels for <strong>2D objects</strong> and millimeters for <strong>3D objects</strong>. Suitable for both <strong>2D elements</strong> and <strong>3D elements</strong>, the function returns a float number for the y position in the appropriate unit of measurement.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the y coordinate as a float. The unit is pixels for 2D objects and millimeters for 3D objects.</p>
          <h4 id="example">Example</h4>
          <p>To get the y position of &quot;my_target&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-keyword">local</span> y_position = NoahGetPositionY(<span className="hljs-string">"my_target"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"Y position: "</span> .. y_position)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Y position: 100
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetposition">NoahGetPosition</a> - Obtains the position of a specified <em>target</em>.</li>
            <li><a href="#noahgetposition3d">NoahGetPosition3D</a> - Obtains the position of a specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetposition2d">NoahGetPosition2D</a> - Obtains the position of a specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetpositionx">NoahGetPositionX</a> - Retrieves the x coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositionz">NoahGetPositionZ</a> - Retrieves the z coordinate of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetpositionz">NoahGetPositionZ</h3>
          <h4 id="definition">Definition</h4><p><code>NoahGetPositionZ(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>
            Fetches the z coordinate of the specified <em>target</em>. This function is effective for <strong>3D elements</strong>, with the unit of measurement in millimeters. For <strong>2D elements</strong>, it always returns <strong>0</strong>, reflecting the absence of a z dimension in 2D space.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>
                  target
                </code>
                (string):</strong> The unique identifier for the target element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns the z coordinate as a float. The unit is millimeters for 3D objects. For 2D objects, it is not applicable and thus returns <strong>0</strong>.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To get the z position of &quot;my_3dTarget&quot;:
          </p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-keyword">local</span> z_position = NoahGetPositionZ(<span className="hljs-string">"my_3dTarget"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"Z position: "</span> .. z_position)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Z position: 50
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetposition">NoahGetPosition</a> - Obtains the position of a specified <em>target</em>.</li>
            <li><a href="#noahgetposition3d">NoahGetPosition3D</a> - Obtains the position of a specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetpositionx">NoahGetPositionX</a> - Retrieves the x coordinate of the specified <em>target</em>.</li>
            <li><a href="#noahgetpositiony">NoahGetPositionY</a> - Retrieves the y coordinate of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahsetrotation2d">NoahSetRotation2D</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetRotation2D(string target, number angle)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Rotates the specified <em>target</em> to a given <em>angle</em> in degrees within 2D space. The rotation is clockwise; to rotate counterclockwise, set <em>angle</em> to a negative value. This function is exclusively applicable to <strong>2D elements</strong>. Attempting to use it on a <strong>3D element</strong> will result in an error message from Noah, and no rotation will be applied.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong> The unique identifier for the target element.</li>
            <li><strong><code>angle</code> (number):</strong> The rotation angle in degrees.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To rotate a 2D element &quot;exampleElement&quot; 45 degrees clockwise:</p>
          <pre className="hljs hljs-content"><code><div>
            NoahSetRotation2D(<span className="hljs-string">"exampleElement"</span>, <span className="hljs-number">45</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetrotation3d">NoahSetRotation3D</a> - Applies rotation to the specified <em>target</em> using <strong>Euler angles</strong> (<em>x, y, z</em>) in degrees for 3D space.</li>
            <li><a href="#noahsetrotationx">NoahSetRotationX</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the X-axis to the given angle in degrees.</li>
            <li><a href="#noahsetrotationy">NoahSetRotationY</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Y-axis to the given angle in degrees.</li>
          </ul>
          <hr />
          <h3 id="noahsetrotation3d">NoahSetRotation3D</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetRotation3D(string target, number x, number y, number z)</code></p>
          <h4 id="description">Description</h4>
          <p>
            Applies rotation to the specified <em>target</em> using <strong>Euler angles</strong> (<em>x, y, z</em>) in degrees for 3D space. This function is tailored for <strong>3D elements</strong> only. Using it on a <strong>2D element</strong> triggers an error message from Noah, with no rotation effect applied.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>x</code> (number), <code>y</code> (number), <code>z</code> (number):</strong> The Euler angles in degrees.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To rotate a 3D element &quot;3DObject&quot; to Euler angles (30, 45, 60) degrees:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahSetRotation3D(
                <span className="hljs-string">"3DObject"</span>,
                <span className="hljs-number">90</span>,
                <span className="hljs-number">45</span>,
                <span className="hljs-number">60</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetrotation2d">NoahSetRotation2D</a> - Rotates the specified <em>target</em> to a given <em>angle</em> in degrees within 2D space.</li>
            <li><a href="#noahsetrotationx">NoahSetRotationX</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the X-axis to the given angle in degrees.</li>
            <li><a href="#noahsetrotationy">NoahSetRotationY</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Y-axis to the given angle in degrees.</li>
            <li><a href="#noahsetrotationz">NoahSetRotationZ</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Z-axis to the given angle in degrees.</li>
          </ul>
          <hr />
          <h3 id="noahsetrotationx">NoahSetRotationX</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>
              NoahSetRotationX(string target, number x)
            </code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the X-axis to the given <em>x</em> angle in degrees, leaving the Y and Z axes unchanged. This function exclusively affects <strong>3D elements</strong>; applying it to a <strong>2D element</strong> results in an error and no rotation.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>x</code> (number):</strong> The angle in degrees for the X-axis rotation.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To rotate &quot;3DObject&quot; 90 degrees around the X-axis:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahSetRotationX(
                <span className="hljs-string">"3DObject"</span>,
                <span className="hljs-number">90</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetrotation2d">NoahSetRotation2D</a> - Rotates the specified <em>target</em> to a given <em>angle</em> in degrees within 2D space.</li>
            <li><a href="#noahsetrotation3d">NoahSetRotation3D</a> - Applies rotation to the specified <em>target</em> using <strong>Euler angles</strong> (<em>x, y, z</em>) in degrees for 3D space.</li>
            <li><a href="#noahsetrotationy">NoahSetRotationY</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Y-axis to the given angle in degrees.</li>
            <li><a href="#noahsetrotationz">NoahSetRotationZ</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Z-axis to the given angle in degrees.</li>
          </ul>
          <hr />
          <h3 id="noahsetrotationy">NoahSetRotationY</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetRotationY(string target, number y)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Rotates the specified <em>target</em>, a <strong>3D element</strong>, around the Y-axis to the assigned <em>y</em> value in degrees, with X and Z axes positions maintained. This function is intended for <strong>3D elements</strong> only. An attempt to use it on a <strong>2D element</strong> will cause Noah to log an error message without performing the rotation.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>y</code> (number):</strong> The angle in degrees for the Y-axis rotation.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To rotate &quot;3DObject&quot; 45 degrees around the Y-axis:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahSetRotationY(
                <span className="hljs-string">"3DObject"</span>,
                <span className="hljs-number">45</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetrotation2d">NoahSetRotation2D</a> - Rotates the specified <em>target</em> to a given <em>angle</em> in degrees within 2D space.</li>
            <li><a href="#noahsetrotation3d">NoahSetRotation3D</a> - Applies rotation to the specified <em>target</em> using <strong>Euler angles</strong> (<em>x, y, z</em>) in degrees for 3D space.</li>
            <li><a href="#noahsetrotationx">NoahSetRotationX</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the X-axis to the given angle in degrees.</li>
            <li><a href="#noahsetrotationz">NoahSetRotationZ</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Z-axis to the given angle in degrees.</li>
          </ul>
          <hr />
          <h3 id="noahsetrotationz">NoahSetRotationZ</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetRotationZ(string target, number z)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Z-axis to the specified <em>z</em> angle in degrees, keeping the positions of the X and Y axes unchanged. This function is applicable only to <strong>3D elements</strong>. Targeting a <strong>2D element</strong> with this function results in an error message from Noah, with no rotation action taken.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong> The unique identifier for the target element.
            </li>
            <li>
              <strong>
                <code>z</code> (number):
              </strong> The angle in degrees for the Z-axis rotation.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            This function does not return any value.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To rotate &quot;3DObject&quot; 60 degrees around the Z-axis:</p>
          <pre className="hljs hljs-content">
            <code><div>NoahSetRotationZ(
              <span className="hljs-string">"3DObject"</span>,
              <span className="hljs-number">60</span>)
            </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetrotation3d">NoahSetRotation3D</a> - Applies rotation to the specified <em>target</em> using <strong>Euler angles</strong> (<em>x, y, z</em>) in degrees for 3D space.</li>
            <li><a href="#noahsetrotationx">NoahSetRotationX</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the X-axis to the given angle in degrees.</li>
            <li><a href="#noahsetrotationy">NoahSetRotationY</a> - Rotates the specified <em>target</em>, which is a <strong>3D element</strong>, around the Y-axis to the given angle in degrees.</li>
          </ul>
          <hr />
          <h3 id="noahgetrotation">NoahGetRotation</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetRotation(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Retrieves the rotation of the specified <em>target</em>, returning a <strong>vec3</strong> object for accessing the Euler angles (x, y, z). This function is applicable to both <strong>2D elements</strong> and <strong>3D elements</strong>. For <strong>2D elements</strong>, only the z value is relevant, representing rotation around the Z-axis in degrees, while x and y values are always <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a <strong>vec3</strong> object representing the target's rotation in Euler angles.</p>
          <h4 id="example">Example</h4>
          <p>To get the x component of rotation for &quot;my_target&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>x_rotation = NoahGetRotation(<span className="hljs-string">"my_target"</span>).x<br/>
                <span className="hljs-built_in">print</span>(<span className="hljs-string">"Rotation angle X: "</span> .. x_rotation)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code>
            <div>Rotation angle X: 90</div></code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetposition">NoahGetRotation2D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetposition3d">NoahGetRotation3D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetrotationx">NoahGetRotationX</a> - Fetches the x-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationy">NoahGetRotationY</a> - Fetches the y-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationz">NoahGetRotationZ</a> - Fetches the z-component of the rotation for the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetrotation2d">NoahGetRotation2D</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetRotation2D(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Fetches the rotation angle of a specified <em>target</em> that is a <strong>2D element</strong>, measured in degrees. This function exclusively affects <strong>2D elements</strong>; attempting to use it on a <strong>3D element</strong> will result in an error and a return value of <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the 2D target element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a float number representing the rotation angle in degrees for 2D elements.</p>
          <h4 id="example">Example</h4>
          <p>To get the rotation angle of &quot;my_2dTarget&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>angle = NoahGetRotation2D(<span className="hljs-string">"my_2dTarget"</span>)<br/>
                <span className="hljs-built_in">print</span>(<span className="hljs-string">"Rotation angle: "</span> .. angle)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Rotation angle: 45</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetrotation">NoahGetRotation</a> - Retrieves the rotation of the specified <em>target</em>.</li>
            <li><a href="#noahgetposition3d">NoahGetRotation3D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetrotationx">NoahGetRotationX</a> - Fetches the x-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationy">NoahGetRotationY</a> - Fetches the y-component of the rotation for the specified <em>target</em></li>
          </ul>
          <hr />
          <h3 id="noahgetrotation3d">NoahGetRotation3D</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetRotation3D(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Obtains the rotation of the specified <em>target</em> that is a <strong>3D element</strong>, returning a <strong>vec3</strong> object for Euler angles (x, y, z) access. This function is designed for <strong>3D elements</strong> only; using it on a <strong>2D element</strong> will cause an error and return <strong>vec3(0, 0, 0)</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the 3D target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a <strong>vec3</strong> object representing the Euler angles of the target's rotation in degrees for 3D elements.</p>
          <h4 id="example">Example</h4>
          <p>
            To get the rotation Euler angles of &quot;my_3dTarget&quot;:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>rotation = NoahGetRotation3D(
                <span className="hljs-string">"my_3dTarget"</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">"Rotation: X="</span>
                .. rotation.x ..
                <span className="hljs-string">", Y="</span>
                .. rotation.y ..
                <span className="hljs-string">", Z="</span>
                .. rotation.z)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Rotation: X=90, Y=45, Z=60</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetrotation">NoahGetRotation</a> - Retrieves the rotation of the specified <em>target</em>.</li>
            <li><a href="#noahgetposition">NoahGetRotation2D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetrotationx">NoahGetRotationX</a> - Fetches the x-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationy">NoahGetRotationY</a> - Fetches the y-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationz">NoahGetRotationZ</a> - Fetches the z-component of the rotation for the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetrotationx">NoahGetRotationX</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetRotationX(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>Retrieves the x-component of the rotation for the specified <em>target</em>, represented in Euler angles and measured in degrees. This function is meaningful only for <strong>3D elements</strong>; applying it to a <strong>2D element</strong> results in a return value of <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a float number representing the x-component of the target's rotation in degrees for 3D elements. For 2D elements, returns <strong>0</strong>.</p>
          <h4 id="example">Example</h4>
          <p>To get the X-axis rotation of &quot;my_3dTarget&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            x_rotation = NoahGetRotationX(<span className="hljs-string">"my_3dTarget"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"X-axis rotation: "</span> .. x_rotation)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>X-axis rotation: 90
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetrotation">NoahGetRotation</a> - Retrieves the rotation of the specified <em>target</em>.</li>
            <li><a href="#noahgetposition">NoahGetRotation2D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetposition3d">NoahGetRotation3D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetrotationy">NoahGetRotationY</a> - Fetches the y-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationz">NoahGetRotationZ</a> - Fetches the z-component of the rotation for the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetrotationy">NoahGetRotationY</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetRotationY(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Fetches the y-component of the rotation for the specified <em>target</em>, expressed in Euler angles and measured in degrees. This evaluation is pertinent solely to <strong>3D elements</strong>; targeting a <strong>2D element</strong> yields a return value of <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a float number indicating the y-component of the target's rotation in degrees for 3D elements. For 2D elements, returns <strong>0</strong>.</p>
          <h4 id="example">Example</h4>
          <p>To get the Y-axis rotation of &quot;my_3dTarget&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            y_rotation = NoahGetRotationY(<span className="hljs-string">"my_3dTarget"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"Y-axis rotation: "</span> .. y_rotation)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Y-axis rotation: 45
          </div></code></pre><h4 id="see-also">See Also</h4>
          
          <ul>
            <li><a href="#noahgetrotation">NoahGetRotation</a> - Retrieves the rotation of the specified <em>target</em>.</li>
            <li><a href="#noahgetposition">NoahGetRotation2D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>2D element</strong>.</li>
            <li><a href="#noahgetposition3d">NoahGetRotation3D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetrotationx">NoahGetRotationX</a> - Fetches the x-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationz">NoahGetRotationZ</a> - Fetches the z-component of the rotation for the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetrotationz">NoahGetRotationZ</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetRotationZ(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Fetches the z-component of the rotation for the specified <em>target</em>, expressed in Euler angles and measured in degrees. This evaluation is pertinent solely to <strong>3D elements</strong>; targeting a <strong>2D element</strong> yields a return value of <strong>0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a float number indicating the z-component of the target's rotation in degrees for 3D elements. For 2D elements, returns <strong>0</strong>.</p>
          <h4 id="example">Example</h4>
          <p>To get the Z-axis rotation of &quot;my_3dTarget&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>z_rotation = NoahGetRotationY(<span className="hljs-string">"my_3dTarget"</span>)<br/>
                <span className="hljs-built_in">print</span>(<span className="hljs-string">"Z-axis rotation: "</span> .. z_rotation)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Z-axis rotation: 60</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetrotation">NoahGetRotation</a> - Retrieves the rotation of the specified <em>target</em>.</li>
            <li><a href="#noahgetposition3d">NoahGetRotation3D</a> - Retrieves the rotation of the specified <em>target</em> that is a <strong>3D element</strong>.</li>
            <li><a href="#noahgetrotationx">NoahGetRotationX</a> - Fetches the x-component of the rotation for the specified <em>target</em></li>
            <li><a href="#noahgetrotationy">NoahGetRotationY</a> - Fetches the y-component of the rotation for the specified <em>target</em></li>
          </ul>
          <hr />
          <h3 id="noahsetscale2d">NoahSetScale2D</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetScale2D(string target, number x, number y)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Sets the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element, according to the <em>x</em> and <em>y</em> scale factors. This function is specifically designed for <strong>Group2D</strong> elements. Applying it to elements of other types will result in an error message from Noah, and the scale will not be altered.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the Group2D element.</li>
            <li><strong><code>x</code> (number), <code>y</code> (number):</strong> The scale factors along the X and Y axes.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To scale a Group2D element &quot;exampleGroup2D&quot; by 2x on the X-axis and 3x on the Y-axis:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>NoahSetScale2D(
                <span className="hljs-string">"exampleGroup2D"</span>,
                <span className="hljs-number">2</span>,
                <span className="hljs-number">3</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetscale3d">NoahSetScale3D</a> - Sets the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.
            </li>
            <li>
              <a href="#noahsetscalex">NoahSetScaleX</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the X-axis.
            </li>
            <li>
              <a href="#noahsetscaley">NoahSetScaleY</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Y-axis.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetscale3d">NoahSetScale3D</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetScale3D(string target, number x, number y, number z)</code></p>
          <h4 id="description">Description</h4>
          <p>Sets the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element, to the specified <em>x, y, z</em> scale factors. This function exclusively affects <strong>Group3D</strong> and <strong>Model3D</strong> elements. Targeting elements of other types will cause Noah to log an error message, with no scaling performed.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the Group3D or Model3D element.</li>
            <li><strong><code>x</code> (number), <code>y</code> (number), <code>z</code> (number):</strong> The scale factors for the X, Y, and Z axes.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To scale a Group3D element &quot;exampleGroup3D&quot; to half its size on all axes:</p>
          <pre className="hljs hljs-content"><code><div>
            NoahSetScale3D(<span className="hljs-string">"exampleGroup3D"</span>, <span className="hljs-number">2</span>, <span className="hljs-number">3</span>, <span className="hljs-number">4</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetscale2d">NoahSetScale2D</a> - Sets the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element.</li>
            <li><a href="#noahsetscalex">NoahSetScaleX</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the X-axis.</li>
            <li><a href="#noahsetscaley">NoahSetScaleY</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Y-axis.</li>
            <li><a href="#noahsetscalez">NoahSetScaleZ</a> - Scales the specified <em>target</em>, a <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Z-axis.</li>
          </ul>
          <hr />
          <h3 id="noahsetscalex">NoahSetScaleX</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetScaleX(string target, number x)</code></p>
          <h4 id="description">Description</h4>
          <p>Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the X-axis to the given <em>x</em> value, with the Y and Z scale factors remaining unchanged. This function is applicable to <strong>Group2D</strong>, <strong>Group3D</strong>, and <strong>Model3D</strong> elements. Using it on other types of elements will result in an error message from Noah, and the scale adjustment will not occur.</p><h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>x</code> (number):</strong> The scale factor for the X-axis.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To scale &quot;exampleElement&quot; by 2x on the X-axis:</p>
          <pre className="hljs hljs-content"><code><div>
            NoahSetScaleX(<span className="hljs-string">"exampleElement"</span>, <span className="hljs-number">2</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetscale2d">NoahSetScale2D</a> - Sets the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element.</li>
            <li><a href="#noahsetscale3d">NoahSetScale3D</a> - Sets the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahsetscaley">NoahSetScaleY</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Y-axis.</li>
            <li><a href="#noahsetscalez">NoahSetScaleZ</a> - Scales the specified <em>target</em>, a <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Z-axis.</li>
          </ul>
          <hr />
          <h3 id="noahsetscaley">NoahSetScaleY</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetScaleY(string target, number y)</code></p>
          <h4 id="description">Description</h4>
          <p>Modifies the scale of the specified <em>target</em>, which can be a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Y-axis to the assigned <em>y</em> value, while keeping the X and Z scale factors constant. This function supports <strong>Group2D</strong>, <strong>Group3D</strong>, and <strong>Model3D</strong> elements exclusively. Targeting other element types will invoke an error message from Noah without affecting the scale.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>y</code> (number):</strong> The scale factor for the Y-axis.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To adjust the Y-axis scale of &quot;exampleElement&quot; by 3x:</p>
          <pre className="hljs hljs-content"><code><div>
            NoahSetScaleY(<span className="hljs-string">"exampleElement"</span>, <span className="hljs-number">3</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetscale2d">NoahSetScale2D</a> - Sets the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element.</li>
            <li><a href="#noahsetscale3d">NoahSetScale3D</a> - Sets the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahsetscalex">NoahSetScaleX</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the X-axis.</li>
            <li><a href="#noahsetscalez">NoahSetScaleZ</a> - Scales the specified <em>target</em>, a <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Z-axis.</li>
          </ul>
          <hr />
          <h3 id="noahsetscalez">NoahSetScaleZ</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetScaleZ(string target, number z)</code></p>
          <h4 id="description">Description</h4>
          <p>Modifies the scale of the specified <em>target</em>, which can be a <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Z-axis to the assigned <em>z</em> value, while keeping the X and Y scale factors constant. This function supports <strong>Group3D</strong>, and <strong>Model3D</strong> elements exclusively. Targeting other element types will invoke an error message from Noah without affecting the scale.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
            <li><strong><code>z</code> (number):</strong> The scale factor for the Z-axis.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To adjust the Z-axis scale of &quot;exampleElement&quot; by 3x:</p>
          <pre className="hljs hljs-content"><code><div>
            NoahSetScaleY(<span className="hljs-string">"exampleElement"</span>, <span className="hljs-number">4</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahsetscale3d">NoahSetScale3D</a> - Sets the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahsetscalex">NoahSetScaleX</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the X-axis.</li>
            <li><a href="#noahsetscaley">NoahSetScaleY</a> - Scales the specified <em>target</em>, a <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> element, along the Y-axis.</li></ul>
          <hr />
          <h3 id="noahgetscale">NoahGetScale</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetScale(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the scale of the specified <em>target</em>, returning a <strong>vec3</strong> object for accessing the scale values along the x, y, and z axes. This function is applicable to various element types, with specific considerations:
          </p>
          <ul>
            <li>For <strong>Group3D</strong> and <strong>Model3D</strong> elements, all x, y, and z values are meaningful.</li>
            <li>For <strong>2D elements</strong> like <em>Quad</em>, <em>Image</em>, or <em>Text</em>, the z value is always <strong>0</strong>, and x, y values represent the width and height as scale factors, respectively.</li>
            <li>Other element types default to a z value of <strong>0</strong>.</li>
          </ul>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a <strong>vec3</strong> object representing the scale of the target.</p>
          <h4 id="example">Example</h4>
          <p>To get the x scale factor of &quot;my_target&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            x_scale = NoahGetScale(<span className="hljs-string">"my_target"</span>).x<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"Scale: X="</span> .. x_scale)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Scale: X=2
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetscale2d">NoahGetScale2D</a> - Obtains the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element.</li>
            <li><a href="#noahgetscale3d">NoahGetScale3D</a> - Obtains the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahgetscalex">NoahGetScaleX</a> - Obtains the x scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscaley">NoahGetScaleY</a> - - Obtains the y scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscalez">NoahGetScaleZ</a> - - Obtains the z scale factor of the specified <em>target</em>.</li>
          </ul>
          <hr /><h3 id="noahgetscale2d">NoahGetScale2D</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetScale2D(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Fetches the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element, providing a <strong>vec2</strong> object for x and y scale access. This function is designed exclusively for <strong>Group2D</strong> elements. Attempting to use it on other types of elements will result in an error and a default return value of <strong>vec2(1, 1)</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the Group2D target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a <strong>vec2</strong> object representing the scale of the target in the x and y dimensions.</p>
          <h4 id="example">Example</h4>
          <p>To get the scale of a Group2D element &quot;my_group2d&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-keyword">local</span> scale = NoahGetScale2D(<span className="hljs-string">"my_group2d"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"Scale: X="</span> .. scale.x .. <span className="hljs-string">", Y="</span> .. scale.y)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Scale: X=2, Y=3
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetscale">NoahGetScale</a> - Obtains the scale of the specified <em>target</em>.</li>
            <li><a href="#noahgetscale3d">NoahGetScale3D</a> - Obtains the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahgetscalex">NoahGetScaleX</a> - Obtains the x scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscaley">NoahGetScaleY</a> - - Obtains the y scale factor of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetscale3d">NoahGetScale3D</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetScale3D(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Obtains the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element, by returning a <strong>vec3</strong> object. This enables access to the scale factors along the x, y, and z axes. This function is applicable solely to <strong>Group3D</strong> and <strong>Model3D</strong> elements. Using it on different element types will cause Noah to issue an error and return <strong>vec3(1, 1, 1)</strong> as a default.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the Group3D or Model3D target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a <strong>vec3</strong> object indicating the scale of the target in three dimensions.</p>
          <h4 id="example">Example</h4>
          <p>To get the scale of a Model3D element &quot;my_model3d&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            <span className="hljs-keyword">local</span> scale = NoahGetScale3D(<span className="hljs-string">"my_model3d"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"Scale: X="</span> .. scale.x .. <span className="hljs-string">", Y="</span> .. scale.y .. <span className="hljs-string">", Z="</span> .. scale.z)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Scale: X=2, Y=3, Z=4
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetscale">NoahGetScale</a> - Obtains the scale of the specified <em>target</em>.</li>
            <li><a href="#noahgetscale2d">NoahGetScale2D</a> - Obtains the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element.</li>
            <li><a href="#noahgetscalex">NoahGetScaleX</a> - Obtains the x scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscaley">NoahGetScaleY</a> - - Obtains the y scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscalez">NoahGetScaleZ</a> - - Obtains the z scale factor of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetscalex">NoahGetScaleX</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetScaleX(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Retrieves the x scale factor of the specified <em>target</em>. This function returns a float number representing the scale along the X-axis. For <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> elements, the returned value corresponds to the x scale factor. For <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, or <strong>Video2D</strong> elements, Noah returns the element's <em>width</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a float number representing the x scale or width of the target.</p>
          <h4 id="example">Example</h4>
          <p>To get the x scale factor of &quot;my_target&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            x_scale = NoahGetScaleX(<span className="hljs-string">"my_target"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"X scale: "</span> .. x_scale)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>X Scale: 2
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetscale">NoahGetScale</a> - Obtains the scale of the specified <em>target</em>.</li>
            <li><a href="#noahgetscale2d">NoahGetScale2D</a> - Obtains the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element.</li>
            <li><a href="#noahgetscale3d">NoahGetScale3D</a> - Obtains the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahgetscaley">NoahGetScaleY</a> - - Obtains the y scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscalez">NoahGetScaleZ</a> - - Obtains the z scale factor of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetscaley">NoahGetScaleY</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetScaleY(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Fetches the y scale factor of the specified <em>target</em>. This function outputs a float number that denotes the scale along the Y-axis. For <strong>Group2D</strong>, <strong>Group3D</strong>, or <strong>Model3D</strong> elements, it returns the y scale factor. For <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Image2D</strong>, <strong>Image3D</strong>, <strong>Text2D</strong>, <strong>Text3D</strong>, or <strong>Video2D</strong> elements, Noah provides the element's <em>height</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a float number indicating the y scale or height of the target.</p>
          <h4 id="example">Example</h4>
          <p>To get the y scale factor of &quot;my_target&quot;:</p>
          <pre className="hljs hljs-content"><code><div>
            y_scale = NoahGetScaleY(<span className="hljs-string">"my_target"</span>)<br/>
            <span className="hljs-built_in">print</span>(<span className="hljs-string">"Y scale: "</span> .. y_scale)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Y Scale: 3
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetscale">NoahGetScale</a> - Obtains the scale of the specified <em>target</em>.</li>
            <li><a href="#noahgetscale2d">NoahGetScale2D</a> - Obtains the scale of the specified <em>target</em>, which is a <strong>Group2D</strong> element.</li>
            <li><a href="#noahgetscale3d">NoahGetScale3D</a> - Obtains the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahgetscalex">NoahGetScaleX</a> - Obtains the x scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscalez">NoahGetScaleZ</a> - - Obtains the z scale factor of the specified <em>target</em>.</li>
          </ul>
          <hr />
          <h3 id="noahgetscalez">NoahGetScaleZ</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetScaleZ(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Determines the z scale factor of the specified <em>target</em>. This function yields a float number. For <strong>Group3D</strong> or <strong>Model3D</strong> elements, it returns the z scale factor. For elements of other types, Noah defaults the return value to <strong>1</strong>, indicating no scaling along the Z-axis.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li><strong><code>target</code> (string):</strong> The unique identifier for the target element.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns a float number representing the z scale factor or a default value of <strong>1</strong> for non-3D elements.</p>
          <h4 id="example">Example</h4>
          <p>To get the z scale factor of &quot;my_3dTarget&quot;:</p>
          <pre className="hljs hljs-content">
            <code><div>z_scale = NoahGetScaleZ(<span className="hljs-string">"my_3dTarget"</span>)<br/>
              <span className="hljs-built_in">print</span>(<span className="hljs-string">"Z scale: "</span> .. z_scale)
            </div></code></pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content"><code><div>Z Scale: 4
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li><a href="#noahgetscale">NoahGetScale</a> - Obtains the scale of the specified <em>target</em>.</li>
            <li><a href="#noahgetscale3d">NoahGetScale3D</a> - Obtains the scale of the specified <em>target</em>, either a <strong>Group3D</strong> or <strong>Model3D</strong> element.</li>
            <li><a href="#noahgetscalex">NoahGetScaleX</a> - Obtains the x scale factor of the specified <em>target</em>.</li>
            <li><a href="#noahgetscaley">NoahGetScaleY</a> - Obtains the y scale factor of the specified <em>target</em>.</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIBasicTransformPage;
