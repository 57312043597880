import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab1Page3() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab1_title">Lab1 UI Layout Construction</h1>
          <h1 id="noah-basic-elements">Noah Basic Elements</h1>
          <p>Noah UI is defined using XML and is composed of various <strong>Basic Noah Elements</strong>, which resemble tags in HTML. You can customize the appearance, translation, rotation, and other properties of these elements through their attributes.</p>
          <p>In the <code>demo_hello_noah</code> project, you will encounter five of the most commonly used <strong>Basic Noah Elements</strong>:</p>
          <ul>
            <li><strong>Scene</strong></li>
            <li><strong>Group2D</strong></li>
            <li><strong>Quad2D</strong></li>
            <li><strong>Text2D</strong></li>
            <li><strong>Image2D</strong></li>
          </ul>
          <p>Every Noah element requires a unique <code>id</code> attribute, which serves as the unique identifier for that element. No two elements within the same scene can share the same <code>id</code>. Each of these elements will be introduced below.</p>
          <br /><hr />
          <h3 id="1-scene">1. <strong>Scene</strong></h3>
          <p>The root node of the entire scene. It has key functions:</p>
          <ul>
            <li>Define the screen specifications by attributes <code>sizepx</code> and <code>sizemm</code>, which the units are pixel and millimeters respectively.</li>
            <li>Specify the viewer's position by attribute <code>eyepos</code>, which is useful in the 3D UI construction.</li>
          </ul>

          <br /><hr />
          <h3 id="2-group2d">2. <strong>Group2D</strong></h3>
          <p>A container node that does not render anything on its own but can hold multiple elements in 2D space. Its key features are:</p>
          <ul>
            <li>Useful for grouping elements to manipulate them collectively.</li>
            <li>The <code>enabled</code> attribute can toggle whether elements within the group are active, without having to modify each child element individually.</li>
          </ul>

          <br /><hr />
          <h3 id="3-quad2d">3. <strong>Quad2D</strong></h3>
          <p>A <strong>Quad2D</strong> is a flat rectangle element used to render basic shapes. It is highly customizable and supports the following attributes:</p>
          <ul>
            <li><code>w</code>, <code>h</code>: Control the width and height of the rectangle.</li>
            <li><code>r</code>: Control rotation.</li>
            <li><code>t</code>: Control the position.</li>
            <li><code>anchor</code>: Specifies the reference point of the element. Requires two values, representing the x-axis and y-axis positions, and works together with the <code>t</code> attribute.
              <ul>
                <li>Value Range:
                <ul>
                  <li><code>0</code> represents the <em>Left/Top</em> side of the Quad.</li>
                  <li><code>1</code> represents the <em>Right/Bottom</em> side of the Quad.</li>
                </ul>
                </li>
                <li>The anchor point does not have to be within the Quad2D itself. For example, an external anchor point such as <code>anchor=&quot;-5.0, 8.4&quot;</code> is valid.</li>
              </ul>
            </li>
            <li><code>color</code>:<br />
            Defines the color used to render the Quad2D element. The color is specified using the <code>#AARRGGBB</code> hexadecimal format, with the following rendering options:
              <ol>
                <li>One Color:
                  <ul>
                    <li>Applies a single uniform color to the entire rectangle.</li>
                  </ul>
                </li>
                <li>Two Colors:
                  <ul>
                    <li>Applies a gradient using two colors, with the first color on the left side and the second color on the right side.</li>
                  </ul>
                </li>
                <li>Four Colors:
                  <ul>
                    <li>Applies a gradient using four colors, with colors representing the top-left, top-right, bottom-left, and bottom-right corners, blended smoothly across the rectangle.</li>
                  </ul>
                </li>
              </ol>
            </li>
          </ul>

          <br /><hr />
          <h3 id="4-text2d">4. <strong>Text2D</strong></h3>
          <p>A flat element for displaying text. Its key attributes are:</p>
          <ul>
            <li><code>text</code>: Specifies the text content to display.</li>
            <li><code>size</code>: Controls the font size.</li>
            <li><code>r</code>: Controls rotation.</li>
            <li><code>t</code>: Controls the position.</li>
            <li><code>font</code>: Specifies the font type. All referenced fonts must be defined in the font configuration file (the path is config/font.yml) to work properly.</li>
          </ul>

          <br /><hr />
          <h3 id="5-image2d">5. <strong>Image2D</strong></h3>
          <p>A flat element for displaying images. Its key attributes are:</p>
          <ul>
            <li><code>src</code>: Specifies the file path of the image to be displayed. This attribute is mandatory.</li>
            <li><code>w</code>, <code>h</code>: Control the width and height of the image.</li>
            <li><code>r</code>: Controls rotation.</li>
            <li><code>t</code>: Controls the position.</li>
            <li><code>anchor</code>: Specifies the reference point of the element. Requires two values, representing the x-axis and y-axis positions, and works together with the <code>t</code> attribute.
              <ul>
                <li>Value Range:
                  <ul>
                    <li><code>0</code> represents the <em>Left/Top</em> side of the Quad.</li>
                    <li><code>1</code> represents the <em>Right/Bottom</em> side of the Quad.</li>
                  </ul>
                </li>
                <li>The anchor point does not have to be within the Quad2D itself. For example, an external anchor point such as <code>anchor=&quot;-5.0, 8.4&quot;</code> is valid.</li>
              </ul>
            </li>
          </ul>
          <hr />
          <p>For more detailed information about these elements, refer to the <a href="/UI_layout_construction"><code>UI_layout_construction</code></a> document.</p>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab1Page3;
