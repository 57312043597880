import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahLuaAPIBasicCameraPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Camera APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Eye Position</h3>
              <li><a href="#noahseteyeposition">NoahSetEyePosition</a></li>
              <li><a href="#noahseteyepositionx">NoahSetEyePositionX</a></li>
              <li><a href="#noahseteyepositiony">NoahSetEyePositionY</a></li>
              <li><a href="#noahseteyepositionz">NoahSetEyePositionZ</a></li>
              <li><a href="#noahgeteyeposition">NoahGetEyePosition</a></li>
              <li><a href="#noahgeteyepositionx">NoahGetEyePositionX</a></li>
              <li><a href="#noahgeteyepositiony">NoahGetEyePositionY</a></li>
              <li><a href="#noahgeteyepositionz">NoahGetEyePositionZ</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Camera</h3>
              <li><a href="#noahsetlookat">NoahSetLookAt</a></li>
              <li><a href="#noahsetfovy">NoahSetFovY</a></li>
              <li><a href="#noahsetaspectratio">NoahSetAspectRatio</a></li>
              <li><a href="#noahsetparallaxenabled">NoahSetParallaxEnabled</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Camera Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahseteyeposition">NoahSetEyePosition</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>
              NoahSetEyePosition(string scene, number x, number y, number z)
            </code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            The <code>NoahSetEyePosition</code> function is designed to adjust
            the viewpoint within a given scene to a new position specified by{' '}
            <em>x</em>, <em>y</em>, and <em>z</em> coordinates (in millimeters).
            This operation is intended exclusively for <strong>Scene</strong>{' '}
            elements. If the provided <em>scene</em> identifier does not
            correspond to a <strong>Scene</strong> element, Noah will log an
            error message, and the eye position will remain unchanged.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>{' '}
              The unique identifier for the scene element, as defined in{' '}
              <code>main.xml</code>.
            </li>
            <li>
              <strong>
                <code>x</code> (number):
              </strong>{' '}
              The target x-coordinate (horizontal position) for the
              camera&apos;s eye position in millimeters.
            </li>
            <li>
              <strong>
                <code>y</code> (number):
              </strong>{' '}
              The target y-coordinate (vertical position) for the camera&apos;s
              eye position in millimeters.
            </li>
            <li>
              <strong>
                <code>z</code> (number):
              </strong>{' '}
              The target z-coordinate (depth position) for the camera&apos;s eye
              position in millimeters.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To move the eye position to coordinates (100mm, 200mm, 50mm) within
            the scene identified by &quot;main&quot;:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetEyePosition(
                <span className="hljs-string">&quot;main&quot;</span>,{' '}
                <span className="hljs-number">100</span>,{' '}
                <span className="hljs-number">200</span>,{' '}
                <span className="hljs-number">50</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahseteyepositionx">NoahSetEyePositionX</a> - Sets the
              camera&apos;s horizontal (X-axis) position.
            </li>
            <li>
              <a href="#noahseteyepositiony">NoahSetEyePositionY</a> - Sets the
              camera&apos;s vertical (Y-axis) position.
            </li>
            <li>
              <a href="#noahseteyepositionz">NoahSetEyePositionZ</a> - Sets the
              camera&apos;s depth (Z-axis) position.
            </li>
          </ul>
          <hr />
          <h3 id="noahseteyepositionx">NoahSetEyePositionX</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetEyePositionX(string scene, number x)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            The <code>NoahSetEyePositionX</code> function enables the adjustment
            of the camera&apos;s horizontal (X-axis) position in millimeters,
            leaving the Y and Z coordinates unaffected. This function is
            specifically designed for use with <strong>Scene</strong> elements.
            Should the specified
            <em>scene</em> not correspond to a <strong>Scene</strong> element,
            Noah will record an error message, and the camera&apos;s position
            will remain static.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
            <li>
              <strong>
                <code>x</code> (number):
              </strong>
              The new X position for the camera, in millimeters.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To adjust the camera&apos;s X-axis position to 100mm within the
            scene identified as &quot;main&quot;, use the following command:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetEyePositionX(
                <span className="hljs-string">&quot;main&quot;</span>,{' '}
                <span className="hljs-number">100</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahseteyeposition">NoahSetEyePosition</a> - Sets the
              camera&apos;s position.
            </li>
            <li>
              <a href="#noahseteyepositiony">NoahSetEyePositionY</a> - Sets the
              camera&apos;s vertical (Y-axis) position.
            </li>
            <li>
              <a href="#noahseteyepositionz">NoahSetEyePositionZ</a> - Sets the
              camera&apos;s depth (Z-axis) position.
            </li>
          </ul>
          <blockquote>
            <p>
              Note: This function does not return any value and is applicable
              solely to <strong>Scene</strong> elements to adjust the
              camera&apos;s X-axis position while maintaining the current Y and
              Z positions.
            </p>
          </blockquote>
          <hr />
          <h3 id="noahseteyepositiony">NoahSetEyePositionY</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetEyePositionY(string scene, number y)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            The <code>NoahSetEyePositionY</code> function enables the adjustment
            of the camera&apos;s vertical (Y-axis) position in millimeters,
            leaving the X and Z coordinates unaffected. This function is
            specifically designed for use with <strong>Scene</strong> elements.
            Should the specified <em>scene</em> not correspond to a{' '}
            <strong>Scene</strong> element, Noah will record an error message,
            and the camera&apos;s position will remain static.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>{' '}
              The unique identifier for the scene element, as defined in{' '}
              <code>main.xml</code>.
            </li>
            <li>
              <strong>
                <code>y</code> (number):
              </strong>{' '}
              The new Y position for the camera, in millimeters.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To adjust the camera&apos;s Y-axis position to 200mm within the
            scene identified as &quot;main&quot;, use the following command:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetEyePositionY(
                <span className="hljs-string">&quot;main&quot;</span>,{' '}
                <span className="hljs-number">200</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahseteyeposition">NoahSetEyePosition</a> - Sets the
              camera&apos;s position.
            </li>
            <li>
              <a href="#noahseteyepositionx">NoahSetEyePositionX</a> - Sets the
              camera&apos;s horizontal (X-axis) position.
            </li>
            <li>
              <a href="#noahseteyepositionz">NoahSetEyePositionZ</a> - Sets the
              camera&apos;s depth (Z-axis) position.
            </li>
          </ul>
          <blockquote>
            <p>
              Note: This function does not return any value and is applicable
              solely to <strong>Scene</strong> elements to adjust the
              camera&apos;s Y-axis position while maintaining the current X and
              Z positions.
            </p>
          </blockquote>
          <hr />
          <h3 id="noahseteyepositionz">NoahSetEyePositionZ</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetEyePositionZ(string scene, number z)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            The <code>NoahSetEyePositionZ</code> function enables the adjustment
            of the camera&apos;s depth (Z-axis) position in millimeters, leaving
            the X and Y coordinates unaffected. This function is specifically
            designed for use with <strong>Scene</strong> elements. Should the
            specified <em>scene</em> not correspond to a <strong>Scene</strong>
            element, Noah will record an error message, and the camera&apos;s
            position will remain static.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>{' '}
              The unique identifier for the scene element, as defined in{' '}
              <code>main.xml</code>.
            </li>
            <li>
              <strong>
                <code>z</code> (number):
              </strong>{' '}
              The new Z position for the camera, in millimeters.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To adjust the camera&apos;s Z-axis position to 50mm within the scene
            identified as &quot;main&quot;, use the following command:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetEyePositionZ(
                <span className="hljs-string">&quot;main&quot;</span>,{' '}
                <span className="hljs-number">50</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahseteyeposition">NoahSetEyePosition</a> - Sets the
              camera&apos;s position.
            </li>
            <li>
              <a href="#noahseteyepositionx">NoahSetEyePositionX</a> - Sets the
              camera&apos;s horizontal (X-axis) position.
            </li>
            <li>
              <a href="#noahseteyepositiony">NoahSetEyePositionY</a> - Sets the
              camera&apos;s vertical (Y-axis) position.
            </li>
          </ul>
          <blockquote>
            <p>
              Note: This function does not return any value and is applicable
              solely to <strong>Scene</strong> elements to adjust the
              camera&apos;s Z-axis position while maintaining the current X and
              Y positions.
            </p>
          </blockquote>
          <hr />
          <h3 id="noahgeteyeposition">NoahGetEyePosition</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetEyePosition(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the current eye position within a specified scene,
            returning a <strong>vec3</strong> object. This object allows access
            to the x, y, and z coordinates through the dot operator. This
            function is tailored for <strong>Scene</strong> elements
            exclusively. If the <em>scene</em> parameter does not match a
            <strong>Scene</strong> element, Noah will log an error message and
            return a default <strong>vec3</strong> object with values
            <strong>{(0, 0, 0)}</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec3</strong> object representing the eye&apos;s
            current position within the scene.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To obtain the x coordinate of the eye position within
            &quot;main&quot;:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                eye_position_x = NoahGetEyePosition(
                <span className="hljs-string">&quot;main&quot;</span>).x
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgeteyepositionx">NoahGetEyePositionX</a> - Retrieves
              the x value of the eye position.
            </li>
            <li>
              <a href="#noahgeteyepositiony">NoahGetEyePositionY</a> - Retrieves
              the y value of the eye position.
            </li>
            <li>
              <a href="#noahgeteyepositionz">NoahGetEyePositionZ</a> - Retrieves
              the z value of the eye position.
            </li>
          </ul>
          <hr />
          <h3 id="noahgeteyepositionx">NoahGetEyePositionX</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetEyePositionX(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Fetches the x-coordinate of the eye position within a specified
            scene. This function is exclusively applicable to
            <strong>Scene</strong> elements. If the <em>scene</em> parameter
            does not match a <strong>Scene</strong> element, Noah will log an
            error message and return <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>{' '}
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the x-coordinate value of the eye position.</p>
          <h4 id="example">Example</h4>
          <p>
            To retrieve the X coordinate (horizontal position) of the
            camera&apos;s eye position within a scene identified by
            &quot;main&quot;, you would use the <code>NoahGetEyePositionX</code>
            function like so:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> eyePositionX =
                NoahGetEyePositionX(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">
                  &quot;The X position of the camera&apos;s eye is: &quot;
                </span>
                .. eyePositionX ..
                <span className="hljs-string">&quot;mm&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>The X position of the camera&apos;s eye is: 100mm</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgeteyeposition">NoahGetEyePosition</a> - Retrieves
              the eye&apos;s current position.
            </li>
            <li>
              <a href="#noahgeteyepositiony">NoahGetEyePositionY</a> - Retrieves
              the y value of the eye position.
            </li>
            <li>
              <a href="#noahgeteyepositionz">NoahGetEyePositionZ</a> - Retrieves
              the z value of the eye position.
            </li>
          </ul>
          <hr />
          <h3 id="noahgeteyepositiony">NoahGetEyePositionY</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetEyePositionY(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Obtains the y-coordinate of the eye position within a specified
            scene. This function is intended solely for <strong>Scene</strong>
            elements. If the <em>scene</em> parameter does not match a{' '}
            <strong>Scene</strong> element, Noah will issue an error message and
            return <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>{' '}
              The unique identifier for the scene element, as defined in{' '}
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the y-coordinate value of the eye position.</p>
          <h4 id="example">Example</h4>
          <p>
            To retrieve the Y coordinate (vertical position) of the
            camera&apos;s eye position within a scene identified by
            &quot;main&quot;, you would use the
            <code>NoahGetEyePositionY</code> function like so:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> eyePositionY =
                NoahGetEyePositionY(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">
                  &quot;The Y position of the camera&apos;s eye is: &quot;
                </span>
                .. eyePositionY ..
                <span className="hljs-string">&quot;mm&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>The Y position of the camera&apos;s eye is: 200mm</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgeteyeposition">NoahGetEyePosition</a> - Retrieves
              the eye&apos;s current position.
            </li>
            <li>
              <a href="#noahgeteyepositionx">NoahGetEyePositionX</a> - Retrieves
              the x value of the eye position.
            </li>
            <li>
              <a href="#noahgeteyepositionz">NoahGetEyePositionZ</a> - Retrieves
              the z value of the eye position.
            </li>
          </ul>
          <hr />
          <h3 id="noahgeteyepositionz">NoahGetEyePositionZ</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetEyePositionZ(string scene)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the z-coordinate of the eye position within a specified
            scene.This function is applicable only to <strong>Scene</strong>
            elements. If the <em>scene</em> parameter does not align with a
            <strong>Scene</strong> element, Noah will log an error message and
            return <strong>0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>scene</code> (string):
              </strong>
              The unique identifier for the scene element, as defined in
              <code>main.xml</code>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the z-coordinate value of the eye position.</p>
          <h4 id="example">Example</h4>
          <p>
            To retrieve the Z coordinate (depth position) of the camera&apos;s
            eye position within a scene identified by &quot;main&quot;, you
            would use the <code>NoahGetEyePositionZ</code> function like so:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> eyePositionZ =
                NoahGetEyePositionZ(
                <span className="hljs-string">&quot;main&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">
                  &quot;The Z position of the camera&apos;s eye is: &quot;
                </span>
                .. eyePositionZ ..
                <span className="hljs-string">&quot;mm&quot;</span>)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>The Z position of the camera&apos;s eye is: 50mm</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgeteyeposition">NoahGetEyePosition</a> - Retrieves
              the eye&apos;s current position.
            </li>
            <li>
              <a href="#noahgeteyepositionx">NoahGetEyePositionX</a> - Retrieves
              the x value of the eye position.
            </li>
            <li>
              <a href="#noahgeteyepositiony">NoahGetEyePositionY</a> - Retrieves
              the y value of the eye position.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetlookat">NoahSetLookAt</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>
              NoahSetLookAt(string name, number x, number y, number z)
            </code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Make camera on <em>name</em> look toward (<em>x</em>, <em>y</em>,
            <em>z</em>). If <em>name</em> is not a <strong>ScreenView</strong>,
            Noah logs an error message and have no effect.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code> (string):
              </strong>
              The unique identifier for the scene element, must be
              <strong>ScreenView</strong>.
            </li>
            <li>
              <strong>
                <code>x</code> (number):
              </strong>
              x-coordinate of look position.
            </li>
            <li>
              <strong>
                <code>y</code> (number):
              </strong>
              y-coordinate of look position.
            </li>
            <li>
              <strong>
                <code>z</code> (number):
              </strong>
              z-coordinate of look position.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>
            To set camera in &quot;main&quot; loot toward (0.0, 0.0, -90.0):
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetLookAt(
                <span className="hljs-string">&quot;main&quot;</span>,
                <span className="hljs-number">0.0</span>,
                <span className="hljs-number">0.0</span>,
                <span className="hljs-number">-90.0</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetfovy">NoahSetFovY</a> - Set fovy of camera.
            </li>
            <li>
              <a href="#noahsetaspectratio">NoahSetAspectRatio</a> - Set aspect
              ratio of camera.
            </li>
            <li>
              <a href="#noahsetparallaxenabled">NoahSetParallaxEnabled</a> - Set
              camera projection mode.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetfovy">NoahSetFovY</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetFovY(string name, number fov_y)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Set <em>fov_y</em> to camera on <em>name</em>. If <em>name</em> is
            not a <strong>ScreenView</strong>, Noah logs an error message and
            have no effect.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code> (string):
              </strong>{' '}
              The unique identifier for the scene element, must be{' '}
              <strong>ScreenView</strong>.
            </li>
            <li>
              <strong>
                <code>fov_y</code> (number):
              </strong>{' '}
              field of view in degree.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set camera fovy in &quot;main&quot; to 45.0:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetFovY(
                <span className="hljs-string">&quot;main&quot;</span>,
                <span className="hljs-number">45.0</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetlookat">NoahSetLookAt</a> - Set position camera
              look toward.
            </li>
            <li>
              <a href="#noahsetaspectratio">NoahSetAspectRatio</a> - Set aspect
              ratio of camera.
            </li>
            <li>
              <a href="#noahsetparallaxenabled">NoahSetParallaxEnabled</a> - Set
              camera projection mode.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetaspectratio">NoahSetAspectRatio</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetAspectRatio(string name, number aspect_ratio)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Set <em>aspect_ratio</em> to camera on <em>name</em>. If
            <em>name</em> is not a <strong>ScreenView</strong>, Noah logs an
            error message and have no effect.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code>(string):
              </strong>
              The unique identifier for the scene element, must be
              <strong>ScreenView</strong>.
            </li>
            <li>
              <strong>
                <code>aspect_ratio</code> (number):
              </strong>
              aspect ratio value of camera.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set camera aspect ratio in &quot;main&quot; to 1.78:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetAspectRatio(
                <span className="hljs-string">&quot;main&quot;</span>,
                <span className="hljs-number">1.78</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetlookat">NoahSetLookAt</a> - Set position camera
              look toward.
            </li>
            <li>
              <a href="#noahsetfovy">NoahSetFovY</a> - Set fovy of camera.
            </li>
            <li>
              <a href="#noahsetparallaxenabled">NoahSetParallaxEnabled</a> - Set
              camera projection mode.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetparallaxenabled">NoahSetParallaxEnabled</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>SetParallaxEnabled(string name, bool isEnabled)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Set camera on <em>name</em> to parallax mode if <em>isEnabled</em>.
            If <em>name</em> is not a <strong>ScreenView</strong>, Noah logs an
            error message and have no effect.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>name</code> (string):
              </strong>
              The unique identifier for the scene element, must be
              <strong>ScreenView</strong>.
            </li>
            <li>
              <strong>
                <code>isEnabled</code> (bool):
              </strong>
              enable parallax mode or not.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set camera in &quot;main&quot; disable parallax mode:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                SetParallaxEnabled(
                <span className="hljs-string">&quot;main&quot;</span>,
                <span className="hljs-literal">false</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetlookat">NoahSetLookAt</a> - Set position camera
              look toward.
            </li>
            <li>
              <a href="#noahsetfovy">NoahSetFovY</a> - Set fovy of camera.
            </li>
            <li>
              <a href="#noahsetaspectratio">NoahSetAspectRatio</a> - Set aspect
              ratio of camera.
            </li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIBasicCameraPage;
