import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIVideoPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Video APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <li><a href="#noahplayvideo">NoahPlayVideo</a></li>
              <li><a href="#noahstopvideo">NoahStopVideo</a></li>
              <li><a href="#noahresetvideo">NoahResetVideo</a></li>
              <li><a href="#noahpausevideo">NoahPauseVideo</a></li>
              <li><a href="#noahresumevideo">NoahResumeVideo</a></li>
              <li><a href="#noahseekvideo">NoahSeekVideo</a></li>
              <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a></li>
              <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahplayvideo">NoahPlayVideo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahPlayVideo(string target, string uri)</code></p>
          <h4 id="description">Description</h4>
          <p>Make <em>target</em>, which is a <strong>Video</strong> component, to play video assigned by <em>uri</em>.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          <li><strong><code>uri</code> (string):</strong> The uri location of the stream.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To play &quot;media/video/sample.mp4&quot; in &quot;video_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahPlayVideo(<span class="hljs-string">"video_node"</span>, <span class="hljs-string">"media/video/sample.mp4"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahstopvideo">NoahStopVideo</a> - Stop the streaming in video node.</li>
          <li><a href="#noahresetvideo">NoahResetVideo</a> - Reset the streaming in video node.</li>
          <li><a href="#noahpausevideo">NoahPauseVideo</a> - Pause the streaming in video node.</li>
          <li><a href="#noahresumevideo">NoahResumeVideo</a> - Resume the streaming in video node.</li>
          <li><a href="#noahseekvideo">NoahSeekVideo</a> - Seek the streaming position in video node.</li>
          <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a> - Get streaming state of video node.</li>
          <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a> - Get streaming progress of video node.</li>
          </ul>
          <hr />
          <h3 id="noahstopvideo">NoahStopVideo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahStopVideo(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Stop the video play of <em>target</em>, which is a <strong>Video</strong> compnent.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To stop video in &quot;video_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahStopVideo(<span class="hljs-string">"video_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahplayvideo">NoahPlayVideo</a> - Play streaming in video node.</li>
          <li><a href="#noahresetvideo">NoahResetVideo</a> - Reset the streaming in video node.</li>
          <li><a href="#noahpausevideo">NoahPauseVideo</a> - Pause the streaming in video node.</li>
          <li><a href="#noahresumevideo">NoahResumeVideo</a> - Resume the streaming in video node.</li>
          <li><a href="#noahseekvideo">NoahSeekVideo</a> - Seek the streaming position in video node.</li>
          <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a> - Get streaming state of video node.</li>
          <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a> - Get streaming progress of video node.</li>
          </ul>
          <hr />
          <h3 id="noahresetvideo">NoahResetVideo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahResetVideo(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Reset video time to 0 of <em>target</em>, which is a <strong>Video</strong> compnent.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To reset video in &quot;video_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahResetVideo(<span class="hljs-string">"video_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahplayvideo">NoahPlayVideo</a> - Play streaming in video node.</li>
          <li><a href="#noahstopvideo">NoahStopVideo</a> - Stop the streaming in video node.</li>
          <li><a href="#noahpausevideo">NoahPauseVideo</a> - Pause the streaming in video node.</li>
          <li><a href="#noahresumevideo">NoahResumeVideo</a> - Resume the streaming in video node.</li>
          <li><a href="#noahseekvideo">NoahSeekVideo</a> - Seek the streaming position in video node.</li>
          <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a> - Get streaming state of video node.</li>
          <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a> - Get streaming progress of video node.</li>
          </ul>
          <hr />
          <h3 id="noahpausevideo">NoahPauseVideo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahPauseVideo(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Pause video of <em>target</em>, which is a <strong>Video</strong> compnent.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To pause video in &quot;video_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahPauseVideo(<span class="hljs-string">"video_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahplayvideo">NoahPlayVideo</a> - Play streaming in video node.</li>
          <li><a href="#noahstopvideo">NoahStopVideo</a> - Stop the streaming in video node.</li>
          <li><a href="#noahresetvideo">NoahResetVideo</a> - Reset the streaming in video node.</li>
          <li><a href="#noahresumevideo">NoahResumeVideo</a> - Resume the streaming in video node.</li>
          <li><a href="#noahseekvideo">NoahSeekVideo</a> - Seek the streaming position in video node.</li>
          <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a> - Get streaming state of video node.</li>
          <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a> - Get streaming progress of video node.</li>
          </ul>
          <hr />
          <h3 id="noahresumevideo">NoahResumeVideo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahResumeVideo(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Resume paused video of <em>target</em>, which is a <strong>Video</strong> compnent.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To resume video in &quot;video_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahResumeVideo(<span class="hljs-string">"video_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahplayvideo">NoahPlayVideo</a> - Play streaming in video node.</li>
          <li><a href="#noahstopvideo">NoahStopVideo</a> - Stop the streaming in video node.</li>
          <li><a href="#noahresetvideo">NoahResetVideo</a> - Reset the streaming in video node.</li>
          <li><a href="#noahpausevideo">NoahPauseVideo</a> - Pause the streaming in video node.</li>
          <li><a href="#noahseekvideo">NoahSeekVideo</a> - Seek the streaming position in video node.</li>
          <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a> - Get streaming state of video node.</li>
          <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a> - Get streaming progress of video node.</li>
          </ul>
          <hr />
          <h3 id="noahseekvideo">NoahSeekVideo</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSeekVideo(string target, number second)</code></p>
          <h4 id="description">Description</h4>
          <p>Seek video of <em>target</em> to <em>second</em>, which is a <strong>Video</strong> compnent.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          <li><strong><code>second</code> (number):</strong> The seek target time of the video.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To seek video in &quot;video_node&quot; to 2.0 second:</p>
          <pre class="hljs hljs-content"><code><div>NoahSeekVideo(<span class="hljs-string">"video_node"</span>, <span class="hljs-number">2.0</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahplayvideo">NoahPlayVideo</a> - Play streaming in video node.</li>
          <li><a href="#noahstopvideo">NoahStopVideo</a> - Stop the streaming in video node.</li>
          <li><a href="#noahresetvideo">NoahResetVideo</a> - Reset the streaming in video node.</li>
          <li><a href="#noahpausevideo">NoahPauseVideo</a> - Pause the streaming in video node.</li>
          <li><a href="#noahresumevideo">NoahResumeVideo</a> - Resume the streaming in video node.</li>
          <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a> - Get streaming state of video node.</li>
          <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a> - Get streaming progress of video node.</li>
          </ul>
          <hr />
          <h3 id="noahgetvideoplaybackstate">NoahGetVideoPlaybackState</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetVideoPlaybackState(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get playing state of <em>target</em>, which is a <strong>Video</strong> compnent.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return video state as string, can be <strong>PREROLL</strong>, <strong>STOPPED</strong>, <strong>PAUSED</strong> and <strong>PLAYING</strong></p>
          <h4 id="example">Example</h4>
          <p>To get video play state in &quot;video_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> state = NoahGetVideoPlaybackState(<span class="hljs-string">"video_node"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"The playing state is : "</span> .. state)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>The playing state is : PLAYING
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahplayvideo">NoahPlayVideo</a> - Play streaming in video node.</li>
          <li><a href="#noahstopvideo">NoahStopVideo</a> - Stop the streaming in video node.</li>
          <li><a href="#noahresetvideo">NoahResetVideo</a> - Reset the streaming in video node.</li>
          <li><a href="#noahpausevideo">NoahPauseVideo</a> - Pause the streaming in video node.</li>
          <li><a href="#noahresumevideo">NoahResumeVideo</a> - Resume the streaming in video node.</li>
          <li><a href="#noahseekvideo">NoahSeekVideo</a> - Seek the streaming position in video node.</li>
          <li><a href="#noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</a> - Get streaming progress of video node.</li>
          </ul>
          <hr />
          <h3 id="noahgetvideoplaybackprogress">NoahGetVideoPlaybackProgress</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetVideoPlaybackProgress(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get current video time position of <em>target</em>, which is a <strong>Video</strong> compnent.</p>
          <p>This function takes effect on only <strong>Video</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Video</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return a number which is current video seek position in second.</p>
          <h4 id="example">Example</h4>
          <p>To get seek time in &quot;video_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> sec = NoahGetVideoPlaybackProgress(<span class="hljs-string">"video_node"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"Already played : "</span> .. sec .. <span class="hljs-string">" second"</span>)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Already played : 2.2 second
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahplayvideo">NoahPlayVideo</a> - Play streaming in video node.</li>
          <li><a href="#noahstopvideo">NoahStopVideo</a> - Stop the streaming in video node.</li>
          <li><a href="#noahresetvideo">NoahResetVideo</a> - Reset the streaming in video node.</li>
          <li><a href="#noahpausevideo">NoahPauseVideo</a> - Pause the streaming in video node.</li>
          <li><a href="#noahresumevideo">NoahResumeVideo</a> - Resume the streaming in video node.</li>
          <li><a href="#noahseekvideo">NoahSeekVideo</a> - Seek the streaming position in video node.</li>
          <li><a href="#noahgetvideoplaybackstate">NoahGetVideoPlaybackState</a> - Get streaming state of video node.</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIVideoPage;
