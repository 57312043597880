import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
import speedometer from './image/5_1_speedometer.png';
/*eslint-disable */

function NoahTutorialLab2Page5() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="before-you-begin">Lab2 Lua Script</h1>
          <h1 id="implementing-a-2d-meter-a-guide-to-editing-lua-scripts-for-ui-control">Implementing a 2D Meter: A Guide to Editing Lua Scripts for UI Control</h1>
          <p>In this section, we will introduce how to edit Lua script to achieve the effect of a 2D meter. The primary goal of our first step is to utilize Lua function to control UI behavior.</p>
          <h2 id="lua-script-workflow-overview">Lua Script Workflow Overview</h2>
          <p>The <code>demo_meter</code> project is a speedometer dashboard. Its XML file consists of a series of rectangles representing the scale, as well as a text element displaying the speed. Let's illustrate with a simple example:</p>
          <p>In this project, five Lua scripts determine the behavior:</p>
          <ul>
            <li><code>script.lua</code>: Defines the project's lifecycle.</li>
          </ul>
          <pre class="hljs hljs-content"><code><div><span class="hljs-comment">
            -- Import the mouse module to handle mouse input events</span><br />
            <span class="hljs-built_in">require</span>(<span class="hljs-string">"mouse"</span>)<br />
            <span class="hljs-comment">-- Import the keyboard module to handle keyboard input events</span><br />
            <span class="hljs-built_in">require</span>(<span class="hljs-string">"keyboard"</span>)<br /> <br />
            <span class="hljs-comment">-- Function to initialize the environment or application</span><br />
            <span class="hljs-comment">-- This is called once at the start to set up initial configurations, variables, or states</span><br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Initialize</span><span class="hljs-params">()</span></span><br />
                {'  '}<span class="hljs-comment">-- Add initialization logic here, if needed</span><br />
            <span class="hljs-keyword">end</span><br />  <br />
            <span class="hljs-comment">-- Function to update the application state</span><br />
            <span class="hljs-comment">-- @param interval: The time interval (in milliseconds or seconds) since the last update</span><br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Update</span><span class="hljs-params">(interval)</span></span><br />
                {'  '}<span class="hljs-comment">-- Call the UpdateMeter function in every loop iteration to update the meter's behavior or UI</span><br />
                {'  '}UpdateMeter(interval)<br />
            <span class="hljs-keyword">end</span><br /> <br />
            <span class="hljs-comment">-- Function to terminate the application or clean up resources</span><br />
            <span class="hljs-comment">-- This is called once at the end to release resources or perform any final actions</span><br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Terminate</span><span class="hljs-params">()</span></span><br />
                {'  '}<span class="hljs-comment">-- Add resource cleanup or finalization logic here</span><br />
            <span class="hljs-keyword">end</span><br />

          </div></code></pre>
          
          <ul>
          <li>
          <p><code>keyboard.lua</code> and <code>mouse.lua</code>: Manage user input.</p>
          <p>During the initial configuration, establish a state named <code>is_SPACE_pressed</code> in <code>keyboard.lua</code>. This state alternates based on whether the space key is pressed or released, mirroring the action of a pedal being pressed down or released.</p>
          </li>
          </ul>
          <pre class="hljs hljs-content"><code><div>is_SPACE_pressed = <span class="hljs-literal">false</span><br />
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">KeyPress</span><span class="hljs-params">(key)</span></span><br />
                {'  '}<span class="hljs-built_in">print</span>(key .. <span class="hljs-string">" is pressed."</span>)<br />
                {'  '}<span class="hljs-keyword">if</span> (key == <span class="hljs-string">"SPACE"</span>)<br />
                {'  '}{'  '}<span class="hljs-keyword">then</span> is_SPACE_pressed = <span class="hljs-literal">true</span><br />
                {'  '}<span class="hljs-keyword">end</span><br />
            <span class="hljs-keyword">end</span><br /><br />

            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">KeyRelease</span><span class="hljs-params">(key)</span></span><br />
                {'  '}is_SPACE_pressed = <span class="hljs-literal">false</span><br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre>
          <ul>
            <li>
                <p><code>meter.lua</code>: Manage meter behavior.</p>
                <p>In the <code>UpdateSpeed</code> function of <code>meter.lua</code>, the <code>kmh</code>(car speed) and <code>rpm</code>(revolutions per minute) will be calculated by different formula based on the <code>is_SPACE_pressed</code> state.</p>
                <ul>
                    <li><em>Note:</em> Cause we need the <code>is_SPACE_pressed</code> state which declared in <code>keyboard.lua</code>, we need to import the file by <code>require(&quot;keyboard&quot;)</code>.</li>
                </ul>
            </li>
          </ul>
          
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-built_in">require</span>(<span class="hljs-string">"keyboard"</span>)<br />
            <span class="hljs-keyword">local</span> kmh = <span class="hljs-number">0</span><br />
            <span class="hljs-keyword">local</span> rpm = <span class="hljs-number">0</span><br /><br />

            <span class="hljs-keyword">local</span> <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">UpdateSpeed</span><span class="hljs-params">(interval)</span></span><br />
            {'  '}<span class="hljs-keyword">local</span> a = <span class="hljs-number">1.1</span><br />
            {'  '}<span class="hljs-keyword">local</span> b = <span class="hljs-number">0.04</span><br />
            {'  '}<span class="hljs-keyword">local</span> c = <span class="hljs-number">0.0029</span><br />
            {'  '}<span class="hljs-keyword">local</span> d = <span class="hljs-number">0.00009</span><br />
            {'  '}<span class="hljs-keyword">local</span> r = <span class="hljs-number">0.2</span><br />
            {'  '}<span class="hljs-keyword">local</span> s = <span class="hljs-number">0.042</span><br /><br />
            {'  '}<span class="hljs-keyword">if</span> is_SPACE_pressed <span class="hljs-keyword">then</span><br />
            {'  '}{'  '}rpm = rpm + interval*(a - c*kmh - d*rpm)<br />
            {'  '}<span class="hljs-keyword">else</span><br />
            {'  '}{'  '}rpm = rpm + interval*(b*(kmh - <span class="hljs-number">210</span>)/<span class="hljs-number">240</span> - c*kmh - d*rpm)<br />
            {'  '}{'  '}<span class="hljs-keyword">if</span> (rpm &lt; <span class="hljs-number">0</span>) <span class="hljs-keyword">then</span><br />
            {'  '}{'  '}{'  '}rpm = <span class="hljs-number">0</span><br />
            {'  '}{'  '}<span class="hljs-keyword">end</span><br />
            {'  '}<span class="hljs-keyword">end</span><br /><br />
            {'  '}kmh = rpm*s<br />
            <span class="hljs-keyword">end</span><br />
          </div></code></pre>
          <p>Further, in the <code>UpdateMeter</code> function of <code>meter.lua</code>, we calculate which <strong>Quad2D</strong> need to be filled based on the current speed and determine the appropriate color for each. We then use the <code>NoahSetRGBA</code> function to update the color of the corresponding elements and call the <code>NoahSetText</code> function to update the speed display on the dashboard.</p>
          <ul>
            <li><code>NoahSetRGBA(string target, number r, number g, number b, number a)</code>:
                <ul>
                    <li>Set <em>target</em>, which is a <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong> element, to the color specified by <code>r, g, b, a</code>.</li>
                    <li>The assigned <code>r, g, b, a</code> should be float numbers within the range of <strong>0.0</strong> to <strong>1.0</strong>.</li>
                </ul>
            </li>
            <li><code>NoahSetText(string target, string text)</code>:
                <ul>
                    <li>Set <code>target</code>, which is a <strong>Text2D</strong> or <strong>Text3D</strong> element, to the text content specified by <code>text</code>.</li>
                </ul>
            </li>
          </ul>
          <pre class="hljs hljs-content"><code><div><span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">UpdateMeter</span><span class="hljs-params">(interval)</span></span><br />
              {'  '}UpdateSpeed(interval)<br /><br />
              {'  '}<span class="hljs-keyword">for</span> i = <span class="hljs-number">1</span>, <span class="hljs-number">9</span> <span class="hljs-keyword">do</span><br />
                  {'  '}{'  '}<span class="hljs-keyword">local</span> target = <span class="hljs-string">"meter.quad"</span>..<span class="hljs-built_in">tostring</span>(i)<br />
                  {'  '}{'  '}<span class="hljs-keyword">if</span> i - <span class="hljs-number">1</span> &lt; kmh/<span class="hljs-number">5</span> <span class="hljs-keyword">then</span><br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, i/<span class="hljs-number">33</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">0.8</span>*(<span class="hljs-number">1</span> - i/<span class="hljs-number">9</span>), <span class="hljs-number">0.3</span> + <span class="hljs-number">0.6</span>*(i/<span class="hljs-number">49</span>))<br />
                  {'  '}{'  '}<span class="hljs-keyword">else</span> <br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, <span class="hljs-number">0.4</span>, <span class="hljs-number">0.4</span>, <span class="hljs-number">0.4</span>, <span class="hljs-number">0.5</span>)<br />
                  {'  '}{'  '}<span class="hljs-keyword">end</span><br />
              {'  '}<span class="hljs-keyword">end</span><br /><br />
              {'  '}<span class="hljs-keyword">for</span> i = <span class="hljs-number">10</span>, <span class="hljs-number">25</span> <span class="hljs-keyword">do</span><br />
                  {'  '}{'  '}<span class="hljs-keyword">local</span> target = <span class="hljs-string">"meter.quad"</span>..<span class="hljs-built_in">tostring</span>(i)<br />
                  {'  '}{'  '}<span class="hljs-keyword">if</span> i - <span class="hljs-number">1</span> &lt; kmh/<span class="hljs-number">5</span> <span class="hljs-keyword">then</span><br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, i/<span class="hljs-number">33</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">0.0</span>, <span class="hljs-number">0.3</span> + <span class="hljs-number">0.6</span>*(i/<span class="hljs-number">49</span>))<br />
                  {'  '}{'  '}<span class="hljs-keyword">else</span> <br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.5</span>)<br />
                  {'  '}{'  '}<span class="hljs-keyword">end</span><br />
              {'  '}<span class="hljs-keyword">end</span><br /><br />
              {'  '}<span class="hljs-keyword">for</span> i = <span class="hljs-number">26</span>, <span class="hljs-number">33</span> <span class="hljs-keyword">do</span><br />
                  {'  '}{'  '}<span class="hljs-keyword">local</span> target = <span class="hljs-string">"meter.quad"</span>..<span class="hljs-built_in">tostring</span>(i)<br />
                  {'  '}{'  '}<span class="hljs-keyword">if</span> i - <span class="hljs-number">1</span> &lt; kmh/<span class="hljs-number">5</span> <span class="hljs-keyword">then</span><br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, i/<span class="hljs-number">33</span>, <span class="hljs-number">1.0</span> - (i<span class="hljs-number">-25</span>)/<span class="hljs-number">24</span>, <span class="hljs-number">0.0</span>, <span class="hljs-number">0.3</span> + <span class="hljs-number">0.6</span>*(i/<span class="hljs-number">49</span>))<br />
                  {'  '}{'  '}<span class="hljs-keyword">else</span> <br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.4</span>, <span class="hljs-number">0.5</span>)<br />
                  {'  '}{'  '}<span class="hljs-keyword">end</span><br />
              {'  '}<span class="hljs-keyword">end</span><br /><br />
              {'  '}<span class="hljs-keyword">for</span> i = <span class="hljs-number">34</span>, <span class="hljs-number">41</span> <span class="hljs-keyword">do</span><br />
                  {'  '}{'  '}<span class="hljs-keyword">local</span> target = <span class="hljs-string">"meter.quad"</span>..<span class="hljs-built_in">tostring</span>(i)<br />
                  {'  '}{'  '}<span class="hljs-keyword">if</span> i - <span class="hljs-number">1</span> &lt; kmh/<span class="hljs-number">5</span> <span class="hljs-keyword">then</span><br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, <span class="hljs-number">1.0</span>, <span class="hljs-number">1.0</span> - (i<span class="hljs-number">-25</span>)/<span class="hljs-number">24</span>, <span class="hljs-number">0.0</span>, <span class="hljs-number">0.3</span> + <span class="hljs-number">0.6</span>*(i/<span class="hljs-number">49</span>))<br />
                  {'  '}{'  '}<span class="hljs-keyword">else</span> <br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.4</span>, <span class="hljs-number">0.5</span>)<br />
                  {'  '}{'  '}<span class="hljs-keyword">end</span><br />
              {'  '}<span class="hljs-keyword">end</span><br /><br />
              {'  '}<span class="hljs-keyword">for</span> i = <span class="hljs-number">42</span>, <span class="hljs-number">49</span> <span class="hljs-keyword">do</span><br />
                  {'  '}{'  '}<span class="hljs-keyword">local</span> target = <span class="hljs-string">"meter.quad"</span>..<span class="hljs-built_in">tostring</span>(i)<br />
                  {'  '}{'  '}<span class="hljs-keyword">if</span> i - <span class="hljs-number">1</span> &lt; kmh/<span class="hljs-number">5</span> <span class="hljs-keyword">then</span><br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, <span class="hljs-number">1.0</span>, <span class="hljs-number">1.0</span> - (i<span class="hljs-number">-25</span>)/<span class="hljs-number">24</span>, <span class="hljs-number">0.0</span>, <span class="hljs-number">0.3</span> + <span class="hljs-number">0.6</span>*(i/<span class="hljs-number">49</span>))<br />
                  {'  '}{'  '}<span class="hljs-keyword">else</span> <br />
                      {'  '}{'  '}{'  '}NoahSetRGBA(target, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.4</span>, <span class="hljs-number">0.4</span>, <span class="hljs-number">0.5</span>)<br />
                  {'  '}{'  '}<span class="hljs-keyword">end</span><br />
              {'  '}<span class="hljs-keyword">end</span><br /><br />
              {'  '}<span class="hljs-keyword">local</span> gA = <span class="hljs-number">1.0</span><br />
              {'  '}<span class="hljs-keyword">local</span> bA = <span class="hljs-number">1.0</span><br />
              {'  '}<span class="hljs-keyword">if</span> kmh &gt; <span class="hljs-number">135</span> <span class="hljs-keyword">then</span> bA = <span class="hljs-number">1.0</span> - (kmh<span class="hljs-number">-135</span>)/<span class="hljs-number">105</span> <span class="hljs-keyword">end</span><br />
              {'  '}<span class="hljs-keyword">if</span> kmh &gt; <span class="hljs-number">180</span> <span class="hljs-keyword">then</span> gA = <span class="hljs-number">1.0</span> - (kmh<span class="hljs-number">-180</span>)/<span class="hljs-number">60</span> <span class="hljs-keyword">end</span><br />
              {'  '}NoahSetRGBA(<span class="hljs-string">"meter_value"</span>, <span class="hljs-number">1.0</span>, gA, bA, <span class="hljs-number">0.8</span>)<br />
              {'  '}NoahSetText(<span class="hljs-string">"meter_value"</span>, <span class="hljs-built_in">string</span>.<span class="hljs-built_in">format</span>(<span class="hljs-string">"%2.0f"</span>, kmh))<br />
          <span class="hljs-keyword">end</span><br />
          </div></code></pre><br />
          <p>Finally, in <code>script.lua</code>, we incorporate the <code>UpdateMeter</code> function into the <code>Update</code> function. By doing so, we can calculate the current speed in each frame and update the UI accordingly.</p><br />
          <p><img src={speedometer} alt="alt text" width={960} height={360}/></p><br />
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab2Page5;
