import React from 'react';
import PropTypes from 'prop-types';
/*eslint-disable */

function DownloadTable({ documents }) {
  return (
    <div className="tableContainer">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Download Link</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc) => (
            <tr key={`key-${doc.name}`}>
              <td>{doc.name}</td>
              <td>{doc.description}</td>
              <td>
                <a
                  href={doc.downloadUrl}
                  download={doc.name}
                  className="downloadLink"
                >
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Define the prop types for DocumentTable
DownloadTable.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      downloadUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DownloadTable;
