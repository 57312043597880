import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab4Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab4_title">Lab4 Communication</h1>
          <h1 id="conclusion">Conclusion</h1>
          <h2 id="summary">Summary</h2>
          <ul>
            <li>Establish and close the communication.</li>
            <li>Create and destroy a subscriber.</li>
            <li>Subscribe data from the host.</li>
            <li>Create and destroy a publisher.</li>
            <li>Publish data to the host.</li>
            <li>Send messages to another client.</li>
            <li>Read messages from another client.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab4Page;
