import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahOverviewPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="overview">Overview</h1>
          <p><em>Noah</em> is a GUI framework designed to implement vehicle UI. With <em>Noah</em>, you can implement your design easily, test it easily, and modify it easily.</p>
          <p><em>Noah</em> can present not only images, texts, videos, and regular UI components such as buttons or sliders, it is also a 3D renderer which can render 3D scenes with physically-based shading. Going one step further, you can customize your own shaders to meet your particular needs if you want.</p>
          <blockquote>
          <h4 id="the-advantage-to-choose-noah">The advantage to choose Noah:</h4>
          <ul>
          <li><em>Noah</em> provides high-performance rendering based on OpenGL ES 3 with a relatively low resource consumption, and hence allows car manufacturers to create unique and beautiful vehicle UI at a reasonable cost.</li>
          <li><em>Noah</em> interprets all the layout, scenes, images, 3D models, and the interactive logic on <strong>runtime</strong>, that is, you don't have to re-compile your project when you change your design, and to update your vehicle UI is quite easy.</li>
          <li><em>Noah</em> is NOT dependent on any system-binding GUI tools. You can bring your design to any platform and get exactly the same appearance and the same behavior.</li>
          </ul>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default NoahOverviewPage;
