import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPIColorPage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>Color APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Transparency</h3>
              <li><a href="#noahsetalpha">NoahSetAlpha</a></li>
              <li><a href="#noahgetalpha">NoahGetAlpha</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Blending Color</h3>
              <li><a href="#noahsettintrgb">NoahSetTintRGB</a></li>
              <li><a href="#noahgettintrgb">NoahGetTintRGB</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Color - Quad & Text</h3>
              <li><a href="#noahsetrgba">NoahSetRGBA</a></li>
              <li><a href="#noahsetcolor">NoahSetColor</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Color - Quad</h3>
              <li><a href="#noahset2colors">NoahSet2Colors</a></li>
              <li><a href="#noahset4colors">NoahSet4Colors</a></li>
              <li><a href="#noahsetcornercolor">NoahSetCornerColor</a></li>
              <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a></li>
              <li><a href="#noahgetrgba">NoahGetRGBA</a></li>
              <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahsetalpha">NoahSetAlpha</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahSetAlpha(string target, number alpha)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Sets the transparency of the specified <em>target</em> by setting
            its alpha value. The <em>alpha</em> parameter determines the opacity
            level and should be a floating-point number ranging from
            <strong>0.0</strong> (completely transparent) to
            <strong>1.0</strong> (completely opaque).
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
            <li>
              <strong>
                <code>alpha</code> (number):
              </strong>
              The alpha value to set, within the range of <strong>0.0</strong>
              to <strong>1.0</strong>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set the transparency of &quot;exampleElement&quot; to 50%:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetAlpha(
                <span className="hljs-string">
                  &quot;exampleElement&quot;
                </span>, <span className="hljs-number">0.5</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgetalpha">NoahGetAlpha</a> - Get the transparency of
              the specified <em>target</em>.
            </li>
          </ul>
          <hr />
          <h3 id="noahgetalpha">NoahGetAlpha</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetAlpha(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the alpha value (transparency level) of the specified
            <em>target</em>, returning a float number within the range of
            <strong>0.0</strong> to <strong>1.0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>Returns the current alpha value of the target element.</p>
          <h4 id="example">Example</h4>
          <p>To get the alpha value of &quot;exampleElement&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> alphaValue =
                NoahGetAlpha(
                <span className="hljs-string">&quot;exampleElement&quot;</span>)<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Alpha value: &quot;</span>
                .. alphaValue)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Alpha value: 0.5</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsetalpha">NoahSetAlpha</a> - Set the transparency of
              the specified <em>target</em>.
            </li>
          </ul>
          <hr />
          <h3 id="noahsettintrgb">NoahSetTintRGB</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>
              NoahSetTintRGB(string target, number r, number g, number b)
            </code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Applies a blending color to the specified <em>target</em> by setting
            the red (r), green (g), and blue (b) components. Each of these color
            components must be a floating-point number ranging from
            <strong>0.0</strong> (no contribution) to <strong>1.0</strong> (full
            intensity), defining the intensity of the respective colors.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
            <li>
              <strong>
                <code>r</code> (number), <code>g</code> (number), <code>b</code>
                (number):
              </strong>
              The RGB components to set, with each value within the range of
              <strong>0.0</strong> to <strong>1.0</strong>.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set a green tint on &quot;exampleElement&quot;:</p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                NoahSetTintRGB(
                <span className="hljs-string">&quot;exampleElement&quot;</span>,
                <span className="hljs-number">0.0</span>,
                <span className="hljs-number">1.0</span>,
                <span className="hljs-number">0.0</span>)
              </div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahgettintrgb">NoahGetTintRGB</a> - Retrieves the
              blending color of the specified <em>target</em>.
            </li>
          </ul>
          <hr />
          <h3 id="noahgettintrgb">NoahGetTintRGB</h3>
          <h4 id="definition">Definition</h4>
          <p>
            <code>NoahGetTintRGB(string target)</code>
          </p>
          <h4 id="description">Description</h4>
          <p>
            Retrieves the blending color of the specified <em>target</em>,
            returning it as a <strong>vec3</strong> object. This object allows
            access to the red (r), green (g), and blue (b) values using the dot
            operator. Each component of the blending color is a floating-point
            number within the range of <strong>0.0</strong> to
            <strong>1.0</strong>.
          </p>
          <h4 id="parameters">Parameters</h4>
          <ul>
            <li>
              <strong>
                <code>target</code> (string):
              </strong>
              The unique identifier for the target element.
            </li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>
            Returns a <strong>vec3</strong> object containing the RGB components
            of the target&quot;s blending color.
          </p>
          <h4 id="example">Example</h4>
          <p>
            To get the red component of the blending color for
            &quot;my_element&quot;:
          </p>
          <pre className="hljs hljs-content">
            <code>
              <div>
                <span className="hljs-keyword">local</span> blendingColorR =
                NoahGetTintRGB(
                <span className="hljs-string">&quot;my_element&quot;</span>).r<br/>
                <span className="hljs-built_in">print</span>(
                <span className="hljs-string">&quot;Red component: &quot;</span>
                .. blendingColorR)
              </div>
            </code>
          </pre>
          <h4 id="output">Output</h4>
          <pre className="hljs hljs-content">
            <code>
              <div>Red component: 0.5</div>
            </code>
          </pre>
          <h4 id="see-also">See Also</h4>
          <ul>
            <li>
              <a href="#noahsettintrgb">NoahSetTintRGB</a> - Set the blending
              color of the specified <em>target</em>.
            </li>
          </ul>
          <hr />
          <h3 id="noahsetrgba">NoahSetRGBA</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetRGBA(string target, number r, number g, number b, number a)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong> element, to the color specified by <em>r, g, b, a</em>. The assigned <em>r, g, b, a</em> should be float numbers within the range of <strong>0.0</strong> to <strong>1.0</strong>.</p>
          <p>If <em>target</em> is a <strong>Quad2D</strong> or <strong>Quad3D</strong> element, this function makes the quad a single-color rectangle. If <em>target</em> is a <strong>Text2D</strong> or <strong>Text3D</strong> element, this function changes the color of text. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <p>You should notice that this function is designed to change the color of <em>Quad or Text</em>, not the blending color of a element. If you want to edit the blending color, you should call <strong>NoahSetTintRGB</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong>.</li>
          <li><strong><code>r</code> (number):</strong> Red channel of color.</li>
          <li><strong><code>g</code> (number):</strong> Green channel of color.</li>
          <li><strong><code>b</code> (number):</strong> Blue channel of color.</li>
          <li><strong><code>a</code> (number):</strong> Alpha channel of color.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;my_quad&quot; to rgba(1.0, 1.0, 0.0, 1.0):</p>
          <pre class="hljs hljs-content"><code><div>NoahSetRGBA(<span class="hljs-string">"my_quad"</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">0.0</span>, <span class="hljs-number">1.0</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetcolor">NoahSetColor</a> - Set text or quad color</li>
          <li><a href="#noahset2colors">NoahSet2Colors</a> - Set quad color</li>
          <li><a href="#noahset4colors">NoahSet4Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornercolor">NoahSetCornerColor</a> - Set quad color</li>
          <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a> - Set quad color</li>
          <li><a href="#noahgetrgba">NoahGetRGBA</a> - Get text or quad color</li>
          <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a> - Get quad corner color</li>
          </ul>
          <hr />
          <h3 id="noahsetcolor">NoahSetColor</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetColor(string target, string color)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong> element, to the <em>color</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong>.</li>
          <li><strong><code>color</code> (string):</strong> Color to set, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;my_quad&quot; to red:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetColor(<span class="hljs-string">"my_quad"</span>, <span class="hljs-string">"#FFFF0000"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetrgba">NoahSetRGBA</a> - Set text or quad color</li>
          <li><a href="#noahset2colors">NoahSet2Colors</a> - Set quad color</li>
          <li><a href="#noahset4colors">NoahSet4Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornercolor">NoahSetCornerColor</a> - Set quad color</li>
          <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a> - Set quad color</li>
          <li><a href="#noahgetrgba">NoahGetRGBA</a> - Get text or quad color</li>
          <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a> - Get quad corner color</li>
          </ul>
          <hr />
          <h3 id="noahset2colors">NoahSet2Colors</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSet2Colors(string target, string color_1, string color_2)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Quad2D</strong> or <strong>Quad3D</strong> element, to a 2-color-gradient rectangle.
          This function can handle only <strong>Quad2D</strong> and <strong>Quad3D</strong> elements. If you target a element of other types to this function, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong> or <strong>Quad3D</strong>.</li>
          <li><strong><code>color_1</code> (string):</strong> Color at left side, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          <li><strong><code>color_2</code> (string):</strong> Color at right side, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;my_quad&quot; to left side red, right side blue:</p>
          <pre class="hljs hljs-content"><code><div>NoahSet2Colors(<span class="hljs-string">"my_quad"</span>, <span class="hljs-string">"#FFFF0000"</span>, <span class="hljs-string">"#FF00FF00"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetrgba">NoahSetRGBA</a> - Set text or quad color</li>
          <li><a href="#noahsetcolor">NoahSetColor</a> - Set text or quad color</li>
          <li><a href="#noahset4colors">NoahSet4Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornercolor">NoahSetCornerColor</a> - Set quad color</li>
          <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a> - Set quad color</li>
          <li><a href="#noahgetrgba">NoahGetRGBA</a> - Get text or quad color</li>
          <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a> - Get quad corner color</li>
          </ul>
          <hr />
          <h3 id="noahset4colors">NoahSet4Colors</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSet4Colors(string target, string color_1, string color_2, string color_3, string color_4)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Quad2D</strong> or <strong>Quad3D</strong> element, to a 4-color-gradient rectangle.</p>
          <p>This function can handle only <strong>Quad2D</strong> and <strong>Quad3D</strong> elements. If you target a element of other types to this function, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong> or <strong>Quad3D</strong>.</li>
          <li><strong><code>color_1</code> (string):</strong> Color at Top-Left corner, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          <li><strong><code>color_2</code> (string):</strong> Color at Top-Right corner, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          <li><strong><code>color_3</code> (string):</strong> Color at Bottom-Left corner, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          <li><strong><code>color_4</code> (string):</strong> Color at Bottom-Right corner, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;my_quad&quot; corners to Top-Left rgba(1.0, 0.0, 0.0, 1.0), Top-Right rgba(0.0, 1.0, 0.0, 1.0), Bottom-Left rgba(0.0, 0.0, 1.0, 1.0) and Bottom-Right rgba(1.0, 1.0, 1.0, 0.5):</p>
          <pre class="hljs hljs-content"><code><div>NoahSet4Colors(<span class="hljs-string">"my_quad"</span>, <span class="hljs-string">"#FFFF0000"</span>, <span class="hljs-string">"#FF00FF00"</span>, <span class="hljs-string">"#FF0000FF"</span>, <span class="hljs-string">"7FFFFFFF"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetrgba">NoahSetRGBA</a> - Set text or quad color</li>
          <li><a href="#noahsetcolor">NoahSetColor</a> - Set text or quad color</li>
          <li><a href="#noahset2colors">NoahSet2Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornercolor">NoahSetCornerColor</a> - Set quad color</li>
          <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a> - Set quad color</li>
          <li><a href="#noahgetrgba">NoahGetRGBA</a> - Get text or quad color</li>
          <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a> - Get quad corner color</li>
          </ul>
          <hr />
          <h3 id="noahsetcornercolor">NoahSetCornerColor</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetCornerColor(string target, number corner, string color)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the color at one corner of <em>target</em>, which is a <strong>Quad2D</strong> or <strong>Quad3D</strong> element, to <em>color</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong> or <strong>Quad3D</strong>.</li>
          <li><strong><code>corner</code> (number):</strong> Corner index of quad, <em>corner</em> = 1 for the Top-Left corner, <em>corner</em> = 2 for the Top-Right corner, <em>corner</em> = 3 for the Bottom-Left corner, <em>corner</em> = 4 for the Bottom-Right corner.</li>
          <li><strong><code>color</code> (string):</strong> Color to set, which is defined by strings of <em>#AARRGGBB</em> format.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;my_quad&quot; Top-Right corner to rgba(1.0, 1.0, 0.0, 1.0):</p>
          <pre class="hljs hljs-content"><code><div>NoahSetCornerColor(<span class="hljs-string">"my_quad"</span>, <span class="hljs-number">2</span>, <span class="hljs-string">"#FFFFFF00"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetrgba">NoahSetRGBA</a> - Set text or quad color</li>
          <li><a href="#noahsetcolor">NoahSetColor</a> - Set text or quad color</li>
          <li><a href="#noahset2colors">NoahSet2Colors</a> - Set quad color</li>
          <li><a href="#noahset4colors">NoahSet4Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a> - Set quad color</li>
          <li><a href="#noahgetrgba">NoahGetRGBA</a> - Get text or quad color</li>
          <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a> - Get quad corner color</li>
          </ul>
          <hr />
          <h3 id="noahsetcornerrgba">NoahSetCornerRGBA</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetCornerRGBA(string target, number corner, number r, number g, number b, number a)</code></p>
          <h4 id="description">Description</h4>
          <p>Set the color at one corner of <em>target</em>, which is a <strong>Quad2D</strong> or <strong>Quad3D</strong> element, to <em>r, g, b, a</em>. The assigned <em>r, g, b, a</em> should be float numbers within the range of <strong>0.0</strong> to <strong>1.0</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong> or <strong>Quad3D</strong>.</li>
          <li><strong><code>corner</code> (number):</strong> Corner index of quad, <em>corner</em> = 1 for the Top-Left corner, <em>corner</em> = 2 for the Top-Right corner, <em>corner</em> = 3 for the Bottom-Left corner, <em>corner</em> = 4 for the Bottom-Right corner.</li>
          <li><strong><code>r</code> (number):</strong> Red channel of color.</li>
          <li><strong><code>g</code> (number):</strong> Green channel of color.</li>
          <li><strong><code>b</code> (number):</strong> Blue channel of color.</li>
          <li><strong><code>a</code> (number):</strong> Alpha channel of color.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set node &quot;my_quad&quot; Top-Right corner to rgba(1.0, 1.0, 0.0, 1.0):</p>
          <pre class="hljs hljs-content"><code><div>NoahSetCornerRGBA(<span class="hljs-string">"my_quad"</span>, <span class="hljs-number">2</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">0.0</span>, <span class="hljs-number">1.0</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetrgba">NoahSetRGBA</a> - Set text or quad color</li>
          <li><a href="#noahsetcolor">NoahSetColor</a> - Set text or quad color</li>
          <li><a href="#noahset2colors">NoahSet2Colors</a> - Set quad color</li>
          <li><a href="#noahset4colors">NoahSet4Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornercolor">NoahSetCornerColor</a> - Set quad color</li>
          <li><a href="#noahgetrgba">NoahGetRGBA</a> - Get text or quad color</li>
          <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a> - Get quad corner color</li>
          </ul>
          <hr />
          <h3 id="noahgetrgba">NoahGetRGBA</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetRGBA(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the color of <em>target</em>, which is a <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong> element.</p>
          <p>This function takes effect on only <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong> elements. If <em>target</em> is a <strong>Quad2D</strong> or <strong>Quad3D</strong> element, <em>Noah</em> returns the average color of the rectangle. If <em>target</em> is a <strong>Text2D</strong> or <strong>Text3D</strong> element, <em>Noah</em> returns the text color. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns <strong>vec4(0, 0, 0, 0)</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong>, <strong>Quad3D</strong>, <strong>Text2D</strong>, or <strong>Text3D</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function returns color as a <strong>vec4</strong> object, with which you can access the r, g, b, a values by the dot operator.</p>
          <h4 id="example">Example</h4>
          <p>To get node &quot;my_quad&quot; red channel value:</p>
          <pre class="hljs hljs-content"><code><div><span class="hljs-keyword">local</span> r = NoahGetRGBA(<span class="hljs-string">"my_quad"</span>).r
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Red channel value : "</span> .. r)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Red channel value : 0.8
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetrgba">NoahSetRGBA</a> - Set text or quad color</li>
          <li><a href="#noahsetcolor">NoahSetColor</a> - Set text or quad color</li>
          <li><a href="#noahset2colors">NoahSet2Colors</a> - Set quad color</li>
          <li><a href="#noahset4colors">NoahSet4Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornercolor">NoahSetCornerColor</a> - Set quad color</li>
          <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a> - Set quad color</li>
          <li><a href="#noahgetcornerrgba">NoahGetCornerRGBA</a> - Get quad corner color</li>
          </ul>
          <hr />
          <h3 id="noahgetcornerrgba">NoahGetCornerRGBA</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetCornerRGBA(string target, number index)</code></p>
          <h4 id="description">Description</h4>
          <p>Get a corner color of <em>target</em>, which is a <strong>Quad2D</strong>, or <strong>Quad3D</strong> element. The corner is specified by <em>index</em>.</p>
          <p>This function takes effect on only <strong>Quad2D</strong> and <strong>Quad3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns <strong>vec4(0, 0, 0, 0)</strong>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Quad2D</strong> or <strong>Quad3D</strong> node.</li>
          <li><strong><code>index</code> (string):</strong> The corner index of node, Top-Left corner is with index = 1, Top-Right corner is with index = 2, Bottom-Left corner is with index = 3, and Bottom-Right corner is with index = 4.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function returns color as a <strong>vec4</strong> object, with which you can access the r, g, b, a values by the dot operator.</p>
          <h4 id="example">Example</h4>
          <p>To get node &quot;my_quad&quot; red channel of <em>Left-Bottom</em> value:</p>
          <pre class="hljs hljs-content"><code><div><span class="hljs-keyword">local</span> r = NoahGetCornerRGBA(<span class="hljs-string">"my_quad"</span>, <span class="hljs-number">3</span>).r
          <span class="hljs-built_in">print</span>(<span class="hljs-string">"Left-Bottom red value : "</span> .. r)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Left-Bottom red value : 0.8
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetrgba">NoahSetRGBA</a> - Set text or quad color</li>
          <li><a href="#noahsetcolor">NoahSetColor</a> - Set text or quad color</li>
          <li><a href="#noahset2colors">NoahSet2Colors</a> - Set quad color</li>
          <li><a href="#noahset4colors">NoahSet4Colors</a> - Set quad color</li>
          <li><a href="#noahsetcornercolor">NoahSetCornerColor</a> - Set quad color</li>
          <li><a href="#noahsetcornerrgba">NoahSetCornerRGBA</a> - Set quad color</li>
          <li><a href="#noahgetrgba">NoahGetRGBA</a> - Get text or quad color</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPIColorPage;
