import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported
/*eslint-disable */

function NoahTutorialLab2Page2() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="before-you-begin">Lab2 Lua Script</h1>
          <h1 id="introduction-to-lua">Introduction to Lua</h1>
          <p>After understanding the basics of <strong>Noah UI</strong>, it's essential to learn about <strong>Lua scripts</strong>. You can see the files end with <code>.lua</code> in the <code>script</code> folder in <code>demo_hello_noah</code> project. These Lua files define the <strong>program logic</strong> and the <strong>state machine</strong> that governs the behavior of your project.</p>
          <h2 id="scriptlua">script.lua</h2>
          <p>Among the lua files, <strong><code>script.lua</code></strong> is the most crucial. It acts as the central hub of the entire program, defining the complete lifecycle of Noah. It outlines the program's initialization, termination, and tasks for each frame.  The sample code of <code>script.lua</code> is listed below:</p>
          <pre class="hljs hljs-content"><code><div>
            -- Require the "mouse" and "keyboard" modules. <br />
            -- These likely contain functions and methods for interacting with the mouse and keyboard input. <br />
            require("mouse") <br />
            require("keyboard") <br /> <br />

            -- This function is called when the project is initialized. <br />
            function Initialize() <br />
            print("hello project is initialized") <br />
            end <br /> <br />

            -- This function is called periodically during the project's execution. <br />
            function Update(interval) <br />
            {'  '}{'  '}-- Perform actions or update logic here. <br />
            {'  '}{'  '}-- The 'interval' parameter indicates the time elapsed since the previous update. <br />
            end <br /> <br />

            -- This function is called when the project is terminated or closed. <br />
            function Terminate() <br />
            print("hello project is terminated") <br />
            end <br />

            -- This is a comment. Comments are ignored by the Lua interpreter and are used to provide explanations and documentation for code. <br />
          
          </div></code></pre>
          <p>In this script:</p>
          <ul>
            <li>
                <p>The <code>require(&quot;mouse&quot;)</code> and <code>require(&quot;keyboard&quot;)</code> statements import functionality from external Lua modules named &quot;mouse&quot; and &quot;keyboard,&quot; which contain functions related to mouse and keyboard input handling.</p>
            </li>
            <li>
                <p>The <code>Initialize()</code> function is called when the project is initialized. In this case, it simply prints a message to indicate that the project has been initialized.</p>
            </li>
            <li>
                <p>The <code>Update(interval)</code> function is intended to be called periodically during the project's execution. It currently doesn't contain any specific code but can be used to update project logic based on the interval parameter.</p>
            </li>
            <li>
                <p>The <code>Terminate()</code> function is called when the project is terminated or closed. It prints a message to indicate that the project is terminated.</p>
            </li>
            <li>
                <p>The last part of the script consists of comments, which are used to provide explanations and documentation for the code.</p>
            </li>
          </ul>
          <p>Please note that the functionality of the script is not fully defined here, as it seems to be a template or a starting point. Depending on your specific project requirements, you would need to implement the logic inside the Update function to achieve the desired functionality for your Noah SDK project.</p>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab2Page2;
