import React, { useEffect, useState } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahLuaAPI3DScenePage() {
  useEffect(() => {
    return () => {};
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClose = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="documentPageContainer">
      <div className="floating-window">
        <div className="floating-window-header">
          <span>3D Scene APIs</span>
        </div>
        {!isCollapsed && (
          <div className="floating-window-content">
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Model</h3>
              <li><a href="#noahsetmodel">NoahSetModel</a></li>
              <li><a href="#noahgetmodel">NoahGetModel</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Light - Set</h3>
              <li><a href="#noahsetlighttype">NoahSetLightType</a></li>
              <li><a href="#noahsetlightrgb">NoahSetLightRGB</a></li>
              <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a></li>
              <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a></li>
              <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a></li>
            </ul>
            <ul>
              <h3 style={{ padding: 0, margin: 0 }}>Light - Get</h3>
              <li><a href="#noahgetlighttype">NoahGetLightType</a></li>
              <li><a href="#noahgetlightrgb">NoahGetLightRGB</a></li>
              <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a></li>
              <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a></li>
              <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a></li>
            </ul>
          </div>
        )}
      </div>
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="mindtronic-ai-noah">Mindtronic AI Noah</h1>
          <h2 id="api-reference">API Reference</h2>
          <h4 id="version-104a">version 105a</h4>
          <hr />
          <h2 id="basic-noah-functions">Basic Noah Functions</h2>
          <p>
            The <strong>noah functions</strong> to control basic{' '}
            <strong>noah elements</strong> are listed below.
          </p>
          <hr />
          <h3 id="noahsetmodel">NoahSetModel</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetModel(string target, string model)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>model</em> to <em>target</em>, which is a <strong>Nodel3D</strong> element.</p>
          <p>This function takes effect on only <strong>Model3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Model3D</strong> node.</li>
          <li><strong><code>model</code> (string):</strong> The path of gltf model file.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To let node &quot;sample_model&quot; use &quot;model/WaterBottle/WaterBottle.gltf&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetModel(<span class="hljs-string">"sample_model"</span>, <span class="hljs-string">"model/WaterBottle/WaterBottle.gltf"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahgetmodel">NoahGetModel</a> - Get model path current used.</li>
          </ul>
          <hr />
          <h3 id="noahgetmodel">NoahGetModel</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetModel(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get the model path of <em>target</em>, which is a <strong>Model3D</strong> element.</p>
          <p>This function takes effect on only <strong>Model3D</strong> element. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns an empty string.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Model3D</strong> node.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function return a string, which is model path of the node target.</p>
          <h4 id="example">Example</h4>
          <p>To get model path of node &quot;sample_model&quot;:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-keyword">local</span> <span class="hljs-built_in">path</span> = NoahGetModel(<span class="hljs-string">"sample_model"</span>)<br />
            <span class="hljs-built_in">print</span>(<span class="hljs-string">"Model path : "</span> .. <span class="hljs-built_in">path</span>)
          </div></code></pre>
          <h4 id="output">Output</h4>
          <pre class="hljs hljs-content"><code><div>Model path : model/WaterBottle/WaterBottle.gltf
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetmodel">NoahSetModel</a> - Set model file to node.</li>
          </ul>
          <hr />
          <h3 id="noahsetlighttype">NoahSetLightType</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetLightType(string target, string type)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Light3D</strong> compnent, to <em>type</em>. The assigned <em>type</em> must be one of <strong>AMBIENT</strong>, <strong>DIRECTION</strong>, <strong>OMNI</strong>, or <strong>SPOT</strong>.</p>
          <p>This function takes effect on only <strong>Light3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Light3D</strong> node.</li>
          <li><strong><code>type</code> (string):</strong> light type, can <strong>AMBIENT</strong>, <strong>DIRECTION</strong>, <strong>OMNI</strong>, or <strong>SPOT</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set light type of &quot;light_node&quot; to OMNI:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetLightType(<span class="hljs-string">"light_node"</span>, <span class="hljs-string">"OMNI"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h4 id="noahsetlightrgb">NoahSetLightRGB</h4>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetLightRGB(string target, number r, number g, number b)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Light3D</strong> compnent, to the light color specified by <em>r, g, b</em>. The <em>r, g, b</em> values should be within the range of <strong>0.0</strong> to <strong>1.0</strong>.</p>
          <p>This function takes effect on only <strong>Light3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Light3D</strong> node.</li>
          <li><strong><code>r</code> (number):</strong> red-channel of light color.</li>
          <li><strong><code>g</code> (number):</strong> green-channel of light color.</li>
          <li><strong><code>b</code> (number):</strong> blue-channel of light color.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set light color of &quot;light_node&quot; to rgb(1.0, 0.5, 0.7):</p>
          <pre class="hljs hljs-content"><code><div>NoahSetLightRGB(<span class="hljs-string">"light_node"</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">0.5</span>, <span class="hljs-number">0.7</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahsetlightintensity">NoahSetLightIntensity</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetLightIntensity(string target, number intensity)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Light3D</strong> component, to the assigned <em>intensity</em>.</p>
          <p>This function takes effect on only <strong>Light3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Light3D</strong> node.</li>
          <li><strong><code>intensity</code> (number):</strong> light intensity, only effecient when light type is not <strong>AMBIENT</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set light intensity of &quot;light_node&quot; to 1000.0:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetLightIntensity(<span class="hljs-string">"light_node"</span>, <span class="hljs-number">1000.0</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahsetlightcutoff">NoahSetLightCutoff</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetLightCutoff(string target, number cutoff)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Light3D</strong> compnent, to the assigned <em>cutoff</em> angle in degrees. The <em>cutoff</em> angle counts in degrees.</p>
          <p>This function takes effect on only <strong>Light3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Light3D</strong> node.</li>
          <li><strong><code>cutoff</code> (number):</strong> Cutoff of light in degree, only effecient when light type is <strong>SPOT</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set spot light cutoff of &quot;light_node&quot; to 45:</p>
          <pre class="hljs hljs-content"><code><div>NoahSetLightCutoff(<span class="hljs-string">"light_node"</span>, <span class="hljs-number">45.0</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahsetlightattenuation">NoahSetLightAttenuation</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahSetLightAttenuation(string target, number x, number y, number z, number w)</code></p>
          <h4 id="description">Description</h4>
          <p>Set <em>target</em>, which is a <strong>Light3D</strong> component, to the attenuation defined by <em>x, y, z, w</em>.</p>
          <p>This function takes effect on only <strong>Light3D</strong> elements. If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and does nothing to <em>target</em>.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier of the node, must be <strong>Light3D</strong> node.</li>
          <li><strong><code>x</code> (number):</strong> Const attenuation of light.</li>
          <li><strong><code>y</code> (number):</strong> Linear distance attenuation of light.</li>
          <li><strong><code>z</code> (number):</strong> Square distance attenuation of light.</li>
          <li><strong><code>w</code> (number):</strong> Reserved parameter, not used in current shader.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>This function does not return any value.</p>
          <h4 id="example">Example</h4>
          <p>To set attenuation value of &quot;light_node&quot; to (1.0, 1.0, 1.0, X):</p>
          <pre class="hljs hljs-content"><code><div>NoahSetLightAttenuation(<span class="hljs-string">"light_node"</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">1.0</span>, <span class="hljs-number">0.0</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahgetlighttype">NoahGetLightType</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetLightType(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get light type of the specified <strong>Light3D</strong> elements, If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns false.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the node, must be a <strong>Light3D</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The light type of <em>target</em> element when <em>target</em> is a <strong>Light3D</strong> element. False otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the light type of a light element identified by &quot;light_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahGetLightType(<span class="hljs-string">"light_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahgetlightrgb">NoahGetLightRGB</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetLightRGB(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get light RGB value of the specified <strong>Light3D</strong> elements, If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns false.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the node, must be a <strong>Light3D</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The RGB value of <em>target</em> element when <em>target</em> is a <strong>Light3D</strong> element. False otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the RGB value of a light element identified by &quot;light_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahGetLightRGB(<span class="hljs-string">"light_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahgetlightintensity">NoahGetLightIntensity</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetLightIntensity(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get light intensity of the specified <strong>Light3D</strong> elements, If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns false.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the node, must be a <strong>Light3D</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The light type of <em>target</em> element when <em>target</em> is a <strong>Light3D</strong> element. False otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the light intensity of a light element identified by &quot;light_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahGetLightIntensity(<span class="hljs-string">"light_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahgetlightcutoff">NoahGetLightCutoff</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetLightCutoff(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get light cutoff of the specified <strong>Light3D</strong> elements, If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns false.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the node, must be a <strong>Light3D</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The light cutoff of <em>target</em> element when <em>target</em> is a <strong>Light3D</strong> element. False otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the light cutoff of a light element identified by &quot;light_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahGetLightCutoff(<span class="hljs-string">"light_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightattenuation">NoahGetLightAttenuation</a> - Get light attenuation parameter of the light</li>
          </ul>
          <hr />
          <h3 id="noahgetlightattenuation">NoahGetLightAttenuation</h3>
          <h4 id="definition">Definition</h4>
          <p><code>NoahGetLightAttenuation(string target)</code></p>
          <h4 id="description">Description</h4>
          <p>Get light attenuation of the specified <strong>Light3D</strong> elements, If <em>target</em> is a element of other types, <em>Noah</em> logs an error message and returns false.</p>
          <h4 id="parameters">Parameters</h4>
          <ul>
          <li><strong><code>target</code> (string):</strong> The unique identifier for the node, must be a <strong>Light3D</strong>.</li>
          </ul>
          <h4 id="return-value">Return Value</h4>
          <p>The light attenuation of <em>target</em> element when <em>target</em> is a <strong>Light3D</strong> element. False otherwise.</p>
          <h4 id="example">Example</h4>
          <p>To get the light attenuation of a light element identified by &quot;light_node&quot;:</p>
          <pre class="hljs hljs-content"><code><div>NoahGetLightAttenuation(<span class="hljs-string">"light_node"</span>)
          </div></code></pre>
          <h4 id="see-also">See Also</h4>
          <ul>
          <li><a href="#noahsetlighttype">NoahSetLightType</a> - Set light type</li>
          <li><a href="#noahsetlightrgb">NoahSetLightRGB</a> - Set color of light</li>
          <li><a href="#noahsetlightintensity">NoahSetLightIntensity</a> - Set intensity of the light</li>
          <li><a href="#noahsetlightcutoff">NoahSetLightCutoff</a> - Set spot light cutoff of the light</li>
          <li><a href="#noahsetlightattenuation">NoahSetLightAttenuation</a> - Set light attenuation parameter of the light</li>
          <li><a href="#noahgetlighttype">NoahGetLightType</a> - Get light type</li>
          <li><a href="#noahgetlightrgb">NoahGetLightRGB</a> - Get color of light</li>
          <li><a href="#noahgetlightintensity">NoahGetLightIntensity</a> - Get intensity of the light</li>
          <li><a href="#noahgetlightcutoff">NoahGetLightCutoff</a> - Get spot light cutoff of the light</li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default NoahLuaAPI3DScenePage;
