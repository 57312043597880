import React, { useEffect } from 'react';
import NoticeWidget from './NoticeWidget';
import './css/ContentPage.css'; // Ensure this is correctly imported

import DownloadTable from './DownloadTable';
/*eslint-disable */

const documents = [
  {
    name: 'OMNI Installation/Calibration Manual v1.2',
    description: 'The manual for OMNI installation and calibration.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Installation_Calibration_Manual_v1.2.pdf',
  },
  {
    name: 'OMNI User Manual v1.0',
    description: 'The user manual of the OMNI device.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_User_Manual_v1.0.pdf',
  },
  // ... more documents
];

function OmniRegisterPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Register my OMNI device</h1>
          <p>
            The registration of your OMNI device is a critical step in
            integrating it with our Fleet Management System. This process
            ensures that your device is recognized and fully operational within
            our network. By registering your OMNI device, you enable it to
            communicate and synchronize with our system, allowing for efficient
            management and monitoring of your fleet. The registration process
            links your device to your specific account, providing access to
            real-time data, analytics, and the full suite of features offered by
            our Fleet Management System. This seamless integration is essential
            for optimizing fleet operations, enhancing vehicle safety, and
            improving overall fleet efficiency.
          </p>
          <h2>Step 1: Download the mobile App</h2>
          <p>
            Download the mobile app using the QR code and sign in with your
            existing account. The sign in account and password are the same as
            accessing this portal. The app enables you to stay updated on the
            fleet&apos;s current status, communicate and monitor the drivers in
            real-time, and receive notifications for any abnormal events.{' '}
          </p>
          <div className="appCenterContent">
            <div className="centerContent">
              <img
                src="/images/android_qrcode.png"
                alt="First"
                className="centeredImage"
              />
              <img
                src="/images/android_store.png"
                alt="Second"
                className="centeredImageStore"
              />
            </div>
            <div className="centerContent">
              <img
                src="/images/ios_qrcode.png"
                alt="First"
                className="centeredImage"
              />
              <img
                src="/images/ios_store.png"
                alt="Second"
                className="centeredImageStore"
              />
            </div>
          </div>
          <h2>Step 2: Register OMNI </h2>
          <p>
            By registering your device with our cloud service, you will be able
            to add your vehicle to our cloud-based fleet management system. This
            section will help you register your device to the cloud.
          </p>
          <ol>
            <li>
              Operate OMNI Device → Main Menu → Internet → Wi-Fi-Hotspot ON.
            </li>
            <li>In mobile phone, connect Wi-Fi named &apos;OMNI&apos;.</li>
            <li>
              After entering [OMNI Fleet] on the mobile phone, select →
              Configure device → Connect to device → Register Device.
            </li>
            <li>Fill the information in the register page.</li>
            <li>Register the device to the system.</li>
          </ol>
          <NoticeWidget>
            <li>
              {' '}
              The phone must have internet connectivity when pressing the
              register button. Registration can be done using the OMNI&apos;s
              SIM card or by switching back to the phone&apos;s own network.
            </li>
            <li>
              Mindtronic AI will provide a specific fleet ID for your company
            </li>
          </NoticeWidget>
          <p>
            You can also check this{' '}
            <a href="https://api.cloud.mindtronicai.com:7001/download/OMNI_Installation_Calibration_Manual_v1.0.pdf">
              manual
            </a>{' '}
            for the detail.
          </p>
          <h2>Next Steps</h2>
          <p>
            You have successfully completed the registration. You may now
            proceed to&nbsp;
            <a href="/omni/setup">setup the fleet</a>.
          </p>
          {/* The table for download. */}
          <h2>Resources</h2>
          <DownloadTable documents={documents} />
        </div>
      </div>
    </div>
  );
}

export default OmniRegisterPage;
