import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab1Page1() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab1_title">Lab1 UI Layout Construction</h1>
          <h3 id="before-you-begin">Before you begin</h3>
          <p>Upon completing this tutorial, you'll learn how to load 2D assets and utilize them as the 2D user interface using Noah.</p>
          <h2 id="prerequisites">Prerequisites</h2>
          <ul>
            <li>None</li>
          </ul>
          <h2 id="what-youll-learn">What you’ll learn</h2>
          <ul>
            <li>What is Noah Basic Element</li>
            <li>What attributes are in element</li>
            <li>How to place UI in Figma</li>
            <li>How to place UI in XML</li>  
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab1Page1;
