import React, { useEffect } from 'react';
import '../css/ContentPage.css'; // Ensure this is correctly imported

/*eslint-disable */

function NoahTutorialLab4Page() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1 id="Lab4_title">Lab4 Communication</h1>
          <h1 id="introduce-to-communication">Introduce to Communication</h1>
          <p>Noah Communication provides a simple method for data exchange between a host and client. It is divided into three parts: <strong>Setup Communication</strong>, <strong>Subscribe</strong>, and <strong>Publish</strong>.</p>
          <p>In this chapter, we will first discuss how to establish a connection with the host and safely terminate it.</p>
          <h2 id="establish-communication-with-host">Establish communication with Host</h2>
          <p><code>NoahCommConnect(string channel_id, string host_ip, number host_port)</code> establishes a connection to the host at the specified <code>host_ip</code> and <code>host_port</code>, and then creates a client with the name <code>channel_id</code>.</p>
          <p>In the Lua script <code>script.lua</code>, you can add the following code to the <code>Initialize()</code> function to establish a connection to host. For example, if you want to connect to the host at ip <code>127.0.0.1</code>, port <code>5050</code> with a <code>consumer</code> client:</p>
          
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Initialize</span><span class="hljs-params">()</span></span><br />
            {'  '}  <span class="hljs-keyword">if</span> NoahCommConnect(<span class="hljs-string">"consumer"</span>, <span class="hljs-string">"127.0.0.1"</span>, <span class="hljs-number">5050</span>) <span class="hljs-keyword">then</span><br/>
            {'  '}      <span class="hljs-built_in">print</span>(<span class="hljs-string">"NoahCommConnect success"</span>)<br/>
            {'  '}  <span class="hljs-keyword">else</span><br/>
            {'  '}      <span class="hljs-built_in">print</span>(<span class="hljs-string">"NoahCommConnect failed"</span>)<br/>
            {'  '}  <span class="hljs-keyword">end</span><br/>
            <span class="hljs-keyword">end</span><br/>
          </div></code></pre><br/>

          <p>Output Example - Success:</p>
          <pre class="hljs hljs-content"><code><div>
            [TcpClient] Connected to 127.0.0.1:5050<br/>
            [CommClient] Received uplink port: 6005, downlink port: 7005<br/>
            [TcpClient] Closing socket<br/>
            [TcpClient] Connected to 127.0.0.1:5050<br/>
            [CommChannel] Downlink started at port 7005<br/>
            [TcpServer] Listening on port 7005<br/>
            [TcpServer] Connection from 127.0.0.1:56510<br/>
            [TcpClient] Connected to 127.0.0.1:6005<br/>
            [CommChannel] Connected to uplink port 127.0.0.1:6005<br/>
            [TcpClient] Closing socket<br/>
            NoahCommConnect success
          </div></code></pre><br/>

          <p>Output Example - Failed:</p>
          <pre class="hljs hljs-content"><code><div>
            [CommClient] Failed to request ports
            NoahCommConnect failed
          </div></code></pre><br/>

          <h2 id="close-the-communication-with-host">Close the communication with Host</h2>
          <p>To safely close the connection, Noah API also provides the <code>NoahCommDisconnect()</code> function. You can add the following code to the <code>Terminate()</code> function:</p>
          <pre class="hljs hljs-content"><code><div>
            <span class="hljs-function"><span class="hljs-keyword">function</span> <span class="hljs-title">Terminate</span><span class="hljs-params">()</span></span><br />
            {'  '}NoahCommDisconnect()<br/>
            <span class="hljs-keyword">end</span><br/>
          </div></code></pre>
          
          <p>This function has no parameter or returns, and will ensure the connection is properly closed when the project ends.</p>
        </div>
      </div>
    </div>
  );
}

export default NoahTutorialLab4Page;
